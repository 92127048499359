import {FC, ReactNode} from 'react'
import {StyledSectionTitle} from './style'
import {useTranslation} from 'react-i18next'
import {Box} from '../box/Box'

interface FormSectionProps {
    title: string
    children: ReactNode
}
export const FormSection: FC<FormSectionProps> = ({title, children}) => {
    const {t} = useTranslation()

    return (
        <Box>
            <StyledSectionTitle>{t(title)}</StyledSectionTitle>
            {children}
        </Box>
    )
}

FormSection.displayName = 'FormSection'
