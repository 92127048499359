import axios from '@/axiosInstance'
import {z} from 'zod'
import {PaginationResponse} from '@/types/commons'
import {Restaurant, RestaurantListItem} from '../types'
import {RestaurantsSearchParams} from '@/features/restaurant/types'

/**
 * httpGetRestaurants
 * Here we will fetch all the restaurants data for the dash table
 */

// Params schema
export const HttpRestaurantsParams = z.object({
    ...RestaurantsSearchParams.shape,
    page: z.number().nullable(),
    limit: z.number().nullish()
})
export type HttpRestaurantsParams = z.infer<typeof HttpRestaurantsParams>

// Response
export const HttpRestaurantsResponse = z.object({
    data: z.array(RestaurantListItem),
    paginateMetadata: PaginationResponse
})
export type HttpRestaurantsResponse = z.infer<typeof HttpRestaurantsResponse>

// Http request
export const httpGetRestaurants = async (params: HttpRestaurantsParams): Promise<HttpRestaurantsResponse> => {
    const response = await axios.get(`/~/eateries/admin`, {params})
    return HttpRestaurantsResponse.parse(response.data)
}

/**
 * httpGetRestaurantDetails
 * Here we will get Restaurant details data
 */

// Params
export const HttpGetRestaurantDetailsParams = z.object({
    restaurantId: z.string()
})
export type HttpGetRestaurantDetailsParams = z.infer<typeof HttpGetRestaurantDetailsParams>

// Response
export const HttpGetRestaurantDetailsResponse = Restaurant
export type HttpGetRestaurantDetailsResponse = z.infer<typeof HttpGetRestaurantDetailsResponse>

// Http request
export const httpGetRestaurantDetails = async (
    params: HttpGetRestaurantDetailsParams
): Promise<HttpGetRestaurantDetailsResponse> => {
    const response = await axios.get(`/~/eatery/${params.restaurantId}`, {params})
    return HttpGetRestaurantDetailsResponse.parse(response.data)
}

/**
 * httpCreateRestaurant
 * Here we will create a new restaurant instance
 */
export const HttpRestaurantBodyRequest = z.object({
    generalInfo: z.object({
        name: z.string(),
        language: z.string(),
        currency: z.string(),
        delivery: z.number(),
        deliveryPayAfter: z.number(),
        deliveryPayBefore: z.number(),
        eatingTime: z.number(),
        eatOnSpot: z.number(),
        locations: z.number(),
        paymentAvailable: z.number(),
        prepayment: z.number(),
        status: z.string(),
        stripeSetupCompleted: z.number(),
        takeAway: z.number(),
        takeAwayPayAfter: z.number(),
        takeAwayPayBefore: z.number(),
        timezone: z.string(),
        idEatery: z.string().optional()
    }),
    plan: z.object({
        owner: z.string(),
        plan: z.number(),
        activationDate: z.string(),
        expiryDate: z.string()
    }),
    billingInfo: z
        .object({
            businessName: z.string().optional(),
            address: z.string().optional(),
            CAP: z.string().optional(),
            PEC: z.string().optional(),
            city: z.string().optional(),
            SDICode: z.string().optional(),
            country: z.string().optional(),
            province: z.string().optional(),
            vatNumber: z.string().optional(),
            fiscalCode: z.string().optional()
        })
        .optional()
        .nullable()
})
export type HttpRestaurantBodyRequest = z.infer<typeof HttpRestaurantBodyRequest>

export const HttpCreateRestaurantResponse = z.object({
    eatery: z.object({
        idEatery: z.string()
    })
})
export type HttpCreateRestaurantResponse = z.infer<typeof HttpCreateRestaurantResponse>

export const HttpCreateRestaurantParams = z.object({
    data: HttpRestaurantBodyRequest
})
export type HttpCreateRestaurantParams = z.infer<typeof HttpCreateRestaurantParams>

export const httpCreateRestaurant = async (
    params: HttpCreateRestaurantParams
): Promise<HttpCreateRestaurantResponse> => {
    const response = await axios.post(`/~/eatery/admin`, {
        ...params.data
    })
    return response.data
}

/**
 * httpUpdateRestaurant
 * Here we will update a restaurant instance
 */
export const HttpUpdateRestaurantParams = z.object({
    data: HttpRestaurantBodyRequest
})
export type HttpUpdateRestaurantParams = z.infer<typeof HttpUpdateRestaurantParams>

export const httpUpdateRestaurant = async (params: HttpUpdateRestaurantParams) => {
    const response = await axios.post(`/~/eatery/admin`, {...params.data})
    return response.data
}

/**
 * httpDeleteRestaurant
 * Here we will delete a restaurant instance
 */

export const httpDeleteRestaurant = async (id: string): Promise<void> => {
    await axios.delete(`/~/eatery/${id}`)
}
