import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpCreateDishes, HttpGetDishDetailsResponse} from '../restaurantDishes.http'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons'
import {errorHandler} from '@/utilities/genericErrorHandler'

type UseCreateDishProps = {
    restaurantId: string
    onSuccess?: (data: HttpGetDishDetailsResponse) => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useCreateDishes = (options: UseCreateDishProps) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.CREATE_DISHES],
        mutationFn: httpCreateDishes,
        onSuccess: async data => {
            await client.invalidateQueries({queryKey: [QUERY_KEYS.RESTAURANTS]})
            await client.invalidateQueries({queryKey: [QUERY_KEYS.RESTAURANT_DETAILS, options.restaurantId]})
            options?.onSuccess?.(data)
        },
        onError: (error: AxiosError<ErrorResponseData>) => errorHandler(error)
    })
}
