import {Table, TableSorter} from '@/components/commons/table/Table'
import {Dispatch, FC, ReactElement, SetStateAction, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {generatePath, useNavigate} from 'react-router-dom'
import {AccountListItem, AccountsSearchParams} from '@/features/account/types'
import {TableDataCell} from '@/components/commons/table/table-data-cell/TableDataCell'
import 'dayjs/locale/en'
import {EmptyResults} from '@/components/commons/empty-results/EmptyResults'
import {EmptySearch} from '@/components/commons/empty-search/EmptySearch'
import {CheckIcon, DetailsIcon, DotsVerticalIcon, PlusIcon, SearchMdIcon, Trash01Icon} from '@/components/ui/icon/Icon'
import {useTheme} from 'styled-components'
import {DropdownMenu} from '@/components/ui/dropdown-menu/DropdownMenu'
import {routes} from '@/utilities/constants/routes'
import {Button} from '@/components/ui/button/Button'
import {Chip} from '@/components/ui/chip/Chip'
import {formatLocaleDate} from '@/utilities/helpers'
import {useVerifyAccount} from '../../services/queries/useVerifyAccount'
import {errorHandler} from '@/utilities/genericErrorHandler'
import {toast} from '@/utilities/toast/toast'

interface AccountsTableProps {
    data: AccountListItem[]
    isChangingPage: boolean
    isError: boolean
    isLoading: boolean
    onClickAddAccount: () => void
    searchValue?: string
    emptySearchStateComponent?: ReactElement
    sorter?: TableSorter<AccountsSearchParams['orderBy']> | undefined
    onChangePage?: () => void
    onResetSearchCb?: () => void
    setAccountToDelete: Dispatch<SetStateAction<AccountListItem | null>>
}

export const AccountsTable: FC<AccountsTableProps> = ({
    data,
    isLoading,
    sorter,
    searchValue,
    onChangePage,
    onResetSearchCb,
    onClickAddAccount,
    setAccountToDelete
}) => {
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 960, [width])
    const theme = useTheme()
    const navigate = useNavigate()

    // mutations
    const {mutate: verifyAccountMutation, isPending: isPendingVerify} = useVerifyAccount({
        onSuccess: () => {
            toast.success(t('account:account_verified_success'))
        },
        onError: error => errorHandler(error)
    })

    return (
        <Table
            onChangePage={onChangePage}
            isMobile={isMobile}
            data={data}
            isLoading={isLoading}
            sorter={sorter}
            searchValue={searchValue}
            columns={[
                {
                    label: t('account:table:labels:id'),
                    width: '25%',
                    cellRender: account => (
                        <TableDataCell isBold={true} size="sm">
                            {account.idAccount}
                        </TableDataCell>
                    )
                },
                {
                    label: t('account:table:labels:name'),
                    width: '25%',
                    sortName: 'displayName',
                    cellRender: account => <TableDataCell size="sm">{account.displayName ?? '--'}</TableDataCell>
                },
                {
                    label: t('account:table:labels:creationDate'),
                    width: '15%',
                    sortName: 'dateCreation',
                    cellRender: account => (
                        <TableDataCell size="sm">{formatLocaleDate(account.dateCreation)}</TableDataCell>
                    )
                },
                {
                    label: t('account:table:labels:verify'),
                    width: '15%',
                    alignment: 'center',
                    cellRender: account => (
                        <TableDataCell size="sm">
                            {account.emailVerified ? (
                                <Chip
                                    icon={<CheckIcon size={14} />}
                                    id={`${account.idAccount}`}
                                    variant="success"
                                    label={t('account:verified')}
                                />
                            ) : (
                                <Button
                                    disabled={isPendingVerify}
                                    onClick={() => verifyAccountMutation({idAccount: account.idAccount})}
                                    size="sm"
                                    variant="secondary"
                                >
                                    {t('account:verify')}
                                </Button>
                            )}
                        </TableDataCell>
                    )
                },
                {
                    label: ' ',
                    width: '20%',
                    alignment: 'right',
                    cellRender: account => (
                        <TableDataCell>
                            <DropdownMenu
                                sideOffset={8}
                                trigger={<DotsVerticalIcon />}
                                actions={[
                                    {
                                        component: 'Details',
                                        withSeparator: true,
                                        icon: <DetailsIcon stroke={theme.palette.neutral[700]} size={18} />,
                                        onClickCb: () =>
                                            navigate(
                                                generatePath(routes.ACCOUNT_DETAILS.path, {
                                                    id: account.idAccount
                                                })
                                            )
                                    },
                                    {
                                        component: 'Delete',
                                        isDanger: true,
                                        icon: <Trash01Icon stroke={theme.palette.danger[700]} size={16} />,
                                        onClickCb: () => {
                                            setAccountToDelete(account)
                                        }
                                    }
                                ]}
                            />
                        </TableDataCell>
                    )
                }
            ]}
            emptyStateComponent={
                <EmptyResults
                    title={t('account:table:empty:title')}
                    subtitle={t('account:table:empty:subtitle')}
                    btn={{
                        onClick: onClickAddAccount,
                        text: t('account:new_account'),
                        icon: <PlusIcon stroke={theme.palette.neutral.white} />
                    }}
                />
            }
            emptySearchStateComponent={
                <EmptySearch
                    title={t('commons:no_results')}
                    onClearSearch={() => onResetSearchCb && onResetSearchCb()}
                    icon={<SearchMdIcon />}
                />
            }
        />
    )
}
