import axios from '@/axiosInstance'
import {z} from 'zod'
import {PaginationResponse} from '@/types/commons'
import {AccountListItem} from '../types'
import {AccountsSearchParams} from '@/features/account/types'

/**
 * httpGetAccounts
 * Here we will fetch all the accounts data for the dash table
 */

// Params schema
export const HttpAccountsParams = z.object({
    ...AccountsSearchParams.shape,
    page: z.number().nullable(),
    limit: z.number().nullish()
})
export type HttpAccountsParams = z.infer<typeof HttpAccountsParams>

// Response
export const HttpAccountsResponse = z.object({
    data: z.array(AccountListItem),
    paginateMetadata: PaginationResponse
})
export type HttpAccountsResponse = z.infer<typeof HttpAccountsResponse>

// Http request
export const httpGetAccounts = async (params: HttpAccountsParams): Promise<HttpAccountsResponse> => {
    const response = await axios.get('/~/accounts', {params})
    return HttpAccountsResponse.parse(response.data)
}

/**
 * httpGetAccountDetails
 * Here we will get Account details data
 */

// Params
export const HttpGetAccountDetailsParams = z.object({
    accountId: z.string()
})
export type HttpGetAccountDetailsParams = z.infer<typeof HttpGetAccountDetailsParams>

// Response
export const HttpGetAccountDetailsResponse = z.object({
    email: z.string(),
    mobile: z.string().nullable(),
    language: z.string(),
    givenName: z.string(),
    idAccount: z.string(),
    urlAvatar: z.string().nullable(),
    familyName: z.string(),
    displayName: z.string().nullable(),
    emailVerified: z.number()
})
export type HttpGetAccountDetailsResponse = z.infer<typeof HttpGetAccountDetailsResponse>

// Http request
export const httpGetAccountDetails = async (
    params: HttpGetAccountDetailsParams
): Promise<HttpGetAccountDetailsResponse> => {
    const response = await axios.get(`/~/account/${params.accountId}`)
    return HttpGetAccountDetailsResponse.parse(response.data)
}

/**
 * httpCreateAccount
 * Here we will create a new account instance
 */
export const HttpAccountBodyRequest = z.object({
    email: z.string(),
    mobile: z.string().nullable(),
    language: z.string(),
    givenName: z.string(),
    familyName: z.string(),
    displayName: z.string(),
    urlAvatar: z.string()
})
export type HttpAccountBodyRequest = z.infer<typeof HttpAccountBodyRequest>

export const HttpCreateAccountParams = z.object({
    data: HttpAccountBodyRequest
})
export type HttpCreateAccountParams = z.infer<typeof HttpCreateAccountParams>

export const httpCreateAccount = async (params: HttpCreateAccountParams): Promise<HttpGetAccountDetailsResponse> => {
    const response = await axios.post(`/~/account/signup`, {
        ...params.data
    })
    return response.data
}

/**
 * httpUpdateAccount
 * Here we will update a account instance
 */
export const HttpUpdateAccountParams = z.object({
    idAccount: z.string(),
    data: HttpAccountBodyRequest
})
export type HttpUpdateAccountParams = z.infer<typeof HttpUpdateAccountParams>

export const httpUpdateAccount = async (params: HttpUpdateAccountParams) => {
    const response = await axios.patch(`/~/account/${params.idAccount}`, {...params.data})
    return HttpGetAccountDetailsResponse.parse(response.data)
}

/**
 * httpDeleteAccount
 * Here we will delete a account instance
 */

export const httpDeleteAccount = async (id: string): Promise<void> => {
    await axios.delete(`/accounts/${id}`)
}

/**
 * httpVerifyAccount
 * Here we will verify a new account instance
 */

export const HttpVerifyAccountParams = z.object({
    idAccount: z.string()
})
export type HttpVerifyAccountParams = z.infer<typeof HttpVerifyAccountParams>

export const httpVerifyAccount = async (params: HttpVerifyAccountParams): Promise<HttpGetAccountDetailsResponse> => {
    const response = await axios.patch(`/~/account/verify/${params.idAccount}`)
    return response.data
}
