import React from 'react'
import {useTranslation} from 'react-i18next'
import {Modal} from '@/components/ui/modal/Modal'
import {StyledModal, StyledTitle} from './style'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import BeverageForm from './BeverageForm'
import {HttpUploadBeveragesFileResponse} from '../../services/restaurantBeverages.http'
import {BeverageValidationSchema} from './BeverageFormModel'
import {BeverageImageItem} from '../../types'

interface BeverageModalProps {
    onClose: () => void
    uploadedDishes?: HttpUploadBeveragesFileResponse | null
    restaurantId: string
    beveragePeriodId: string
    defaultValues?: BeverageValidationSchema
    defaultImages?: BeverageImageItem[]
}

export const BeverageModal: React.FC<BeverageModalProps> = ({
    onClose,
    uploadedDishes,
    restaurantId,
    beveragePeriodId,
    defaultValues,
    defaultImages
}) => {
    const {t} = useTranslation()

    return (
        <Modal width={800} closeIconOffset="sm" onClose={onClose}>
            <StyledModal>
                <Flexbox align={'center'}>
                    <StyledTitle>
                        {t(defaultValues ? 'beverage:updateModal:title' : 'beverage:addModal:title')}
                    </StyledTitle>
                </Flexbox>
                <BeverageForm
                    restaurantId={restaurantId}
                    onCancel={onClose}
                    uploadedDishes={uploadedDishes}
                    beveragePeriodId={beveragePeriodId}
                    defaultValues={defaultValues}
                    defaultImages={defaultImages}
                />
            </StyledModal>
        </Modal>
    )
}

export default BeverageModalProps
