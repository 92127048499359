import {LANGUAGES} from '@/utilities/constants/common'
import {
    HttpCreateCustomerParams,
    HttpGetCustomerDetailsResponse,
    HttpUpdateCustomerParams
} from '../../services/customer.http'
import {CustomerValidationSchema} from './CustomerFormModel'
import i18n from 'i18next'

export const adaptCustomerToForm = (data: HttpGetCustomerDetailsResponse): CustomerValidationSchema => {
    const language = LANGUAGES.find(item => item.value === data.customer.language)
    return {
        givenName: data.customer.givenName,
        familyName: data.customer.familyName,
        email: data.customer.email ?? '',
        mobile: data.customer.mobile ?? '',
        language: {value: language?.value ?? '', label: i18n.t(language?.label ?? '')}
    }
}

export const adaptCustomerToUpdate = (
    idCustomer: string,
    data: CustomerValidationSchema
): HttpUpdateCustomerParams => ({
    data: {
        idCustomer,
        givenName: data.givenName,
        familyName: data.familyName,
        email: data.email ?? '',
        mobile: data.mobile || null,
        language: data.language.value ?? '',
        displayName: `${data.givenName} ${data.familyName}`,
        urlAvatar: ''
    }
})

export const adaptCustomerToCreate = (data: CustomerValidationSchema): HttpCreateCustomerParams => ({
    data: {
        givenName: data.givenName,
        familyName: data.familyName,
        email: data.email ?? '',
        mobile: data.mobile || null,
        language: data.language.value ?? '',
        displayName: `${data.givenName} ${data.familyName}`,
        urlAvatar: ''
    }
})
