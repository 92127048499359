import {Table, TableSorter} from '@/components/commons/table/Table'
import {Dispatch, FC, ReactElement, SetStateAction, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {generatePath, useNavigate} from 'react-router-dom'
import {RestaurantListItem, RestaurantsSearchParams} from '@/features/restaurant/types'
import {TableDataCell} from '@/components/commons/table/table-data-cell/TableDataCell'
import 'dayjs/locale/en'
import {EmptyResults} from '@/components/commons/empty-results/EmptyResults'
import {EmptySearch} from '@/components/commons/empty-search/EmptySearch'
import {
    CheckIcon,
    DetailsIcon,
    DotsVerticalIcon,
    SearchMdIcon,
    Trash01Icon,
    XCloseIcon
} from '@/components/ui/icon/Icon'
import {useTheme} from 'styled-components'
import {DropdownMenu} from '@/components/ui/dropdown-menu/DropdownMenu'
import {routes} from '@/utilities/constants/routes'
import {Chip} from '@/components/ui/chip/Chip'
import {formatLocaleDate} from '@/utilities/helpers'

interface RestaurantsTableProps {
    data: RestaurantListItem[]
    isChangingPage: boolean
    isError: boolean
    isLoading: boolean
    searchValue?: string
    emptySearchStateComponent?: ReactElement
    sorter?: TableSorter<RestaurantsSearchParams['orderBy']> | undefined
    onChangePage?: () => void
    onResetSearchCb?: () => void
    setRestaurantToDelete: Dispatch<SetStateAction<RestaurantListItem | null>>
}

export const RestaurantsTable: FC<RestaurantsTableProps> = ({
    data,
    isLoading,
    sorter,
    searchValue,
    onChangePage,
    onResetSearchCb,
    setRestaurantToDelete
}) => {
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 960, [width])
    const theme = useTheme()
    const navigate = useNavigate()

    return (
        <Table
            onChangePage={onChangePage}
            isMobile={isMobile}
            data={data}
            isLoading={isLoading}
            sorter={sorter}
            searchValue={searchValue}
            columns={[
                {
                    label: t('restaurant:table:labels:name'),
                    width: '40%',
                    sortName: 'name',
                    cellRender: restaurant => <TableDataCell size="sm">{restaurant.name}</TableDataCell>
                },
                {
                    label: t('restaurant:table:labels:planType'),
                    width: '20%',
                    cellRender: restaurant => <TableDataCell size="sm">{restaurant.plan.name}</TableDataCell>
                },
                {
                    label: t('restaurant:table:labels:expiryDate'),
                    width: '22%',
                    sortName: 'licenseExpire',
                    cellRender: restaurant => (
                        <TableDataCell size="sm">{formatLocaleDate(restaurant.licenseExpire)}</TableDataCell>
                    )
                },
                {
                    label: t('restaurant:table:labels:hasMenus'),
                    width: '10%',
                    cellRender: restaurant => (
                        <TableDataCell size="sm">
                            {restaurant.hasMenu ? (
                                <Chip variant="success" id={`${restaurant.idEatery}`} icon={<CheckIcon size={15} />} />
                            ) : (
                                <Chip variant="warning" id={`${restaurant.idEatery}`} icon={<XCloseIcon size={15} />} />
                            )}
                        </TableDataCell>
                    )
                },
                {
                    label: ' ',
                    width: '8%',
                    alignment: 'right',
                    cellRender: restaurant => (
                        <TableDataCell>
                            <DropdownMenu
                                sideOffset={8}
                                trigger={<DotsVerticalIcon />}
                                actions={[
                                    {
                                        component: 'Details',
                                        withSeparator: true,
                                        icon: <DetailsIcon stroke={theme.palette.neutral[700]} size={18} />,
                                        onClickCb: () =>
                                            navigate(
                                                generatePath(routes.RESTAURANT_DETAILS.path, {
                                                    id: restaurant.idEatery
                                                })
                                            )
                                    },
                                    {
                                        component: 'Delete',
                                        isDanger: true,
                                        icon: <Trash01Icon stroke={theme.palette.danger[700]} size={16} />,
                                        onClickCb: () => {
                                            setRestaurantToDelete(restaurant)
                                        }
                                    }
                                ]}
                            />
                        </TableDataCell>
                    )
                }
            ]}
            emptyStateComponent={
                <EmptyResults
                    title={t('restaurant:table:empty:title')}
                    subtitle={t('restaurant:table:empty:subtitle')}
                />
            }
            emptySearchStateComponent={
                <EmptySearch
                    title={t('commons:no_results')}
                    onClearSearch={() => onResetSearchCb && onResetSearchCb()}
                    icon={<SearchMdIcon />}
                />
            }
        />
    )
}
