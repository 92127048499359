import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {CUSTOMER_FORM_MODEL, CustomerFormSchema, CustomerValidationSchema} from './CustomerFormModel'
import {useTranslation} from 'react-i18next'
import {StyledFirstRowWrapper, StyledSecondRowWrapper, StyledActionsWrapper} from './style'
import {Button} from '@/components/ui/button/Button'
import React, {useEffect} from 'react'
import {Select, SelectValue} from '@/components/commons/select/Select'
import {LANGUAGES} from '@/utilities/constants/common'
import {useUpdateCustomer} from '../../services/queries/useUpdateCustomer'
import {errorHandler} from '@/utilities/genericErrorHandler'
import {useCreateCustomer} from '../../services/queries/useCreateCustomer'
import {toast} from '@/utilities/toast/toast'
import {adaptCustomerToCreate, adaptCustomerToUpdate} from './utils'

interface CustomerFormProps {
    defaultValues?: CustomerValidationSchema
    customerId?: string
    onCancel?: () => void
    readOnly?: boolean
}

const CustomerForm: React.FC<CustomerFormProps> = ({defaultValues, customerId, onCancel, readOnly = false}) => {
    const {t} = useTranslation()

    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: {errors, touchedFields, isValid, isDirty}
    } = useForm<CustomerValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(CustomerFormSchema),
        defaultValues: {...defaultValues}
    })

    useEffect(() => {
        reset(defaultValues)
    }, [defaultValues])

    // mutations
    const {mutate: createCustomerMutation, isPending: isPendingCreate} = useCreateCustomer({
        onSuccess: () => {
            onCancel?.()
            toast.success(t('customer:new_customer_success'))
        },
        onError: error => errorHandler(error)
    })

    const {mutate: updateCustomerMutation, isPending: isPendingUpdate} = useUpdateCustomer({
        onSuccess: () => {
            onCancel?.()
            toast.success(t(`commons:update_completed`, {entity: t(`customer:singular`)}))
        },
        onError: () => {
            reset({...defaultValues})
        }
    })

    // submit handler
    const onSubmit: SubmitHandler<CustomerValidationSchema> = data => {
        if (customerId) {
            updateCustomerMutation(adaptCustomerToUpdate(customerId, data))
            reset({}, {keepValues: true})
        } else {
            createCustomerMutation(adaptCustomerToCreate(data))
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <StyledFirstRowWrapper>
                <InputText
                    label={`${t(CUSTOMER_FORM_MODEL.givenName.label)}`}
                    type={'text'}
                    touched={touchedFields.givenName}
                    errorMessage={t(errors.givenName?.message || '')}
                    placeholder={t(CUSTOMER_FORM_MODEL.givenName.label)}
                    {...register(t(CUSTOMER_FORM_MODEL.givenName.name))}
                    disabled={readOnly}
                />
                <InputText
                    label={`${t(CUSTOMER_FORM_MODEL.familyName.label)}`}
                    type={'text'}
                    touched={touchedFields.familyName}
                    errorMessage={t(errors.familyName?.message || '')}
                    placeholder={t(CUSTOMER_FORM_MODEL.familyName.label)}
                    {...register(t(CUSTOMER_FORM_MODEL.familyName.name))}
                    disabled={readOnly}
                />
                <InputText
                    label={`${t(CUSTOMER_FORM_MODEL.email.label)}`}
                    type={'text'}
                    touched={touchedFields.email}
                    errorMessage={t(errors.email?.message || '')}
                    placeholder={t(CUSTOMER_FORM_MODEL.email.label)}
                    {...register(t(CUSTOMER_FORM_MODEL.email.name))}
                    disabled={readOnly}
                />
            </StyledFirstRowWrapper>
            <StyledSecondRowWrapper>
                <InputText
                    label={`${t(CUSTOMER_FORM_MODEL.mobile.label)}`}
                    type={'text'}
                    touched={touchedFields.mobile}
                    errorMessage={t(errors.mobile?.message || '')}
                    placeholder={t(CUSTOMER_FORM_MODEL.mobile.label)}
                    {...register(t(CUSTOMER_FORM_MODEL.mobile.name))}
                    disabled={readOnly}
                />
                <Controller
                    render={({field: {onChange, value}}) => (
                        <Select
                            value={value}
                            onChange={newValue => {
                                onChange(newValue as SelectValue)
                            }}
                            size={'medium'}
                            name={CUSTOMER_FORM_MODEL.language.name}
                            label={`${t(CUSTOMER_FORM_MODEL.language.label)}`}
                            isClearable={false}
                            isSearchable={true}
                            errorMessage={t(errors.language?.message || '')}
                            placeholder={t(CUSTOMER_FORM_MODEL.language.label)}
                            options={LANGUAGES.map(item => ({
                                value: item.value,
                                label: t(item.label)
                            }))}
                            disabled={readOnly}
                        />
                    )}
                    control={control}
                    name={CUSTOMER_FORM_MODEL.language.name}
                />
            </StyledSecondRowWrapper>
            {!readOnly && (
                <StyledActionsWrapper gap={2} justify={'end'}>
                    <Button
                        type="button"
                        onClick={onCancel}
                        variant={'tertiary'}
                        size="md"
                        disabled={isPendingUpdate || isPendingCreate}
                    >
                        {t('commons:cancel')}
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        size="md"
                        disabled={!isValid || isPendingUpdate || isPendingCreate || !isDirty}
                    >
                        {t('commons:save')}
                    </Button>
                </StyledActionsWrapper>
            )}
        </form>
    )
}

export default CustomerForm
