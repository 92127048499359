import {Controller, SubmitHandler, useFieldArray, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {DISH_FORM_MODEL, DishFormSchema, DishItem, DishValidationSchema} from './DishFormModel'
import {useTranslation} from 'react-i18next'
import {
    StyledActionsWrapper,
    StyledBox,
    StyledDishItemContent,
    StyledDishesActionsWrapper,
    StyledImageUploaderWrapper
} from './style'
import {Button} from '@/components/ui/button/Button'
import React, {useEffect, useState} from 'react'
import {PlusIcon, Trash01Icon} from '@/components/ui/icon/Icon'
import {useTheme} from 'styled-components'
import {TextArea} from '@/components/commons/textarea/TextArea'
import {List} from '@/components/commons/list/list/List'
import {ListItem} from '@/components/commons/list/list-item/ListItem'
import {Checkbox} from '@/components/commons/checkbox/CheckBox'
import {useSelectableList} from '@/hooks/useSelectableList'
import {Select, SelectValue} from '@/components/commons/select/Select'
import {PeriodItem} from '@/features/restaurant/types'
import {HttpUploadDishesFileResponse, httpUploadDishImage} from '../../services/restaurantDishes.http'
import toast from 'react-hot-toast'
import {errorHandler} from '@/utilities/genericErrorHandler'
import {useCreateDishes} from '../../services/queries/useCreateDishes'
import {adaptDishesToCreate} from '../../utils'
import {ImageUploader} from '@/components/commons/image-uploader/ImageUploader'
import {downloadFile} from '@/utilities/helpers'
import {DishImageItem} from '../../types'
import {useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'

interface DishFormProps {
    restaurantId: string
    onCancel?: () => void
    periods: PeriodItem[]
    uploadedDishes?: HttpUploadDishesFileResponse | null
    defaultValues?: DishValidationSchema
    defaultImages?: DishImageItem[]
}

const DishForm: React.FC<DishFormProps> = ({
    onCancel,
    periods,
    uploadedDishes,
    restaurantId,
    defaultValues,
    defaultImages
}) => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()
    const theme = useTheme()
    const [files, setFiles] = useState<DishImageItem[]>(defaultImages ?? [])
    const [isUploadingFiles, setIsUploadingFiles] = useState(false)
    const {items, setItems, onSelectItem, onSelectAll, isAllSelected, isNoneSelected} = useSelectableList([
        {
            index: 0,
            selected: false
        }
    ])

    const {
        control,
        register,
        handleSubmit,
        formState: {errors, touchedFields, isValid, isDirty},
        setValue,
        getValues
    } = useForm<DishValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(DishFormSchema),
        defaultValues: defaultValues || {
            dishes: [
                {
                    name: '',
                    description: '',
                    period: {value: '', label: t('commons:select_a_period')},
                    price: 0,
                    image: null
                }
            ]
        }
    })

    const {
        fields: rows,
        append: appendRow,
        remove: removeRow
    } = useFieldArray({
        control,
        name: 'dishes'
    })

    useEffect(() => {
        if (uploadedDishes?.length && rows.length === 1) {
            removeRow(0)
            uploadedDishes.forEach(item =>
                appendRow({
                    name: item.name,
                    description: item.description,
                    period: {value: '', label: t('commons:select_a_period')},
                    price: item.price,
                    image: null
                })
            )
        }
    }, [uploadedDishes?.length])

    const onClickAppendDish = () => {
        appendRow({
            name: '',
            description: '',
            period: {value: '', label: t('commons:select_a_period')},
            price: 0,
            image: null
        })
        setItems(prev => [...prev, {index: prev.length, selected: false}])
    }

    const onClickDeleteSelected = () => {
        const formDishes = getValues('dishes')
        let counter = 0
        const updatedDishes: DishItem[] = []
        items.forEach((item, index) => {
            if (!item.selected) {
                updatedDishes.push(formDishes[index])
                counter++
            }
        })
        setValue('dishes', updatedDishes)
        const updatedSelected = Array.from({length: counter}, (_, index) => ({index, selected: false}))
        setItems(updatedSelected)
    }

    // mutations
    const {mutate: createDishMutation, isPending: isPendingCreate} = useCreateDishes({
        restaurantId: restaurantId,
        onSuccess: data => {
            toast.success(t(defaultValues ? 'dish:update_dish_successful' : 'dish:new_dish_success'))

            // uploading images
            const promises: Promise<void>[] = []
            files.forEach(item => {
                const dish = data.find((_, index) => index === item.index)
                if (item.file) promises.push(httpUploadDishImage(item.file, dish?.idDish ?? ''))
            })
            setIsUploadingFiles(true)
            Promise.all(promises).finally(() => {
                setIsUploadingFiles(false)
                onCancel?.()
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.RESTAURANT_DETAILS, restaurantId]})
            })
        },
        onError: error => errorHandler(error)
    })

    // submit handler
    const onSubmit: SubmitHandler<DishValidationSchema> = data => {
        createDishMutation(adaptDishesToCreate(data, restaurantId))
    }

    const onChangeFile = (index: number, uploadedFile: File) => {
        const reader = new FileReader()
        reader.onload = () => {
            const updatedFiles = [
                ...files.filter(item => item.index !== index),
                {index, file: uploadedFile, image: reader.result as string}
            ]
            setFiles(updatedFiles)
        }
        reader.readAsDataURL(uploadedFile)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {!defaultValues && (
                <StyledDishesActionsWrapper>
                    <Button
                        type="button"
                        variant="transparentDanger"
                        disabled={isNoneSelected}
                        onClick={onClickDeleteSelected}
                    >
                        <Trash01Icon />
                        {t('commons:delete')}
                    </Button>
                    <Checkbox
                        disabled={items.length === 0}
                        checked={isAllSelected}
                        onChange={onSelectAll}
                        id="selectAll"
                        label={t('commons:select_all')}
                    />
                    <Button type="button" variant="secondary" onClick={onClickAppendDish}>
                        <PlusIcon stroke={theme.palette.neutral[500]} />
                        {t('dish:addForm:addButton')}
                    </Button>
                </StyledDishesActionsWrapper>
            )}
            <StyledBox>
                {rows.length === 0 && <span>{t('dish:addForm:emptyList')}</span>}
                <List>
                    {rows.map((field, index) => {
                        const checked = items.find(element => element.index === index && element.selected) !== undefined
                        const file = files.find(file => file.index === index)
                        return (
                            <ListItem isSelected={checked} key={field.id}>
                                {!defaultValues && (
                                    <Checkbox checked={checked} onChange={() => onSelectItem(index)} id={`${index}`} />
                                )}
                                <StyledImageUploaderWrapper>
                                    <Controller
                                        render={({field: {onChange, value}}) => (
                                            <ImageUploader
                                                maxSizeInMB={10}
                                                accept={{'image/jpeg': [], 'image/png': []}}
                                                value={value ?? undefined}
                                                onChange={changedFile => {
                                                    onChangeFile(index, changedFile)
                                                    onChange(changedFile)
                                                }}
                                                downloadCallback={downloadFile}
                                                image={file?.image}
                                            />
                                        )}
                                        control={control}
                                        name={`dishes.${index}.image`}
                                    />
                                </StyledImageUploaderWrapper>
                                <StyledDishItemContent>
                                    <input type={'hidden'} {...register(`dishes.${index}.idDish`)} />
                                    <InputText
                                        type={'text'}
                                        label={t(DISH_FORM_MODEL.dishes.name.label)}
                                        touched={touchedFields?.dishes?.[index]?.name}
                                        errorMessage={t(errors.dishes?.[index]?.name?.message || '')}
                                        placeholder={t(DISH_FORM_MODEL.dishes.name.label)}
                                        {...register(`dishes.${index}.name`)}
                                    />
                                    <Controller
                                        render={({field: {onChange, value}}) => (
                                            <Select
                                                value={value}
                                                onChange={newValue => {
                                                    onChange(newValue as SelectValue)
                                                }}
                                                size={'medium'}
                                                name={`dishes.${index}.period`}
                                                label={t(DISH_FORM_MODEL.dishes.period.label)}
                                                isClearable={false}
                                                isSearchable={true}
                                                errorMessage={t(errors.dishes?.[index]?.period?.message || '')}
                                                placeholder={t(DISH_FORM_MODEL.dishes.period.label)}
                                                options={periods
                                                    .filter(item => item.period !== 0)
                                                    .map(item => ({
                                                        value: item.idPeriod,
                                                        label: item.name
                                                    }))}
                                            />
                                        )}
                                        control={control}
                                        name={`dishes.${index}.period`}
                                    />
                                    <TextArea
                                        label={t(DISH_FORM_MODEL.dishes.description.label)}
                                        errorMessage={t(errors.dishes?.[index]?.description?.message || '')}
                                        placeholder={t(DISH_FORM_MODEL.dishes.description.label)}
                                        {...register(`dishes.${index}.description`)}
                                    />
                                    <InputText
                                        type={'text'}
                                        label={t(DISH_FORM_MODEL.dishes.price.label)}
                                        touched={touchedFields?.dishes?.[index]?.price}
                                        errorMessage={t(errors.dishes?.[index]?.price?.message || '')}
                                        placeholder={t(DISH_FORM_MODEL.dishes.price.label)}
                                        {...register(`dishes.${index}.price`)}
                                    />
                                </StyledDishItemContent>
                            </ListItem>
                        )
                    })}
                </List>
            </StyledBox>

            <StyledActionsWrapper gap={2} justify={'end'}>
                <Button
                    type="button"
                    onClick={onCancel}
                    variant={'tertiary'}
                    size="md"
                    disabled={isPendingCreate || isUploadingFiles}
                >
                    {t('commons:cancel')}
                </Button>
                <Button
                    type="submit"
                    variant="primary"
                    size="md"
                    disabled={!isValid || !isDirty || !rows.length || isPendingCreate || isUploadingFiles}
                >
                    {t('commons:save')}
                </Button>
            </StyledActionsWrapper>
        </form>
    )
}

export default DishForm
