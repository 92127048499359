import {z} from 'zod'
import axios from '@/axiosInstance'
import ObjectID from 'bson-objectid'

/**
 * httpUploadDishesFile
 * Here we will upload a CSV file to create multiple dishes
 */

// Response
export const HttpUploadDishesFileResponse = z.array(
    z.object({
        name: z.string(),
        description: z.string(),
        price: z.number(),
        special: z.string().nullable(),
        warning: z.string().nullable()
    })
)
export type HttpUploadDishesFileResponse = z.infer<typeof HttpUploadDishesFileResponse>

export const httpUploadDishesFile = async (file: File): Promise<HttpUploadDishesFileResponse> => {
    const formData = new FormData()
    formData.append('file', file)
    const response = await axios.post('/~/dish/parse-csv', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    return response.data
}

/**
 * httpCreateDishes
 * Here we will create multiple dish instances
 */

// Response
export const HttpGetDishDetailsResponse = z.array(
    z.object({
        name: z.string(),
        description: z.string(),
        price: z.string(),
        status: z.string(),
        period: z.object({
            idPeriod: z.string()
        }),
        idDish: z.string()
    })
)
export type HttpGetDishDetailsResponse = z.infer<typeof HttpGetDishDetailsResponse>

export const HttpDishBodyRequestItem = z.object({
    idDish: z.string().optional(),
    name: z.string(),
    description: z.string().optional(),
    price: z.string(),
    status: z.string(),
    period: z.object({
        idPeriod: z.string()
    })
})
export type HttpDishBodyRequestItem = z.infer<typeof HttpDishBodyRequestItem>

export const HttpDishBodyRequest = z.array(HttpDishBodyRequestItem)
export type HttpDishBodyRequest = z.infer<typeof HttpDishBodyRequest>

export const HttpCreateDishParams = z.object({
    idEatery: z.string(),
    data: HttpDishBodyRequest
})
export type HttpCreateDishParams = z.infer<typeof HttpCreateDishParams>

export const httpCreateDishes = async (params: HttpCreateDishParams): Promise<HttpGetDishDetailsResponse> => {
    const response = await axios.post(`/~/eatery/${params.idEatery}/dish`, params.data)
    return response.data
}

/**
 * httpDeleteDishes
 * Here we will delete multiple dishes
 */

export const httpDeleteDishes = async (idRestaurant: string, idDish: string[]): Promise<void> => {
    await axios.delete(`/~/eatery/${idRestaurant}/dish`, {params: {idDish}})
}

/**
 * httpUploadDishImage
 * Here we will upload a new image resource for a dish
 */

// Response
export const HttpUploadImageResponse = z.void()
export type HttpUploadImageResponse = z.infer<typeof HttpUploadImageResponse>

export const httpUploadDishImage = async (file: File, name: string): Promise<HttpUploadImageResponse> => {
    const formData = new FormData()
    formData.append('file', file, `re_${ObjectID().toHexString()}`)
    formData.append('name', name)
    const response = await axios.post('/~/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    return response.data
}
