import {palette as mainPalette} from './palette.ts'
import {breakpoints, mediaQueries} from './mediaQueries.ts'
import ResetStyles from './reset.ts'
import {shadows} from './shadows.ts'
import {opacities} from './opacities.ts'
import {css, DefaultTheme} from 'styled-components'
import {typography} from './typography.ts'

const GlobalStyles = ResetStyles
export const spacing = 4

interface ThemeInterface {
    name: 'light'
    /*name: 'light' | 'dark'*/
    direction?: 'ltr' | 'rtl'
}

const getAppTheme = ({name}: ThemeInterface): DefaultTheme => {
    const palette = mainPalette[name]

    return {
        breakpoints,
        mediaQueries,
        palette,
        shadows,
        opacities,
        spacing,
        transition: css`
            transition: ease-in-out 200ms;
        `,
        typography,
        zIndex: {
            modalOverlay: 10,
            modal: 11,
            tooltip: 12,
            popover: 12
        }
    }
}

export {GlobalStyles, getAppTheme}
