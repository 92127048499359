import {SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {MENU_FORM_MODEL, MenuPageNameSchema, MenuPageNameValidationSchema} from './MenuFormModel'
import {useTranslation} from 'react-i18next'
import {StyledActionsWrapper, StyledMenuPageNameWrapper} from './style'
import {Button} from '@/components/ui/button/Button'
import React from 'react'
import {InputText} from '@/components/commons/input-text/InputText'
import {toast} from '@/utilities/toast/toast'
import {errorHandler} from '@/utilities/genericErrorHandler'
import {adaptMenuPageToCreate} from '../../utils'
import {useCreateRestaurantMenuPage} from '../../services/queries/useCreateRestaurantMenuPage'

interface MenuPageFormProps {
    onCancel?: () => void
    restaurantId: string
    menuId: string
    lastPagePosition: number
}

const MenuPageForm: React.FC<MenuPageFormProps> = ({onCancel, restaurantId, menuId, lastPagePosition}) => {
    const {t} = useTranslation()

    const {
        register,
        handleSubmit,
        formState: {errors, touchedFields, isValid, isDirty}
    } = useForm<MenuPageNameValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(MenuPageNameSchema)
    })

    // mutations
    const {mutate: createMenuMutation, isPending: isPendingCreate} = useCreateRestaurantMenuPage({
        idEatery: restaurantId,
        onSuccess: () => {
            toast.success(t('menu:new_menu_page_success'))
            onCancel?.()
        },
        onError: error => errorHandler(error)
    })

    // submit handler
    const onSubmit: SubmitHandler<MenuPageNameValidationSchema> = data => {
        createMenuMutation(adaptMenuPageToCreate(data, restaurantId, menuId, lastPagePosition))
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <StyledMenuPageNameWrapper>
                <InputText
                    type={'text'}
                    label={t(MENU_FORM_MODEL.pages.name.label)}
                    touched={touchedFields?.name}
                    errorMessage={t(errors?.name?.message || '')}
                    placeholder={t(MENU_FORM_MODEL.pages.name.label)}
                    {...register(MENU_FORM_MODEL.name.name)}
                />
            </StyledMenuPageNameWrapper>
            <StyledActionsWrapper gap={2} justify={'end'}>
                <Button type="button" onClick={onCancel} variant={'tertiary'} size="md" disabled={isPendingCreate}>
                    {t('commons:cancel')}
                </Button>
                <Button type="submit" variant="primary" size="md" disabled={!isValid || isPendingCreate || !isDirty}>
                    {t('commons:save')}
                </Button>
            </StyledActionsWrapper>
        </form>
    )
}

export default MenuPageForm
