import React from 'react'
import {useTranslation} from 'react-i18next'
import {Modal} from '@/components/ui/modal/Modal'
import {StyledModal, StyledTitle} from './style'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import CustomerForm from './CustomerForm'
import {CustomerValidationSchema} from './CustomerFormModel'

interface CustomerModalProps {
    onClose: () => void
    defaultValues?: CustomerValidationSchema
    customerId?: string
}

export const CustomerModal: React.FC<CustomerModalProps> = ({onClose, defaultValues, customerId}) => {
    const {t} = useTranslation()

    return (
        <Modal width={1000} closeIconOffset="sm" onClose={onClose}>
            <StyledModal>
                <Flexbox align={'center'}>
                    <StyledTitle>{t('customer:details:title')}</StyledTitle>
                </Flexbox>
                <CustomerForm customerId={customerId} defaultValues={defaultValues} onCancel={onClose} />
            </StyledModal>
        </Modal>
    )
}

export default CustomerModalProps
