import {z} from 'zod'

export const Menu = z.object({
    id: z.number(),
    name: z.string(),
    restaurantId: z.number(),
    creationDate: z.date(),
    lastUpdateDate: z.date()
})
export type Menu = z.infer<typeof Menu>

export const MenuListItem = z.object({
    name: z.string(),
    idMenu: z.string(),
    status: z.string(),
    idEatery: z.string(),
    timezone: z.string(),
    lastUpdate: z.string(),
    dateCreation: z.string()
})
export type MenuListItem = z.infer<typeof MenuListItem>

export const MenusSearchParams = z.object({
    orderBy: z.enum(['name']).optional(),
    orderDirection: z.enum(['asc', 'desc']).optional(),
    search: z.string().optional()
})
export type MenusSearchParams = z.infer<typeof MenusSearchParams>
