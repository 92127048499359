import {useInfiniteQuery} from '@tanstack/react-query'
import {QUERY_KEYS, infiniteQueryFetchNextPage} from '@/queryClient'
import {httpGetCustomers, HttpCustomersParams} from '@/features/customer/services/customer.http'

export const useCustomersQuery = (params: Omit<HttpCustomersParams, 'page' | 'per_page'>) => {
    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.CUSTOMERS, params.limit, params.orderBy, params.orderDirection, params.search],
        queryFn: ({pageParam = 1}) =>
            httpGetCustomers({
                ...params,
                page: parseInt(`${pageParam}`)
            }),
        initialPageParam: '1',
        getNextPageParam: ({paginateMetadata}) =>
            paginateMetadata.current_page < paginateMetadata.last_page ? `${paginateMetadata.current_page + 1}` : null
    })

    const remappedData = query.data?.pages[0]?.data?.length ? query.data.pages.flatMap(page => page.data) : []
    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        remappedData
    }
}
