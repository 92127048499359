import {Select} from '@/components/commons/select/Select'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 1fr;
        gap: ${spacing * 4}px;
    `
)

export const StyledActionsWrapper = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        margin-top: ${spacing * 8}px;
    `}
`

export const StyledModal = styled.div(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 6}px;
    `
)

export const StyledTitle = styled.span`
    ${({theme: {typography, palette, spacing}}) => css`
        ${typography.textLg}
        font-weight: bold;
        color: ${palette.neutral['900']};
        display: block;
        margin-bottom: ${spacing * 4}px;
    `}
`

export const StyledPlanSelect = styled(Select)(
    () => css`
        min-width: 200px;
    `
)

export const StyledLanguageSelect = styled(Select)(
    () => css`
        min-width: 30%;
    `
)

export const StyledCurrencySelect = styled(Select)(
    () => css`
        min-width: 30%;
    `
)

export const StyledAccountSelect = styled(Select)(
    () => css`
        min-width: 30%;
    `
)
