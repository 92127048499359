import {useMemo, useState} from 'react'

type SelectableItem = {
    index: number
    selected: boolean
}

export const useSelectableList = (initialState: SelectableItem[] = [], nonSelectableIndexes: number[] = []) => {
    const [items, setItems] = useState<SelectableItem[]>(initialState)

    const onSelectItem = (index: number) => {
        setItems(prev =>
            prev.map(item =>
                item.index === index
                    ? {
                          ...item,
                          selected: !item.selected
                      }
                    : item
            )
        )
    }

    const onSelectAll = () => {
        setItems(prev =>
            prev.map((item, index) => ({
                ...item,
                selected: nonSelectableIndexes.includes(index) ? false : !isAllSelected
            }))
        )
    }

    const isAllSelected = useMemo(
        () =>
            items.filter((_, index) => !nonSelectableIndexes.includes(index)).length !== 0 &&
            items.filter((item, index) => item.selected && !nonSelectableIndexes.includes(index)).length ===
                items.length - nonSelectableIndexes.length,
        [items]
    )

    const isNoneSelected = useMemo(() => items.filter(item => item.selected).length === 0, [items])

    return {
        items,
        setItems,
        onSelectItem,
        onSelectAll,
        isAllSelected,
        isNoneSelected
    }
}
