import styled, {DefaultTheme, css} from 'styled-components'

type StyledChipProps = {
    labelPosition: 'left' | 'right'
    variant: 'success' | 'warning' | 'default'
}

type ColorsProps = {
    theme: DefaultTheme
}

export const StyledChip = styled.div<StyledChipProps>(
    ({theme: {spacing, typography}, labelPosition, variant}) => css`
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        gap: ${spacing}px;
        padding: ${spacing / 2}px ${spacing * 2}px;
        border: 1px solid;
        font-weight: 500;
        border-radius: 16px;
        svg {
            order: ${labelPosition === 'left' ? '1' : '-1'};
        }
        ${typography.textXs};
        ${({theme}) => ColorStyles({theme})[variant]};
    `
)

const ColorStyles = ({theme: {palette}}: ColorsProps) => ({
    success: css`
        background-color: ${palette.success['50']};
        color: ${palette.success['700']};
        border-color: ${palette.success['200']};
    `,
    warning: css`
        background-color: ${palette.warning['50']};
        color: ${palette.warning['700']};
        border-color: ${palette.warning['200']};
    `,
    default: css`
        background-color: ${palette.neutral['50']};
        color: ${palette.secondary['700']};
        border-color: ${palette.neutral['200']};
    `
})
