import {
    Control,
    Controller,
    FieldErrors,
    UseFormGetValues,
    UseFormRegister,
    UseFormSetValue,
    useFieldArray
} from 'react-hook-form'
import React from 'react'
import {MENU_FORM_MODEL, MenuValidationSchema, PageDish} from './MenuFormModel'
import {StyledDishItemContent, StyledDishesActionsWrapper} from './style'
import {Button} from '@/components/ui/button/Button'
import {PlusIcon, Trash01Icon} from '@/components/ui/icon/Icon'
import {Checkbox} from '@/components/commons/checkbox/CheckBox'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import {List} from '@/components/commons/list/list/List'
import {ListItem} from '@/components/commons/list/list-item/ListItem'
import {InputText} from '@/components/commons/input-text/InputText'
import {useSelectableList} from '@/hooks/useSelectableList'
import {Select, SelectValue} from '@/components/commons/select/Select'
import {DishItem, PeriodItem} from '@/features/restaurant/types'

interface DishArrayFieldProps {
    control: Control<MenuValidationSchema>
    index: number
    getValues: UseFormGetValues<MenuValidationSchema>
    setValue: UseFormSetValue<MenuValidationSchema>
    touchedFields: Partial<
        Readonly<{
            name?: boolean | undefined
            pages?:
                | {
                      name?: boolean | undefined
                      dishes?:
                          | {
                                dish?: {value?: boolean | undefined; label?: boolean | undefined} | undefined
                                period?: {value?: boolean | undefined; label?: boolean | undefined} | undefined
                                price?: boolean | undefined
                            }[]
                          | undefined
                  }[]
                | undefined
        }>
    >
    errors: FieldErrors<MenuValidationSchema>
    register: UseFormRegister<MenuValidationSchema>
    pageIndex: number
    periods: PeriodItem[]
    dishes: DishItem[]
}

const DishesArrayField: React.FC<DishArrayFieldProps> = ({
    control,
    index,
    getValues,
    setValue,
    touchedFields,
    errors,
    register,
    pageIndex,
    periods,
    dishes
}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const {fields: rows, append: appendRow} = useFieldArray({
        control,
        name: `pages.${index}.dishes`
    })

    const {items, setItems, onSelectItem, onSelectAll, isAllSelected, isNoneSelected} = useSelectableList([
        {
            index: 0,
            selected: false
        }
    ])

    const onClickDeleteSelected = () => {
        const formDishes = getValues(`pages.${index}.dishes`)
        let counter = 0
        const updatedDishes: PageDish[] = []
        items.forEach((item, index) => {
            if (!item.selected) {
                updatedDishes.push(formDishes[index])
                counter++
            }
        })
        setValue(`pages.${index}.dishes`, updatedDishes)
        const updatedSelected = Array.from({length: counter}, (_, index) => ({index, selected: false}))
        setItems(updatedSelected)
    }

    const onClickAppend = () => {
        appendRow({
            dish: {value: '', label: t('commons:select_a_dish')},
            period: {value: '', label: t('commons:select_a_period')},
            price: 0
        })
        setItems(prev => [...prev, {index: prev.length, selected: false}])
    }

    return (
        <>
            <StyledDishesActionsWrapper>
                <span>{t('menu:addForm:dishesLabel')}</span>
                <Button
                    type="button"
                    variant="transparentDanger"
                    disabled={isNoneSelected}
                    onClick={onClickDeleteSelected}
                >
                    <Trash01Icon />
                    {t('commons:delete')}
                </Button>
                <Checkbox
                    disabled={items.length === 0}
                    checked={isAllSelected}
                    onChange={onSelectAll}
                    id="selectAll"
                    label={t('commons:select_all')}
                />
                <Button type="button" variant="secondary" onClick={onClickAppend}>
                    <PlusIcon stroke={theme.palette.neutral[500]} />
                    {t('menu:addForm:addDishButton')}
                </Button>
            </StyledDishesActionsWrapper>
            {rows.length === 0 && <span>{t('menu:addForm:emptyDishesList')}</span>}
            <List>
                {rows.map((field, index) => {
                    const checked = items.find(element => element.index === index && element.selected) !== undefined
                    return (
                        <ListItem isSelected={checked} key={field.id}>
                            <Checkbox checked={checked} onChange={() => onSelectItem(index)} id={`${index}`} />
                            <StyledDishItemContent>
                                <Controller
                                    render={({field: {onChange, value}}) => (
                                        <Select
                                            value={value}
                                            onChange={newValue => {
                                                const safeNewValue = newValue as SelectValue
                                                onChange(safeNewValue)
                                                const selectedDish = dishes.find(
                                                    dish => dish.idDish === safeNewValue.value
                                                )
                                                const selectedPeriod = periods.find(
                                                    period => period.idPeriod === selectedDish?.idPeriod
                                                )
                                                setValue(
                                                    `pages.${pageIndex}.dishes.${index}.period`,
                                                    {
                                                        value: selectedPeriod?.idPeriod ?? '',
                                                        label: selectedPeriod?.name ?? ''
                                                    },
                                                    {shouldValidate: true}
                                                )
                                                setValue(
                                                    `pages.${pageIndex}.dishes.${index}.price`,
                                                    selectedDish?.price ?? 0,
                                                    {shouldValidate: true}
                                                )
                                            }}
                                            size={'medium'}
                                            name={`pages.${pageIndex}.dishes.${index}.dish`}
                                            label={t(MENU_FORM_MODEL.pages.dishes.dish.label)}
                                            isClearable={false}
                                            isSearchable={true}
                                            errorMessage={t(
                                                errors?.pages?.[pageIndex]?.dishes?.[index]?.dish?.message || ''
                                            )}
                                            placeholder={t(MENU_FORM_MODEL.pages.dishes.dish.label)}
                                            options={dishes.map(item => ({
                                                value: item.idDish,
                                                label: item.name
                                            }))}
                                        />
                                    )}
                                    control={control}
                                    name={`pages.${pageIndex}.dishes.${index}.dish`}
                                />
                                <Controller
                                    render={({field: {onChange, value}}) => (
                                        <Select
                                            value={value}
                                            onChange={newValue => {
                                                onChange(newValue as SelectValue)
                                            }}
                                            size={'medium'}
                                            name={`pages.${pageIndex}.dishes.${index}.period`}
                                            label={t(MENU_FORM_MODEL.pages.dishes.period.label)}
                                            isClearable={false}
                                            isSearchable={true}
                                            errorMessage={t(
                                                errors?.pages?.[pageIndex]?.dishes?.[index]?.period?.message || ''
                                            )}
                                            placeholder={t(MENU_FORM_MODEL.pages.dishes.period.label)}
                                            options={periods.map(item => ({
                                                value: item.idPeriod,
                                                label: item.name
                                            }))}
                                        />
                                    )}
                                    control={control}
                                    name={`pages.${pageIndex}.dishes.${index}.period`}
                                />
                                <InputText
                                    type={'text'}
                                    label={t(MENU_FORM_MODEL.pages.dishes.price.label)}
                                    touched={touchedFields?.pages?.[pageIndex]?.dishes?.[index]?.price}
                                    errorMessage={t(errors?.pages?.[pageIndex]?.dishes?.[index]?.price?.message || '')}
                                    placeholder={t(MENU_FORM_MODEL.pages.dishes.price.label)}
                                    {...register(`pages.${pageIndex}.dishes.${index}.price`)}
                                />
                            </StyledDishItemContent>
                        </ListItem>
                    )
                })}
            </List>
        </>
    )
}

export default DishesArrayField
