import styled, {css} from 'styled-components'

export const StyledExpiryDateWrapper = styled.div(
    () => css`
        width: 300px;
    `
)

export const StyledFiltersWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: ${spacing * 4}px;
        width: 100%;
        margin: ${spacing * 4}px 0;
    `
)
