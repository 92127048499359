import {FC, ReactNode} from 'react'
import {StyledBox, StyledTitle} from './style'

interface HomeBoxProps {
    children: ReactNode
    className?: string
    title?: string
}
export const HomeBox: FC<HomeBoxProps> = ({children, className, title}) => {
    return (
        <StyledBox className={className}>
            {title && <StyledTitle>{title}</StyledTitle>}
            {children}
        </StyledBox>
    )
}

HomeBox.displayName = 'HomeBox'
