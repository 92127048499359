import {Box} from '@/components/commons/box/Box'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {
    StyledActionsWrapper,
    StyledDishImageWrapper,
    StyledDishInfoWrapper,
    StyledDishMainWrapper,
    StyledDishPriceWrapper,
    StyledMainWrapper,
    StyledTemplateLink,
    StyledUploadTitle
} from './style'
import {Button} from '@/components/ui/button/Button'
import {ImageIcon, Trash01Icon} from '@/components/ui/icon/Icon'
import {Checkbox} from '@/components/commons/checkbox/CheckBox'
import {List} from '@/components/commons/list/list/List'
import {ListItem} from '@/components/commons/list/list-item/ListItem'
import {downloadFile, formatCurrency} from '@/utilities/helpers'
import {useSelectableList} from '@/hooks/useSelectableList'
import {Uploader} from '@/components/commons/uploader/Uploader'
import {DeleteModal} from '@/components/commons/delete-modal/DeleteModal'
import {useUploadDishesFile} from '../../services/queries/useUploadDishesFile'
import {toast} from '@/utilities/toast/toast'
import {DishItem, PeriodItem} from '@/features/restaurant/types'
import {EmptyList} from '@/components/commons/empty-list/EmptyList'
import {HttpUploadDishesFileResponse} from '../../services/restaurantDishes.http'
import {useDeleteDishes} from '../../services/queries/useDeleteDishes'
import {DishModal} from '../dish-form/DishModal'
import {useTheme} from 'styled-components'

type DishesListProps = {
    dishes: DishItem[]
    onUploadDishes: (dishes: HttpUploadDishesFileResponse) => void
    periods: PeriodItem[]
}

export const DishesList: React.FC<DishesListProps> = ({dishes, onUploadDishes, periods}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const {id: restaurantId} = useParams()
    const {items, setItems, onSelectItem, onSelectAll, isAllSelected, isNoneSelected} = useSelectableList()
    const [file, setFile] = useState<File | undefined>(undefined)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [isDishModalOpen, setIsDishModalOpen] = useState(false)
    const {mutate: deleteDishes, isPending: isPendingDeleteDishes} = useDeleteDishes({idRestaurant: `${restaurantId}`})
    const {
        data: uploadedDishes,
        mutateAsync: uploadDishesFile,
        isPending: isPendingUploadDishesFile
    } = useUploadDishesFile({
        restaurantId: restaurantId ?? '',
        onSuccess: () => toast.success(t('dish:list:uploadSuccessful'))
    })

    useEffect(() => {
        if (uploadedDishes?.length) {
            onUploadDishes(uploadedDishes)
        }
    }, [uploadedDishes?.length])

    useEffect(() => {
        if (dishes && dishes?.length) {
            setItems(
                dishes?.map((_, index) => ({
                    index,
                    selected: false
                }))
            )
        }
    }, [dishes?.length])

    const onChangeFile = (uploadedFile: File) => {
        setFile(uploadedFile)
        uploadDishesFile(uploadedFile)
        setFile(undefined)
    }

    const onCloseDeleteModal = () => {
        setIsDeleteModalVisible(false)
    }

    const onSubmitDeleteModal = () => {
        setIsDeleteModalVisible(false)
        deleteDishes(items.filter(item => item.selected).map(item => dishes[item.index].idDish))
    }

    return (
        <>
            <Box>
                <StyledMainWrapper>
                    <div>
                        <StyledUploadTitle>{t('commons:upload_file')}</StyledUploadTitle>
                        <Uploader
                            maxSizeInMB={10}
                            accept={{'text/csv': []}}
                            value={file}
                            onChange={onChangeFile}
                            downloadCallback={downloadFile}
                            isLoading={isPendingUploadDishesFile}
                        />
                        <StyledTemplateLink href="/template.csv" target="_blank">
                            {t('commons:download_template')}
                        </StyledTemplateLink>
                    </div>
                    {dishes.length ? (
                        <div>
                            <StyledActionsWrapper>
                                <span>{t('dish:list:label')}</span>
                                <Button
                                    type="button"
                                    variant="transparentDanger"
                                    disabled={isNoneSelected}
                                    onClick={() => setIsDeleteModalVisible(true)}
                                >
                                    <Trash01Icon />
                                    {t('commons:delete')}
                                </Button>
                                <Button
                                    type="button"
                                    variant="secondary"
                                    disabled={isNoneSelected}
                                    onClick={() => setIsDishModalOpen(true)}
                                >
                                    {t('commons:update')}
                                </Button>
                                <Checkbox
                                    disabled={items.length === 0}
                                    checked={isAllSelected}
                                    onChange={onSelectAll}
                                    id="selectAll"
                                    label={t('commons:select_all')}
                                />
                            </StyledActionsWrapper>
                            <List>
                                {dishes?.map((dish, dishIndex) => {
                                    const checked =
                                        items.find((element, index) => index === dishIndex && element.selected) !==
                                        undefined
                                    const period = periods.find(period => period.idPeriod === dish.idPeriod)
                                    return (
                                        <ListItem key={dish.idDish} isSelected={checked}>
                                            <Checkbox
                                                checked={checked}
                                                onChange={() => onSelectItem(dishIndex)}
                                                id={`${dish.idDish}`}
                                            />
                                            <StyledDishMainWrapper>
                                                {dish.resource ? (
                                                    <StyledDishImageWrapper>
                                                        <img className="image" src={dish.resource.URL} alt="Dish" />
                                                    </StyledDishImageWrapper>
                                                ) : (
                                                    <StyledDishImageWrapper>
                                                        <ImageIcon
                                                            fill={theme.palette.neutral['400']}
                                                            size={50}
                                                            viewBox="0 0 29 28"
                                                        />
                                                    </StyledDishImageWrapper>
                                                )}
                                                <StyledDishInfoWrapper>
                                                    <span className="name">{dish.name}</span>
                                                    <span className="period">{period?.name ?? '--'}</span>
                                                    <span className="description">{dish.description}</span>
                                                </StyledDishInfoWrapper>
                                                <StyledDishPriceWrapper>
                                                    <span className="price">{formatCurrency(dish.price, 'EUR')}</span>
                                                </StyledDishPriceWrapper>
                                            </StyledDishMainWrapper>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </div>
                    ) : (
                        <EmptyList />
                    )}
                </StyledMainWrapper>
            </Box>
            {isDeleteModalVisible && (
                <DeleteModal
                    title={t('commons:delete_items_title')}
                    paragraph={t('commons:delete_items_description')}
                    isLoading={isPendingDeleteDishes}
                    onClose={onCloseDeleteModal}
                    onDelete={onSubmitDeleteModal}
                />
            )}
            {isDishModalOpen && (
                <DishModal
                    periods={periods}
                    onClose={() => {
                        setIsDishModalOpen(false)
                        setItems(
                            dishes?.map((_, index) => ({
                                index,
                                selected: false
                            }))
                        )
                    }}
                    restaurantId={restaurantId ?? ''}
                    defaultValues={{
                        dishes: dishes
                            .filter((_, index) => items.find(item => item.index === index && item.selected))
                            .map(dish => ({
                                idDish: dish.idDish,
                                name: dish.name,
                                description: dish.description ?? '',
                                period: {
                                    value: dish.idPeriod,
                                    label: periods.find(period => period.idPeriod === dish.idPeriod)?.name ?? ''
                                },
                                price: dish.price
                            }))
                    }}
                    defaultImages={dishes
                        .filter((_, index) => items.find(item => item.index === index && item.selected))
                        .map((dish, index) => ({
                            file: undefined,
                            image: dish.resource ? dish.resource.URL : undefined,
                            index
                        }))}
                />
            )}
        </>
    )
}

export default DishesList
