import {Box} from '@/components/commons/box/Box'
import {Button} from '@/components/ui/button/Button'
import {Chip} from '@/components/ui/chip/Chip'
import {Container} from '@/components/ui/container/Container'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {CheckIcon} from '@/components/ui/icon/Icon'
import {Spinner} from '@/components/ui/spinner/Spinner'
import AccountForm from '@/features/account/components/account-form/AccountForm'
import {AccountModal} from '@/features/account/components/account-form/AccountModal'
import {adaptAccountToForm} from '@/features/account/components/account-form/utils'
import {useGetAccountDetails} from '@/features/account/services/queries/useGetAccountDetails'
import {useVerifyAccount} from '@/features/account/services/queries/useVerifyAccount'
import {DetailsPageLayout} from '@/layouts/details-page-layout/DetailsPageLayout'
import {errorHandler} from '@/utilities/genericErrorHandler'
import {toast} from '@/utilities/toast/toast'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {useTheme} from 'styled-components'

export const AccountDetails = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const {id: accountId} = useParams()
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
    const accountQuery = useGetAccountDetails(
        {
            accountId: accountId ?? ''
        },
        {enabled: !!accountId}
    )

    useEffect(() => {
        if (accountQuery.isError) toast.error(accountQuery.error?.message)
    }, [accountQuery.isError])

    // mutations
    const {mutate: verifyAccountMutation, isPending: isPendingVerify} = useVerifyAccount({
        onSuccess: () => {
            toast.success(t('account:account_verified_success'))
        },
        onError: error => errorHandler(error)
    })

    return (
        <>
            <DetailsPageLayout
                title={accountQuery.data?.displayName || ''}
                subtitle={accountQuery.data?.idAccount || ''}
                sideContent={
                    <Flexbox align="center" gap={4}>
                        {accountQuery.data && (
                            <>
                                {accountQuery.data?.emailVerified ? (
                                    <Chip
                                        icon={<CheckIcon size={14} />}
                                        id={`${accountQuery.data.idAccount}`}
                                        variant="success"
                                        label={t('account:verified')}
                                    />
                                ) : (
                                    <Button
                                        disabled={isPendingVerify}
                                        onClick={() =>
                                            verifyAccountMutation({idAccount: accountQuery.data?.idAccount ?? ''})
                                        }
                                        variant="secondary"
                                    >
                                        {t('account:verify')}
                                        {isPendingVerify && <Spinner size={16} />}
                                    </Button>
                                )}
                            </>
                        )}
                        <Button onClick={() => setIsInfoModalOpen(true)}>{t('commons:update_info')}</Button>
                    </Flexbox>
                }
            >
                <Container fullWidth>
                    {accountQuery.isLoading && (
                        <Flexbox justify="center">
                            <Spinner color={theme.palette.neutral[400]} size={30} />
                        </Flexbox>
                    )}
                    {accountQuery.data && (
                        <Box>
                            <AccountForm
                                readOnly
                                defaultValues={adaptAccountToForm(accountQuery.data)}
                                accountId={accountId ?? ''}
                            />
                        </Box>
                    )}
                </Container>
            </DetailsPageLayout>
            {isInfoModalOpen && (
                <AccountModal
                    accountId={accountId ?? ''}
                    defaultValues={accountQuery.data ? adaptAccountToForm(accountQuery.data) : undefined}
                    onClose={() => setIsInfoModalOpen(false)}
                />
            )}
        </>
    )
}

AccountDetails.displayName = 'AccountDetails'
