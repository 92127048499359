import {z} from 'zod'

export const Account = z.object({
    id: z.coerce.number(),
    lastLogin: z.date(),
    lastUpdate: z.date(),
    creationDate: z.date(),
    registrationDate: z.date(),
    name: z.string(),
    email: z.string().nullable(),
    phoneNumber: z.string().nullable(),
    language: z.string().nullable(),
    currency: z.object({
        value: z.string(),
        label: z.string()
    }),
    verified: z.boolean()
})
export type Account = z.infer<typeof Account>

export const AccountListItem = z.object({
    email: z.string(),
    mobile: z.string().nullable(),
    language: z.string(),
    idAccount: z.string(),
    displayName: z.string().nullable(),
    dateCreation: z.string(),
    emailVerified: z.number()
})
export type AccountListItem = z.infer<typeof AccountListItem>

export const AccountsSearchParams = z.object({
    orderBy: z.enum(['displayName', 'dateCreation']).optional(),
    orderDirection: z.enum(['asc', 'desc']).optional(),
    search: z.string().optional()
})
export type AccountsSearchParams = z.infer<typeof AccountsSearchParams>
