import i18n from '@/translations/i18n'
import {RestaurantValidationSchema} from './components/restaurant-form/RestaurantFormModel'
import {HttpGetRestaurantDetailsResponse, HttpUpdateRestaurantParams} from './services/restaurant.http'
import {CURRENCIES, LANGUAGES} from '@/utilities/constants/common'
import {Plan} from '../plan/types'
import dayjs from 'dayjs'

export const MEMBER_ROLES = [
    {
        value: 'manager',
        label: 'restaurant:memberRoles:manager'
    },
    {
        value: 'maitre',
        label: 'restaurant:memberRoles:maitre'
    },
    {
        value: 'chef',
        label: 'restaurant:memberRoles:chef'
    },
    {
        value: 'bartender',
        label: 'restaurant:memberRoles:bartender'
    },
    {
        value: 'assistant',
        label: 'restaurant:memberRoles:assistant'
    },
    {
        value: 'waiter',
        label: 'restaurant:memberRoles:waiter'
    }
]

export const adaptRestaurantToForm = (
    data: HttpGetRestaurantDetailsResponse,
    plans: Plan[]
): RestaurantValidationSchema => {
    const language = LANGUAGES.find(item => item.value === data.language)
    const currency = CURRENCIES.find(item => item.value === data.currency)
    const plan = plans.find(item => item.code === data.plan)
    const owner = data.crew?.find(item => item.permission === 'OWNER')
    return {
        name: data.name ?? '',
        language: {value: language?.value ?? '', label: i18n.t(language?.label ?? '')},
        currency: {value: currency?.value ?? '', label: i18n.t(currency?.label ?? '')},
        owner: {value: owner?.idAccount ?? '', label: owner?.displayName ?? ''},
        plan: {value: plan?.code.toString() ?? '', label: plan?.name ?? ''},
        activationDate: new Date(data.dateCreation),
        expiryDate: new Date(data.licenseExpire),
        CAP: data.billingInfo?.CAP ?? '',
        PEC: data.billingInfo?.PEC ?? '',
        city: data.billingInfo?.city ?? '',
        SDICode: data.billingInfo?.SDICode ?? '',
        address: data.billingInfo?.address ?? '',
        country: data.billingInfo?.country ?? '',
        province: data.billingInfo?.province ?? '',
        vatNumber: data.billingInfo?.vatNumber ?? '',
        fiscalCode: data.billingInfo?.fiscalCode ?? '',
        businessName: data.billingInfo?.businessName ?? ''
    }
}

export const adaptRestaurantToCreate = (data: RestaurantValidationSchema): HttpUpdateRestaurantParams => ({
    data: {
        generalInfo: {
            name: data.name,
            language: data.language.value,
            currency: data.currency.value,
            delivery: 0,
            deliveryPayAfter: 0,
            deliveryPayBefore: 0,
            eatingTime: 15,
            eatOnSpot: 1,
            locations: 0,
            paymentAvailable: 0,
            prepayment: 0,
            status: 'DRAFT',
            stripeSetupCompleted: 0,
            takeAway: 0,
            takeAwayPayAfter: 0,
            takeAwayPayBefore: 0,
            timezone: 'Europe/Rome'
        },
        plan: {
            owner: data.owner.value,
            plan: +data.plan.value,
            activationDate: dayjs(data.activationDate).format('YYYY-MM-DD'),
            expiryDate: dayjs(data.expiryDate).format('YYYY-MM-DD')
        },
        billingInfo: {
            businessName: data.businessName,
            address: data.address,
            CAP: data.CAP,
            PEC: data.PEC,
            city: data.city,
            SDICode: data.SDICode,
            country: data.country,
            province: data.province,
            vatNumber: data.vatNumber,
            fiscalCode: data.fiscalCode
        }
    }
})

export const adaptRestaurantToUpdate = (
    idEatery: string,
    data: RestaurantValidationSchema
): HttpUpdateRestaurantParams => ({
    data: {
        generalInfo: {
            idEatery,
            name: data.name,
            language: data.language.value,
            currency: data.currency.value,
            delivery: 0,
            deliveryPayAfter: 0,
            deliveryPayBefore: 0,
            eatingTime: 15,
            eatOnSpot: 1,
            locations: 0,
            paymentAvailable: 0,
            prepayment: 0,
            status: 'DRAFT',
            stripeSetupCompleted: 0,
            takeAway: 0,
            takeAwayPayAfter: 0,
            takeAwayPayBefore: 0,
            timezone: 'Europe/Rome'
        },
        plan: {
            owner: data.owner.value,
            plan: +data.plan.value,
            activationDate: dayjs(data.activationDate).format('YYYY-MM-DD'),
            expiryDate: dayjs(data.expiryDate).format('YYYY-MM-DD')
        },
        billingInfo: {
            businessName: data.businessName,
            address: data.address,
            CAP: data.CAP,
            PEC: data.PEC,
            city: data.city,
            SDICode: data.SDICode,
            country: data.country,
            province: data.province,
            vatNumber: data.vatNumber,
            fiscalCode: data.fiscalCode
        }
    }
})
