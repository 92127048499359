import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpDeleteBeverages} from '../restaurantBeverages.http'
import toast from 'react-hot-toast'
import {useTranslation} from 'react-i18next'

export const useDeleteBeverages = ({idRestaurant}: {idRestaurant: string}) => {
    const client = useQueryClient()
    const {t} = useTranslation()

    return useMutation({
        mutationKey: [MUTATION_KEYS.DELETE_BEVERAGES],
        mutationFn: (idBeverages: string[]) => httpDeleteBeverages(idRestaurant, idBeverages),
        onSuccess: () => {
            toast.success(t('commons:delete_completed', {entity: t('dish:plural')}))
            client.invalidateQueries({queryKey: [QUERY_KEYS.RESTAURANT_DETAILS, idRestaurant]})
        }
    })
}
