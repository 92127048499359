import {typography} from '@/theme/typography'
import styled, {css} from 'styled-components'

export const StyledSectionTitleWrapper = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: grid;
        align-items: center;
        grid-template-columns: 1fr auto;
        gap: ${spacing * 8}px;
        margin-bottom: ${spacing * 8}px;
        & > .title {
            ${typography.textLg}
            color: ${palette.neutral[900]};
            font-weight: 700;
        }
    `}
`

export const StyledButtonsWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 8}px;
    `}
`

export const ButtonGroup = styled.div`
    ${({theme: {palette}}) => css`
        display: flex;
        & > button {
            border-radius: 0;
            box-shadow: none;
            border: none;
            outline: none;
        }
        & > button:first-child {
            border-radius: 8px 0 0 8px;
            border-left: solid 1px ${palette.neutral[300]};
            border-top: solid 1px ${palette.neutral[300]};
            border-bottom: solid 1px ${palette.neutral[300]};
        }
        & > button:last-child {
            border-radius: 0 8px 8px 0;
            border-top: solid 1px ${palette.neutral[300]};
            border-right: solid 1px ${palette.neutral[300]};
            border-bottom: solid 1px ${palette.neutral[300]};
        }
        & > button:not(:first-child),
        button:not(:last-child) {
            border-top: solid 1px ${palette.neutral[300]};
            border-bottom: solid 1px ${palette.neutral[300]};
            border-right: solid 1px ${palette.neutral[300]};
        }
    `}
`

export const StyledCustomDateWrapper = styled.div(
    () => css`
        width: 300px;
    `
)
