import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledEmptyResults = styled(Flexbox)(
    ({theme: {spacing, palette, typography}}) => css`
        width: 100%;
        background: ${palette.neutral['50']};
        border-radius: 8px;
        border: 1px solid ${palette.neutral['200']};
        padding: ${spacing * 10}px;
        text-align: center;
        height: 75%;

        h3 {
            ${typography.textMd};
            font-weight: 600;
            color: ${palette.neutral['900']};
            margin-top: ${spacing * 4}px;
        }

        h4 {
            ${typography.textSm};
        }

        a,
        button {
            margin: ${spacing * 6}px 0;
        }
    `
)
