import {QUERY_KEYS} from '@/queryClient'
import {UseQueryOptions, useQuery} from '@tanstack/react-query'
import {
    HttpGetRestaurantDetailsParams,
    HttpGetRestaurantDetailsResponse,
    httpGetRestaurantDetails
} from '../restaurant.http'

export const useGetRestaurantDetails = (
    params: HttpGetRestaurantDetailsParams,
    options?: Omit<UseQueryOptions<HttpGetRestaurantDetailsResponse>, 'queryFn' | 'queryKey'>
) => {
    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.RESTAURANT_DETAILS, params.restaurantId],
        queryFn: () => httpGetRestaurantDetails(params)
    })
}
