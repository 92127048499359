import React from 'react'
import {useTranslation} from 'react-i18next'
import {Modal} from '@/components/ui/modal/Modal'
import {StyledModal, StyledTitle} from './style'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import RestaurantForm from './RestaurantForm'
import {RestaurantValidationSchema} from './RestaurantFormModel'

interface RestaurantModalProps {
    onClose: () => void
    defaultValues?: RestaurantValidationSchema
    restaurantId?: string
}

export const RestaurantModal: React.FC<RestaurantModalProps> = ({onClose, defaultValues, restaurantId}) => {
    const {t} = useTranslation()

    return (
        <Modal width={1000} closeIconOffset="sm" onClose={onClose}>
            <StyledModal>
                <Flexbox align={'center'}>
                    <StyledTitle>{t('restaurant:title')}</StyledTitle>
                </Flexbox>
                <RestaurantForm onCancel={onClose} defaultValues={defaultValues} restaurantId={restaurantId} />
            </StyledModal>
        </Modal>
    )
}

export default RestaurantModalProps
