import i18next from 'i18next'
import {FieldError} from 'react-hook-form'
import {toast} from './toast/toast'
import i18n from '@/translations/i18n'
import dayjs, {ConfigType} from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import {z} from 'zod'

dayjs.extend(LocalizedFormat)

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

type ReactSelect = {
    label: string
    value: string | number
}
export const retrieveSelectSingleValue = (options: ReactSelect[], value: string) => {
    return options.find(option => option.value.toString() == value)
}

export const retrieveSelectMultiValues = (options: ReactSelect[], values: [string]) => {
    return options.filter(option => values.find(value => value.toString() == option.value.toString()))
}

export const getInitials = (name: string) => {
    return name.match(/\b\w/g) || []
}

export const megabytesToBytes = (megabytes: number) => megabytes * 1_000_000

/**
 * Function that throws an error, it's useful to prevent impossible states in the application.
 * Example: const id = params.id ?? raise('No id provided'), in this case id is a string instead of string | undefined
 * @param error
 */
export const raise = (error: string): never => {
    throw new Error(error)
}

export const debounce = <T extends (...args: Parameters<T>) => ReturnType<T>>(
    callback: T,
    delay: number
): ((...args: Parameters<T>) => void) => {
    let timeout: ReturnType<typeof setTimeout>

    return (...args: Parameters<T>) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            callback(...args)
        }, delay)
    }
}

type Field<T extends string> = `${T}`

export type GeneralError = Error & {
    response?: {data: {message: string}; status: number}
}

export type ValidationError<T extends string> = Error & {
    response?: {data: {errors: [{field: Field<T>; message: string}]}}
}

const getErrorMessage = <T extends string>(error: ValidationError<T> | GeneralError) => {
    if (typeof error === 'object' && error !== null) {
        const response = error?.response
        if (typeof response?.data === 'string') {
            return response.data
        } else {
            if (response && response.data) {
                if ('message' in response.data) {
                    return response.data.message
                } else if ('errors' in response.data && response.data.errors.length > 0) {
                    return response.data.errors[0].message
                }
            }
        }
    }
    if (error instanceof Error && error.message) {
        return error.message
    }
    return 'An unknown error occurred.'
}

type handleApiErrorProps<T extends string> = {
    setError?: (field: Field<T>, arg: FieldError) => void
    error: ValidationError<T>
}

export const handleApiError = <T extends string>({setError, error}: handleApiErrorProps<T>) => {
    const t = i18next.t.bind(i18next)
    const errorsList = error?.response?.data?.errors
    if (error) {
        // Errors with field name for react-hook-form
        if (errorsList?.length && setError) {
            errorsList?.map(err => {
                const field = err?.field

                return setError(field, {
                    type: 'backend_error',
                    message: `api_error:${err?.message}`
                })
            })
        } else {
            // General API errors
            const getTranslatedMessage = (errorMessage: string) => t(`api_error:${errorMessage}`)
            const translatedValidationMessage = getTranslatedMessage(getErrorMessage(error))
            return toast.error(translatedValidationMessage)
        }
    }
}

/**
 * Function that given an amount and currency will return the properly formatted string
 * @param amount
 * @param currency
 */
export const formatCurrency = (amount = 0, currency?: string) =>
    new Intl.NumberFormat('en', {
        style: 'currency',
        currency: currency ?? 'USD'
    }).format(amount)

/*date time helpers*/
export const formatLocaleDate = (date: NonNullable<ConfigType>, formatType?: string) =>
    dayjs(date)
        .locale(i18n.language)
        .format(formatType || 'll')

export const formatDate = (date: string, formatType = 'YYYY-MM-DD') => (date ? dayjs(date).format(formatType) : '-')

/*truncateText util*/
export const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text
}

export const downloadFile = (file: File | undefined) => {
    if (!file) return
    const fileLocalURL = URL.createObjectURL(file)
    const link = document.createElement('a')
    link.href = fileLocalURL
    link.download = file.name
    link.click()
    URL.revokeObjectURL(fileLocalURL)
}

/**
 * Function that format a number to have thousands separators
 * @param number
 */
export const formaThousandsNumber = (num: number): string => {
    return num.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
}

/**
 * Function that takes an optional value for zod validation
 * @param schema
 * @param message
 */
export const optionalTextInput = (schema: z.ZodString, message?: string) =>
    z.union([z.string(), z.undefined()]).refine(val => !val || schema.safeParse(val).success, {message: message})
