import styled, {css} from 'styled-components'
import {DPDayRange} from '@rehookify/datepicker'
import {Button} from '@components/ui/button/Button.tsx'

export const StyledContainer = styled.div`
    display: inline-block;
    z-index: 99;
`

export const CalendarsWrapper = styled.div`
    ${({theme: {spacing, mediaQueries, palette}}) => css`
        display: flex;
        flex-direction: row;

        border-bottom: 1px solid ${palette.neutral['200']};

        ${mediaQueries.m} {
            flex-direction: column;
            gap: ${spacing * 2}px;
        }
    `}
`

export const CalendarWrapper = styled.div`
    ${({theme: {spacing, palette, mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${spacing * 6}px;
        padding: ${spacing * 5}px;
        height: 392px;
        width: 320px;

        &:not(:last-child) {
            border-right: 1px solid ${palette.neutral['200']};
            ${mediaQueries.m} {
                border-right: unset;
            }
        }
    `}
`
export const StyledCalendarHeader = styled.div`
    ${({theme: {palette}}) => css`
        display: grid;
        place-items: center;
        position: relative;
        width: 100%;
        font-weight: 800;

        font-size: 18px;
        line-height: 24px;
        color: ${palette.neutral.black};

        .clickableText {
            cursor: pointer;
        }

        .clickableText:hover {
            color: ${palette.primary['600']};
        }

        .dayButton {
            width: 40px;
        }

        .yearButton {
            width: 90px;
        }
        .prevButton {
            left: 0;
            position: absolute;
            display: grid;
            place-items: center;
            cursor: pointer;
        }

        .nextButton {
            right: 0;
            position: absolute;
            display: grid;
            place-items: center;
            cursor: pointer;
        }
    `}
`

export const StyledDayGrid = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(7, 40px);
        row-gap: ${spacing}px;
    `}
`

export const StyledYearMonthGrid = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(3, 80px);
        row-gap: ${spacing * 5}px;
        column-gap: ${spacing * 5}px;
    `}
`

export const StyledWeekDayContainer = styled.div`
    ${({theme: {palette}}) => css`
        display: grid;
        place-items: center;
        line-height: 20px;
        font-weight: 700;
        font-size: 14px;
        color: ${palette.neutral.black};
    `}
`

type DayButtonWrapperProps = {
    $rangeStatus: DPDayRange
    $inCurrentMonth: boolean
}

const styleByRangeStatus = ($rangeStatus: DayButtonWrapperProps['$rangeStatus']) => {
    switch ($rangeStatus) {
        case 'in-range':
            return css`
                ${({theme: {palette}}) => css`
                    background-color: ${palette.primary['200']};
                    border-radius: 0;
                `}
            `
        case 'range-start':
            return css`
                ${({theme: {palette}}) => css`
                    background-color: ${palette.primary['200']};
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                `}
            `
        case 'range-end':
            return css`
                ${({theme: {palette}}) => css`
                    background-color: ${palette.primary['200']};
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                `}
            `
        case 'will-be-in-range':
            return css`
                ${({theme: {palette}}) => css`
                    background-color: ${palette.primary['100']};
                    border-radius: 0;
                `}
            `
        case 'will-be-range-start':
            return css`
                ${({theme: {palette}}) => css`
                    background-color: ${palette.primary['100']};
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                `}
            `
        case 'will-be-range-end':
            return css`
                ${({theme: {palette}}) => css`
                    background-color: ${palette.primary['100']};
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                `}
            `
    }
}

export const DayButtonWrapper = styled.div<DayButtonWrapperProps>`
    ${({$rangeStatus, $inCurrentMonth}) => css`
        display: flex;
        border-radius: 100%;

        &:nth-child(7n),
        &.lastMonthDay {
            border-top-right-radius: 100%;
            border-bottom-right-radius: 100%;
        }

        &:nth-child(7n + 1),
        &.firstMonthDay {
            border-top-left-radius: 100%;
            border-bottom-left-radius: 100%;
        }

        ${$inCurrentMonth &&
        css`
            ${styleByRangeStatus($rangeStatus)}
        `}
    `}
`

export const StyledDayButton = styled(Button)`
    ${({theme: {palette}}) => css`
        &:disabled {
            color: ${palette.neutral['300']};
        }
        padding: 0;
        display: grid;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        place-items: center;
    `}
`

export const StyledMonthYearButton = styled(Button)`
    ${({theme: {spacing}}) => css`
        cursor: pointer;
        padding: ${spacing * 2}px;
        text-align: center;
    `}
`

export const StyledBackButton = styled(Button)`
    ${({theme: {palette}}) => css`
        color: ${palette.primary['600']};
        margin-left: auto;
        margin-top: auto;
    `}
`
