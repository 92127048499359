import {SubmitHandler, useFieldArray, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {MENU_FORM_MODEL, MenuFormSchema, MenuPage, MenuValidationSchema} from './MenuFormModel'
import {useTranslation} from 'react-i18next'
import {
    StyledActionsWrapper,
    StyledMenuNameWrapper,
    StyledPageCheckboxWrapper,
    StyledPageNameWrapper,
    StyledPagesActionsWrapper,
    StyledPageItemWrapper
} from './style'
import {Button} from '@/components/ui/button/Button'
import React from 'react'
import {useTheme} from 'styled-components'
import {PlusIcon, Trash01Icon} from '@/components/ui/icon/Icon'
import {Checkbox} from '@/components/commons/checkbox/CheckBox'
import {Box} from '@/components/commons/box/Box'
import {InputText} from '@/components/commons/input-text/InputText'
import {ListItem} from '@/components/commons/list/list-item/ListItem'
import {List} from '@/components/commons/list/list/List'
import DishesArrayField from './DishesArrayField'
import {useSelectableList} from '@/hooks/useSelectableList'
import {DishItem, PeriodItem} from '@/features/restaurant/types'
import {toast} from '@/utilities/toast/toast'
import {errorHandler} from '@/utilities/genericErrorHandler'
import {useCreateRestaurantMenu} from '../../services/queries/useCreateRestaurantMenu'
import {adaptMenuToCreate} from '../../utils'

interface MenuFormProps {
    onCancel?: () => void
    periods: PeriodItem[]
    restaurantId: string
    dishes: DishItem[]
}

const MenuForm: React.FC<MenuFormProps> = ({onCancel, periods, restaurantId, dishes}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    const {items, setItems, onSelectItem, onSelectAll, isAllSelected, isNoneSelected} = useSelectableList([
        {
            index: 0,
            selected: false
        }
    ])

    const onClickDeleteSelectedPages = () => {
        const formPages = getValues('pages')
        let counter = 0
        const updatedPages: MenuPage[] = []
        items.forEach((item, index) => {
            if (!item.selected) {
                updatedPages.push(formPages[index])
                counter++
            }
        })
        setValue('pages', updatedPages)
        const updatedSelected = Array.from({length: counter}, (_, index) => ({index, selected: false}))
        setItems(updatedSelected)
    }

    const onClickAppendPage = () => {
        appendPage({
            name: '',
            dishes: [
                {
                    dish: {value: '', label: t('commons:select_a_dish')},
                    period: {value: '', label: t('commons:select_a_period')},
                    price: 0
                }
            ]
        })
        setItems(prev => [...prev, {index: prev.length, selected: false}])
    }

    const {
        control,
        register,
        handleSubmit,
        formState: {errors, touchedFields, isValid, isDirty},
        setValue,
        getValues
    } = useForm<MenuValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(MenuFormSchema),
        defaultValues: {
            pages: [
                {
                    name: '',
                    dishes: [
                        {
                            dish: {value: '', label: t('commons:select_a_dish')},
                            period: {value: '', label: t('commons:select_a_period')},
                            price: 0
                        }
                    ]
                }
            ]
        }
    })

    const {fields: pages, append: appendPage} = useFieldArray({
        control,
        name: 'pages'
    })

    // mutations
    const {mutate: createMenuMutation, isPending: isPendingCreate} = useCreateRestaurantMenu({
        idEatery: restaurantId,
        onSuccess: () => {
            toast.success(t('menu:new_menu_success'))
            onCancel?.()
        },
        onError: error => errorHandler(error)
    })

    // submit handler
    const onSubmit: SubmitHandler<MenuValidationSchema> = data => {
        createMenuMutation(adaptMenuToCreate(data, restaurantId))
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <StyledPagesActionsWrapper>
                <Button
                    type="button"
                    variant="transparentDanger"
                    disabled={isNoneSelected}
                    onClick={onClickDeleteSelectedPages}
                >
                    <Trash01Icon />
                    {t('commons:delete')}
                </Button>
                <Checkbox
                    disabled={items.length === 0}
                    checked={isAllSelected}
                    onChange={onSelectAll}
                    id="selectAll"
                    label={t('commons:select_all')}
                />
                <Button type="button" variant="secondary" onClick={onClickAppendPage}>
                    <PlusIcon stroke={theme.palette.neutral[500]} />
                    {t('menu:addForm:addPageButton')}
                </Button>
            </StyledPagesActionsWrapper>
            <StyledMenuNameWrapper>
                <InputText
                    type={'text'}
                    label={t(MENU_FORM_MODEL.name.label)}
                    touched={touchedFields?.name}
                    errorMessage={t(errors.name?.message || '')}
                    placeholder={t(MENU_FORM_MODEL.name.label)}
                    {...register(MENU_FORM_MODEL.name.name)}
                    width={100}
                />
            </StyledMenuNameWrapper>
            <Box>
                {pages.length === 0 && <span>{t('menu:addForm:emptyPagesList')}</span>}
                <List>
                    {pages.map((page, pageIndex) => {
                        const checked =
                            items.find(element => element.index === pageIndex && element.selected) !== undefined
                        return (
                            <ListItem isSelected={checked} key={page.id}>
                                <StyledPageItemWrapper>
                                    <StyledPageCheckboxWrapper>
                                        <Checkbox
                                            label={`${t('commons:page')} ${pageIndex + 1}`}
                                            checked={checked}
                                            onChange={() => onSelectItem(pageIndex)}
                                            id={`${pageIndex}`}
                                        />
                                    </StyledPageCheckboxWrapper>
                                    <StyledPageNameWrapper>
                                        <InputText
                                            type={'text'}
                                            label={t(MENU_FORM_MODEL.pages.name.label)}
                                            touched={touchedFields?.pages?.[pageIndex]?.name}
                                            errorMessage={t(errors.pages?.[pageIndex]?.name?.message || '')}
                                            placeholder={t(MENU_FORM_MODEL.pages.name.label)}
                                            {...register(`pages.${pageIndex}.name`)}
                                            width={100}
                                        />
                                    </StyledPageNameWrapper>
                                    <DishesArrayField
                                        control={control}
                                        index={pageIndex}
                                        getValues={getValues}
                                        setValue={setValue}
                                        touchedFields={touchedFields}
                                        errors={errors}
                                        register={register}
                                        pageIndex={pageIndex}
                                        periods={periods}
                                        dishes={dishes}
                                    />
                                </StyledPageItemWrapper>
                            </ListItem>
                        )
                    })}
                </List>
            </Box>
            <StyledActionsWrapper gap={2} justify={'end'}>
                <Button type="button" onClick={onCancel} variant={'tertiary'} size="md" disabled={isPendingCreate}>
                    {t('commons:cancel')}
                </Button>
                <Button type="submit" variant="primary" size="md" disabled={!isValid || isPendingCreate || !isDirty}>
                    {t('commons:save')}
                </Button>
            </StyledActionsWrapper>
        </form>
    )
}

export default MenuForm
