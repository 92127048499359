import styled, {css} from 'styled-components'

export const StyledBreadcrumbs = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        align-items: center;
        color: ${palette.neutral['500']};

        gap: ${spacing * 4}px;
        & a {
            cursor: pointer;
            display: flex;
            align-items: center;
        }
        & a.active {
            background-color: ${palette.neutral[100]};
            padding: ${spacing * 2}px;
            border-radius: 5px;
        }
        & span {
            color: ${palette.neutral['300']};
            margin: 0 ${spacing * 2}px;
            display: flex;
            align-items: center;
        }
    `}
`
