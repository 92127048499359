import {StyledFiltersContainer} from './style'
import {FC, ReactNode} from 'react'

interface FiltersContainerProps {
    children: ReactNode
}
export const FiltersContainer: FC<FiltersContainerProps> = ({children}) => {
    return <StyledFiltersContainer>{children}</StyledFiltersContainer>
}

FiltersContainer.displayName = 'FiltersContainer'
