import {z} from 'zod'

export const RESTAURANT_FORM_MODEL = {
    name: {
        name: 'name',
        label: 'restaurant:details:name'
    },
    language: {
        name: 'language',
        label: 'restaurant:details:principalLanguage'
    },
    currency: {
        name: 'currency',
        label: 'restaurant:details:currency'
    },
    owner: {
        name: 'owner',
        label: 'restaurant:details:owner'
    },
    plan: {
        name: 'plan',
        label: 'restaurant:details:planType'
    },
    activationDate: {
        name: 'activationDate',
        label: 'restaurant:details:activationDate'
    },
    expiryDate: {
        name: 'expiryDate',
        label: 'restaurant:details:expiryDate'
    },
    businessName: {
        name: 'businessName',
        label: 'restaurant:details:businessName'
    },
    address: {
        name: 'address',
        label: 'restaurant:details:registeredOffice'
    },
    vatNumber: {
        name: 'vatNumber',
        label: 'restaurant:details:vatNumber'
    },
    city: {
        name: 'city',
        label: 'restaurant:details:city'
    },
    CAP: {
        name: 'CAP',
        label: 'restaurant:details:zipCode'
    },
    province: {
        name: 'province',
        label: 'restaurant:details:province'
    },
    country: {
        name: 'country',
        label: 'restaurant:details:state'
    },
    fiscalCode: {
        name: 'fiscalCode',
        label: 'restaurant:details:fiscalCode'
    },
    SDICode: {
        name: 'SDICode',
        label: 'restaurant:details:sdi'
    },
    PEC: {
        name: 'PEC',
        label: 'restaurant:details:pec'
    }
} as const

export const RestaurantFormSchema = z.object({
    name: z.string().min(1, 'errors:required'),
    language: z.object({
        value: z.string(),
        label: z.string()
    }),
    currency: z.object({
        value: z.string(),
        label: z.string()
    }),
    owner: z.object({
        value: z.string(),
        label: z.string()
    }),
    plan: z.object({
        value: z.string(),
        label: z.string()
    }),
    activationDate: z.date(),
    expiryDate: z.date(),
    businessName: z.string().optional(),
    address: z.string().optional(),
    vatNumber: z.string().optional(),
    city: z.string().optional(),
    CAP: z.string().optional(),
    province: z
        .string()
        .optional()
        .transform(text => text?.toUpperCase()),
    country: z
        .string()
        .optional()
        .transform(text => text?.toUpperCase()),
    fiscalCode: z.string().optional(),
    SDICode: z.string().optional(),
    PEC: z.string().optional()
})

export type RestaurantValidationSchema = z.infer<typeof RestaurantFormSchema>
