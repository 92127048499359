import {phoneRegex} from '@/utilities/constants/common'
import {optionalTextInput} from '@/utilities/helpers'
import {z} from 'zod'

export const ACCOUNT_FORM_MODEL = {
    givenName: {
        name: 'givenName',
        label: 'account:details:givenName'
    },
    familyName: {
        name: 'familyName',
        label: 'account:details:familyName'
    },
    email: {
        name: 'email',
        label: 'account:details:email'
    },
    mobile: {
        name: 'mobile',
        label: 'account:details:mobile'
    },
    language: {
        name: 'language',
        label: 'account:details:language'
    }
} as const

export const AccountFormSchema = z.object({
    givenName: z.string().min(1, 'errors:required'),
    familyName: z.string().min(1, 'errors:required'),
    email: z.string().email({message: 'errors:email_valid'}),
    mobile: optionalTextInput(z.string().regex(phoneRegex), 'errors:phone_number').nullable(),
    language: z.object({
        value: z.string(),
        label: z.string()
    })
})

export type AccountValidationSchema = z.infer<typeof AccountFormSchema>
