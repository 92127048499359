import {FC, ReactNode} from 'react'
import {DropzoneState} from 'react-dropzone'

interface DropzoneProps {
    children: ReactNode
    className?: string
    state: DropzoneState
}
export const Dropzone: FC<DropzoneProps> = ({children, className, state}) => {
    return (
        <div className={className} {...state.getRootProps()}>
            <input {...state.getInputProps()} />
            {children}
        </div>
    )
}

Dropzone.displayName = 'Dropzone'
