import axios from '@/axiosInstance'
import {z} from 'zod'
import {MenuListItem, MenusSearchParams} from '../types'
import {PaginationResponse} from '@/types/commons'

/**
 * httpGetMenus
 * Here we will fetch all the menus data
 */

// Response
// Params schema
export const HttpMenusParams = z.object({
    ...MenusSearchParams.shape,
    page: z.number().nullable(),
    limit: z.number().nullish()
})
export type HttpMenusParams = z.infer<typeof HttpMenusParams>

// Response
export const HttpMenusResponse = z.object({
    data: z.array(MenuListItem),
    paginateMetadata: PaginationResponse
})
export type HttpMenusResponse = z.infer<typeof HttpMenusResponse>

// Http request
export const httpGetMenus = async (params: HttpMenusParams): Promise<HttpMenusResponse> => {
    const response = await axios.get('/~/menus/admin', {params})
    return HttpMenusResponse.parse(response.data)
}

/**
 * httpDeleteCustomer
 * Here we will delete a customer instance
 */

export const httpDeleteMenu = async (id: string): Promise<void> => {
    await axios.delete(`/-/menus/${id}`)
}

/**
 * httpDuplicateMenu
 * Here we will duplicate a menu
 */

// Response
export const HttpDuplicateMenuResponse = z.object({
    idMenu: z.string(),
    idEatery: z.string()
})

export type HttpDuplicateMenuResponse = z.infer<typeof HttpDuplicateMenuResponse>

export const HttpDuplicateMenuParams = z.object({
    menuId: z.string()
})
export type HttpDuplicateMenuParams = z.infer<typeof HttpDuplicateMenuParams>

export const httpDuplicateMenu = async (params: HttpDuplicateMenuParams): Promise<HttpDuplicateMenuResponse> => {
    const response = await axios.post(`/~/menu/${params.menuId}/duplicate`)
    return response.data
}
