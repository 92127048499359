import styled, {css, DefaultTheme} from 'styled-components'

export const StyledTHead = styled.div(
    ({theme: {spacing}}: {theme: DefaultTheme}) => css`
        display: flex;
        justify-content: center;
        gap: ${spacing * 2}px;

        & > svg {
            cursor: pointer;
            flex: none;
        }
    `
)
