import {ChevronRightIcon, HomeLineIcon} from '@/components/ui/icon/Icon'
import {useGetCustomerDetails} from '@/features/customer/services/queries/useGetCustomerDetails'
import {HOME_PATHS} from '@/features/home/components/home-tabs/HomeTabs'
import {routes} from '@/utilities/constants/routes'
import {StyledBreadcrumbs} from '@components/commons/bread-crumbs/style.ts'
import {useTranslation} from 'react-i18next'
import {Link, generatePath, useParams} from 'react-router-dom'

export const CustomerDetailsBreadcrumb = () => {
    const {t} = useTranslation()
    const {id} = useParams()
    const {data} = useGetCustomerDetails({customerId: `${id}`})

    return (
        <StyledBreadcrumbs>
            <Link to={generatePath(routes.HOME.path, {tab: HOME_PATHS.restaurants})}>
                <HomeLineIcon data-color size={20} />
            </Link>
            <ChevronRightIcon data-color size={16} />
            <Link to={routes.CUSTOMERS.path}>{t('routeNames:customers')}</Link>
            <ChevronRightIcon data-color size={16} />
            <Link className="active" to={generatePath(routes.CUSTOMER_DETAILS.path, {id})}>
                {data?.customer?.displayName}
            </Link>
        </StyledBreadcrumbs>
    )
}

CustomerDetailsBreadcrumb.displayName = 'CustomerDetailsBreadcrumb'
