import {z} from 'zod'
import {LogListItem, LogsSearchParams} from '../types'
import {PaginationResponse} from '@/types/commons'
import axios from '@/axiosInstance'

/**
 * httpGetLogs
 * Here we will fetch all the logs data
 */

// Response
// Params schema
export const HttpLogsParams = z.object({
    ...LogsSearchParams.shape,
    page: z.number().nullable(),
    limit: z.number().nullish()
})
export type HttpLogsParams = z.infer<typeof HttpLogsParams>

// Response
export const HttpLogsResponse = z.object({
    data: z.array(LogListItem),
    paginateMetadata: PaginationResponse
})
export type HttpLogsResponse = z.infer<typeof HttpLogsResponse>

// Http request
export const httpGetLogs = async (params: HttpLogsParams): Promise<HttpLogsResponse> => {
    const response = await axios.get('/~/logs', {params})
    return HttpLogsResponse.parse(response.data)
}
