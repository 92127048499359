import React from 'react'
import {useTranslation} from 'react-i18next'
import {Modal} from '@/components/ui/modal/Modal'
import {StyledModal, StyledTitle} from './style'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import DishForm from './DishForm'
import {PeriodItem} from '@/features/restaurant/types'
import {HttpUploadDishesFileResponse} from '../../services/restaurantDishes.http'
import {DishValidationSchema} from './DishFormModel'
import {DishImageItem} from '../../types'

interface DishModalProps {
    onClose: () => void
    periods: PeriodItem[]
    uploadedDishes?: HttpUploadDishesFileResponse | null
    restaurantId: string
    defaultValues?: DishValidationSchema
    defaultImages?: DishImageItem[]
}

export const DishModal: React.FC<DishModalProps> = ({
    onClose,
    periods,
    uploadedDishes,
    restaurantId,
    defaultValues,
    defaultImages
}) => {
    const {t} = useTranslation()

    return (
        <Modal width={800} closeIconOffset="sm" onClose={onClose}>
            <StyledModal>
                <Flexbox align={'center'}>
                    <StyledTitle>{t(defaultValues ? 'dish:updateModal:title' : 'dish:addModal:title')}</StyledTitle>
                </Flexbox>
                <DishForm
                    restaurantId={restaurantId}
                    periods={periods}
                    onCancel={onClose}
                    uploadedDishes={uploadedDishes}
                    defaultValues={defaultValues}
                    defaultImages={defaultImages}
                />
            </StyledModal>
        </Modal>
    )
}

export default DishModalProps
