import styled, {css} from 'styled-components'
import {DefaultTheme} from 'styled-components/dist/types'

export const StyledMainWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 350px 1fr;
        gap: ${spacing * 8}px;
    `}
`

export const StyledDishesActionsWrapper = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 4}px;
        margin-bottom: ${spacing * 2}px;
        & > span:first-child {
            ${typography.textMd}
            flex-grow: 1;
            color: ${palette.neutral[700]};
            font-weight: 700;
        }
    `}
`

export const StyledMenuMainWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 1fr auto;
        gap: ${spacing * 2}px;
        width: 100%;
    `}
`

export const StyledMenuInfoWrapper = styled.div`
    ${({theme: {palette, typography}}) => css`
        display: flex;
        flex-direction: column;
        & > .dish {
            ${typography.textLg}
            color: ${palette.neutral[700]}
        }
        & > .period {
            ${typography.textMd}
            color: ${palette.neutral[700]}
        }
        & > .description {
            ${typography.textMd}
            color: ${palette.neutral[400]}
        }
    `}
`

export const StyledMenuPriceWrapper = styled.div`
    ${({theme: {palette, typography}}) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        & > .price {
            ${typography.textLg}
            color: ${palette.neutral[700]}
        }
    `}
`

export const StyledMenuListWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 4}px;
        padding: 0 ${spacing * 4}px;
    `}
`

type StyledMenuListItemProps = {
    theme: DefaultTheme
    $isSelected: boolean
}

export const StyledMenuListItem = styled.span<StyledMenuListItemProps>`
    ${({theme: {palette, spacing}, $isSelected}) => css`
        border-bottom: solid 2px ${$isSelected ? palette.primary[800] : 'transparent'};
        color: ${$isSelected ? palette.primary[800] : palette.neutral[500]};
        transition: all 300ms ease;
        padding-bottom: ${spacing * 2}px;
        cursor: pointer;
    `}
`

export const StyledPagesActionsWrapper = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
        margin-bottom: ${spacing * 2}px;
        & > span:first-child {
            ${typography.textMd}
            flex-grow: 1;
            color: ${palette.neutral[700]};
            font-weight: 700;
        }
    `}
`

export const StyledPagesListWrapper = styled.div`
    ${({theme: {spacing, palette}}) => css`
        background-color: ${palette.neutral[100]};
        border: solid 1px ${palette.neutral[200]};
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
        padding: ${spacing}px;
    `}
`

type StyledPagesListItemProps = {
    theme: DefaultTheme
    $isSelected: boolean
}

export const StyledPagesListItem = styled.span<StyledPagesListItemProps>`
    ${({theme: {palette, spacing, shadows}, $isSelected}) => css`
        background-color: ${$isSelected ? palette.neutral.white : 'transparent'};
        color: ${$isSelected ? palette.neutral[900] : palette.neutral[500]};
        font-weight: ${$isSelected ? 700 : 'normal'};
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
        padding: ${spacing * 3}px;
        box-shadow: ${$isSelected ? shadows.sm : 'none'};
        cursor: pointer;
        & > .deleteButtonWrapper {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    `}
`
