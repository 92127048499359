import {z} from 'zod'

// Menu page dish type

export const MenuPageDishItem = z.object({
    name: z.string(),
    price: z.number(),
    idDish: z.string(),
    status: z.string(),
    deleted: z.number(),
    measure: z.string(),
    special: z.string().nullable(),
    warning: z.string().nullable(),
    idPeriod: z.string(),
    lastUpdate: z.string(),
    description: z.string().nullable(),
    dateCreation: z.string()
})
export type MenuPageDishItem = z.infer<typeof MenuPageDishItem>

// Menu Page Item type

export const MenuPageItem = z.object({
    dish: MenuPageDishItem.nullable(),
    idDish: z.string(),
    deleted: z.number(),
    position: z.number(),
    idSetmenu: z.string().nullable().optional(),
    isSetmenu: z.number(),
    lastUpdate: z.string(),
    dateCreation: z.string(),
    priceOverride: z.number().nullable().optional(),
    idMenuPageItem: z.string(),
    idPeriodOverride: z.string().nullable()
})
export type MenuPageItem = z.infer<typeof MenuPageItem>

// Menu Page type

export const MenuPage = z.object({
    name: z.string(),
    deleted: z.number(),
    position: z.number(),
    idMenuPage: z.string(),
    lastUpdate: z.string(),
    dateCreation: z.string(),
    menuPageItems: z.array(MenuPageItem).nullable()
})
export type MenuPage = z.infer<typeof MenuPage>

// Menu type

export const Menu = z.object({
    name: z.string(),
    idMenu: z.string(),
    status: z.string(),
    sellable: z.number(),
    timezone: z.string(),
    menuPages: z.array(MenuPage).nullable()
})
export type Menu = z.infer<typeof Menu>

// Staff type

export const StaffItem = z.object({
    email: z.string().nullable(),
    rapidEmail: z.string().optional().nullable(),
    status: z.string(),
    idAccount: z.string().nullable(),
    urlAvatar: z.string().nullable(),
    permission: z.string(),
    displayName: z.string().nullable(),
    idEateryAccount: z.string()
})
export type StaffItem = z.infer<typeof StaffItem>

// Period type

export const PeriodItem = z.object({
    name: z.string(),
    period: z.number(),
    idPeriod: z.string(),
    dishCount: z.number()
})
export type PeriodItem = z.infer<typeof PeriodItem>

// Dish type

export const BeverageItem = z.object({
    name: z.string(),
    price: z.number(),
    idDish: z.string(),
    status: z.string(),
    measure: z.string(),
    warning: z.string().nullable(),
    description: z.string().nullable(),
    resource: z
        .object({
            URL: z.string(),
            type: z.string()
        })
        .nullable()
        .optional()
})
export type BeverageItem = z.infer<typeof BeverageItem>

// Dish type

export const DishItem = z.object({
    name: z.string(),
    price: z.number(),
    idDish: z.string(),
    status: z.string(),
    deleted: z.number(),
    measure: z.string(),
    warning: z.string().nullable(),
    lastUpdate: z.string(),
    description: z.string().nullable(),
    dateCreation: z.string(),
    idPeriod: z.string(),
    resource: z
        .object({
            URL: z.string(),
            type: z.string()
        })
        .nullable()
        .optional()
})
export type DishItem = z.infer<typeof DishItem>

// Restaurant type

export const Restaurant = z.object({
    crew: z.array(StaffItem).nullable(),
    crewInvitation: z.array(StaffItem).nullable(),
    name: z.string(),
    plan: z.number(),
    ipPos: z.string().nullable(),
    menus: z.array(Menu).nullable(),
    isOpen: z.number(),
    status: z.string(),
    address: z
        .object({
            lat: z.number().optional().nullable(),
            lng: z.number().optional().nullable(),
            address: z.string().optional().nullable()
        })
        .nullable(),
    periods: z.array(PeriodItem).nullable(),
    dishes: z.array(DishItem).nullable(),
    beverages: z.array(BeverageItem).nullable(),
    billingInfo: z
        .object({
            CAP: z.string(),
            PEC: z.string().nullable(),
            city: z.string(),
            SDICode: z.string().nullable(),
            address: z.string(),
            country: z.string(),
            applyVat: z.number(),
            province: z.string(),
            vatNumber: z.string().nullable(),
            fiscalCode: z.string().nullable(),
            businessName: z.string()
        })
        .nullable()
        .optional(),
    currency: z.string(),
    delivery: z.number(),
    idEatery: z.string(),
    idStripe: z.string().nullable(),
    language: z.string(),
    takeAway: z.number(),
    timezone: z.string(),
    username: z.string(),
    eatOnSpot: z.number(),
    locations: z.number(),
    eatingTime: z.number(),
    permission: z.string(),
    prepayment: z.number(),
    coverCharge: z.number(),
    maxCrewSize: z.number(),
    dateCreation: z.string(),
    licenseExpire: z.string(),
    posPaperFormat: z.string().nullable(),
    deliveryPayAfter: z.number(),
    paymentAvailable: z.number(),
    takeAwayPayAfter: z.number(),
    deliveryPayBefore: z.number(),
    takeAwayPayBefore: z.number(),
    stripeSetupCompleted: z.number().nullable()
})
export type Restaurant = z.infer<typeof Restaurant>

// Restaurant list item type

export const RestaurantListItem = z.object({
    name: z.string(),
    plan: z.object({
        idPlan: z.string(),
        name: z.string()
    }),
    hasMenu: z.number(),
    idEatery: z.string(),
    licenseExpire: z.string()
})
export type RestaurantListItem = z.infer<typeof RestaurantListItem>

// Restaurant search params type

export const RestaurantsSearchParams = z.object({
    orderBy: z.enum(['name', 'dateCreation', 'licenseExpire']).optional(),
    orderDirection: z.enum(['asc', 'desc']).optional(),
    search: z.string().optional(),
    licenseExpire: z.string().optional()
})
export type RestaurantsSearchParams = z.infer<typeof RestaurantsSearchParams>
