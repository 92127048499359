import styled, {css} from 'styled-components'

export const StyledBox = styled.div`
    ${({theme: {spacing, palette}}) => css`
        border-radius: 8px;
        border: solid 1px ${palette.neutral[200]};
        background-color: ${palette.neutral.white};
        padding: ${spacing * 4}px;
    `}
`

export const StyledTitle = styled.span`
    ${({theme: {spacing, palette, typography}}) => css`
        ${typography.textMd}
        color: ${palette.neutral[900]};
        font-weight: 700;
        margin-bottom: ${spacing * 4}px;
        display: block;
    `}
`
