import {Container} from '@/components/ui/container/Container'
import styled, {css, DefaultTheme} from 'styled-components'

export const StyledMainContainer = styled.div(
    () => css`
        display: flex;
        flex-direction: column;
    `
)

export const StyledHeader = styled.div(
    ({theme: {spacing, palette}}: {theme: DefaultTheme}) => css`
        padding: ${spacing * 7}px ${spacing * 8}px;
        height: 120px;
        background-color: ${palette.neutral[50]};
    `
)

export const StyledTitle = styled.div(
    ({theme: {palette, typography}}: {theme: DefaultTheme}) => css`
        ${typography.textXl}
        color: ${palette.neutral[900]};
        font-weight: 700;
    `
)

export const StyledContent = styled(Container)(
    ({theme: {spacing}}: {theme: DefaultTheme}) => css`
        height: calc(100vh - 120px);
        overflow: auto;
        padding-top: ${spacing * 16}px;
        padding-bottom: ${spacing * 8}px;
        margin-top: -25px;
    `
)

export const StyledTabsWrapper = styled.div(
    () => css`
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: -45px;
        position: relative;
        z-index: 1;
    `
)
