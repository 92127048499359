import {DishValidationSchema} from './components/dish-form/DishFormModel'
import {HttpCreateDishParams, HttpDishBodyRequestItem} from './services/restaurantDishes.http'

export const adaptDishesToCreate = (data: DishValidationSchema, idEatery: string): HttpCreateDishParams => ({
    idEatery,
    data: data.dishes.map(dish => {
        const toSend: HttpDishBodyRequestItem = {
            description: dish.description,
            name: dish.name,
            period: {
                idPeriod: dish.period.value
            },
            price: dish.price.toString(),
            status: 'PUBLISHED'
        }
        if (dish.idDish) toSend.idDish = dish.idDish
        return toSend
    })
})
