import {QUERY_KEYS} from '@/queryClient'
import {UseQueryOptions, useQuery} from '@tanstack/react-query'
import {HttpHomeDishesDataParams, HttpHomeDishesDataResponse, httpGetHomeDishesData} from '../home.http'

export const useGetHomeDishesData = (
    params: HttpHomeDishesDataParams,
    options?: Omit<UseQueryOptions<HttpHomeDishesDataResponse>, 'queryFn' | 'queryKey'>
) => {
    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.HOME_DISHES_DATA, params.startDate, params.endDate],
        queryFn: () => httpGetHomeDishesData(params),
        enabled:
            (params.startDate !== undefined && params.endDate !== undefined) ||
            (params.startDate === undefined && params.endDate === undefined)
    })
}
