import styled, {css} from 'styled-components'
import * as AvatarPrimitive from '@radix-ui/react-avatar'

type AvatarRootProps = {
    $size: 'sm' | 'md' | 'lg'
}

export const makeAvatarSizeStyle = {
    sm: css`
        width: 24px;
        height: 24px;
        font-size: 12px;
    `,
    md: css`
        width: 40px;
        height: 40px;
        font-size: 20px;
    `,
    lg: css`
        width: 56px;
        height: 56px;
        font-size: 28px;
    `
}

export const AvatarRoot = styled(AvatarPrimitive.Root)<AvatarRootProps>`
    ${({theme: {palette}, $size}) => css`
        ${makeAvatarSizeStyle[$size]};
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        user-select: none;
        border-radius: 100%;
        overflow: hidden;
        background: ${palette.neutral['100']};
        border: 1px ${palette.neutral['300']};
        color: ${palette.primary[900]};
    `}
`
