import {FC, HTMLAttributes} from 'react'
import {StyledTableDataCell} from './style'

interface TableDataCellProps extends HTMLAttributes<HTMLDivElement> {
    className?: string
    isBold?: boolean
    size?: 'sm' | 'xs'
    isWarn?: boolean
    isDanger?: boolean
}

export const TableDataCell: FC<TableDataCellProps> = ({
    className,
    children,
    isBold = false,
    isWarn = false,
    isDanger = false,
    size = 'sm',
    ...rest
}) => {
    return (
        <StyledTableDataCell
            className={className}
            isBold={isBold}
            isWarn={isWarn}
            isDanger={isDanger}
            size={size}
            {...rest}
        >
            {children}
        </StyledTableDataCell>
    )
}
