import {SubmitHandler, useFieldArray, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {PERIOD_FORM_MODEL, PeriodFormSchema, PeriodValidationSchema} from './PeriodFormModel'
import {useTranslation} from 'react-i18next'
import {
    StyledActionsWrapper,
    StyledPeriodActionsWrapper,
    StyledPeriodItemWrapper,
    StyledPeriodsActionsWrapper,
    StyledPeriodsWrapper
} from './style'
import {Button} from '@/components/ui/button/Button'
import React from 'react'
import {PlusIcon, Trash01Icon} from '@/components/ui/icon/Icon'
import {useTheme} from 'styled-components'
import {useCreatePeriods} from '../../services/queries/useCreatePeriods'
import {toast} from '@/utilities/toast/toast'
import {errorHandler} from '@/utilities/genericErrorHandler'
import {adaptPeriodsToCreate} from '../../utils'

interface PeriodFormProps {
    onCancel?: () => void
    restaurantId: string
    lastPeriod: number
}

const PeriodForm: React.FC<PeriodFormProps> = ({onCancel, restaurantId, lastPeriod}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    const {
        control,
        register,
        handleSubmit,
        formState: {errors, touchedFields, isValid, isDirty}
    } = useForm<PeriodValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(PeriodFormSchema),
        defaultValues: {
            periods: [
                {
                    name: ''
                }
            ]
        }
    })

    const {
        fields: rows,
        append: appendRow,
        remove: removeRow
    } = useFieldArray({
        control,
        name: 'periods'
    })

    const onClickAppendPeriod = () => {
        appendRow({
            name: ''
        })
    }

    const onClickDeletePeriod = (index: number) => {
        removeRow(index)
    }

    // mutations
    const {mutate: createPeriodMutation, isPending: isPendingCreate} = useCreatePeriods({
        restaurantId,
        onSuccess: () => {
            toast.success(t('period:new_period_success'))
            onCancel?.()
        },
        onError: error => errorHandler(error)
    })

    // submit handler
    const onSubmit: SubmitHandler<PeriodValidationSchema> = data => {
        createPeriodMutation(adaptPeriodsToCreate(data, restaurantId, lastPeriod))
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <StyledPeriodsWrapper>
                {rows.map((field, index) => (
                    <StyledPeriodItemWrapper key={field.id}>
                        <InputText
                            type={'text'}
                            label={t(PERIOD_FORM_MODEL.periods.name.label)}
                            touched={touchedFields?.periods?.[index]?.name}
                            errorMessage={t(errors.periods?.[index]?.name?.message || '')}
                            placeholder={t(PERIOD_FORM_MODEL.periods.name.label)}
                            {...register(`periods.${index}.name`)}
                        />
                        {rows.length > 1 && (
                            <StyledPeriodActionsWrapper justify={'flex-end'}>
                                <Button type="button" variant="secondary" onClick={() => onClickDeletePeriod(index)}>
                                    <Trash01Icon fill={theme.palette.danger[500]} />
                                </Button>
                            </StyledPeriodActionsWrapper>
                        )}
                    </StyledPeriodItemWrapper>
                ))}
            </StyledPeriodsWrapper>
            <StyledPeriodsActionsWrapper>
                <Button type="button" variant="secondary" onClick={onClickAppendPeriod}>
                    <PlusIcon stroke={theme.palette.neutral[500]} />
                </Button>
            </StyledPeriodsActionsWrapper>

            <StyledActionsWrapper gap={2} justify={'end'}>
                <Button type="button" onClick={onCancel} variant={'tertiary'} size="md" disabled={isPendingCreate}>
                    {t('commons:cancel')}
                </Button>
                <Button type="submit" variant="primary" size="md" disabled={!isValid || !isDirty || isPendingCreate}>
                    {t('commons:save')}
                </Button>
            </StyledActionsWrapper>
        </form>
    )
}

export default PeriodForm
