import {LANGUAGES} from '@/utilities/constants/common'
import {
    HttpCreateAccountParams,
    HttpGetAccountDetailsResponse,
    HttpUpdateAccountParams
} from '../../services/account.http'
import {AccountValidationSchema} from './AccountFormModel'
import i18n from '@/translations/i18n'

export const adaptAccountToForm = (data: HttpGetAccountDetailsResponse): AccountValidationSchema => {
    const language = LANGUAGES.find(item => item.value === data.language)
    return {
        givenName: data.givenName,
        familyName: data.familyName,
        email: data.email ?? '',
        mobile: data.mobile ?? '',
        language: {value: language?.value ?? '', label: i18n.t(language?.label ?? '')}
    }
}

export const adaptAccountToUpdate = (idAccount: string, data: AccountValidationSchema): HttpUpdateAccountParams => ({
    idAccount,
    data: {
        givenName: data.givenName,
        familyName: data.familyName,
        email: data.email ?? '',
        mobile: data.mobile || null,
        language: data.language.value ?? '',
        displayName: `${data.givenName} ${data.familyName}`,
        urlAvatar: ''
    }
})

export const adaptAccountToCreate = (data: AccountValidationSchema): HttpCreateAccountParams => ({
    data: {
        givenName: data.givenName,
        familyName: data.familyName,
        email: data.email ?? '',
        mobile: data.mobile || null,
        language: data.language.value ?? '',
        displayName: `${data.givenName} ${data.familyName}`,
        urlAvatar: ''
    }
})
