import {
    StyledMobileTBody,
    StyledTCell,
    StyledTCellColumn,
    StyledTCellValue,
    StyledTRow,
    StyledTRowWrapper
} from '@/components/commons/table/mobile-t-body/style'
import {EmptyPlaceholder} from '@components/commons/table/empty-placeholder/EmptyPlaceholder'
import {ErrorMessage} from '@/components/commons/table/error-message/ErrorMessage'
import {TableColumn, TableExpandedRows} from '@components/commons/table/Table.tsx'
import {ReactNode} from 'react'
import {MobileSkeleton} from '@/components/commons/table/mobile-skeleton/MobileSkeleton'

interface MobileTBodyProps<TData extends unknown[], TSortName extends string | undefined> {
    columns: TableColumn<TData, TSortName>[]
    data: TData
    isError?: boolean
    expandedRows?: TableExpandedRows<TData>
    isChangingPage?: boolean
    isLoading: boolean
    onChangePage?: (lastItemIndex: number) => void
    subRow?: (item: TData[number], itemIndex: number) => ReactNode
}

export const MobileTBody = <TData extends unknown[], TSortName extends string | undefined>({
    columns,
    data,
    isError,
    expandedRows,
    isChangingPage,
    isLoading,
    onChangePage,
    subRow
}: MobileTBodyProps<TData, TSortName>) => {
    return (
        <StyledMobileTBody
            data={data}
            endReached={isError ? undefined : onChangePage}
            overscan={100}
            itemContent={(itemIndex, item) => {
                if (item)
                    return (
                        <StyledTRowWrapper key={itemIndex}>
                            <StyledTRow>
                                {columns.map((column, index) => (
                                    <StyledTCell key={index}>
                                        {column.label && <StyledTCellColumn>{column.label}</StyledTCellColumn>}
                                        <StyledTCellValue>{column.cellRender(item, itemIndex)}</StyledTCellValue>
                                    </StyledTCell>
                                ))}
                            </StyledTRow>
                            {subRow?.(item, itemIndex)}
                            {expandedRows?.indexes?.includes(itemIndex) && expandedRows.render(item, itemIndex)}
                        </StyledTRowWrapper>
                    )
            }}
            components={{
                EmptyPlaceholder: () => {
                    if (isLoading) {
                        return <MobileSkeleton columns={columns} />
                    }
                    if (isError) {
                        return null
                    }

                    return <EmptyPlaceholder />
                },
                Footer: () => {
                    if (isLoading) {
                        return null
                    }
                    if (isLoading) {
                        return <ErrorMessage />
                    }
                    if (isChangingPage) {
                        return <MobileSkeleton columns={columns} />
                    }

                    return null
                }
            }}
        />
    )
}

MobileTBody.displayName = 'MobileTBody'
