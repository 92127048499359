import styled, {css} from 'styled-components'

export const RedirectWrapper = styled.div`
    ${() => css`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    `}
`

export const RedirectBox = styled.div`
    ${({theme: {typography, palette, spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: stretch;
        text-align: center;
        width: 360px;

        & h3 {
            ${typography.displaySm};
            font-weight: 600;
            color: ${palette.neutral['900']};
            margin-top: ${spacing * 6}px;
        }

        & p {
            ${typography.textMd};
            margin: ${spacing * 2}px 0 ${spacing * 14}px 0;
        }
    `}
`

export const RedirectCtaWrapper = styled.div`
    ${({theme: {typography, spacing, palette}}) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        gap: ${spacing}px;
        & small {
            ${typography.textSm};
        }
        & a {
            ${typography.textSm};
            cursor: pointer;
            text-decoration: none;
            color: ${palette.primary['700']};
            font-weight: 600;
            &:hover {
                color: ${palette.neutral['900']};
                text-decoration: none;
            }
            &:focus {
                color: ${palette.neutral['900']};
            }
            &:visited {
                color: ${palette.neutral['500']};
            }
            &:active {
                color: ${palette.primary['700']};
            }
            &:link {
                color: ${palette.neutral['600']};
            }
        }
    `}
`
