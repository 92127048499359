import {createBrowserRouter, createRoutesFromElements, Route} from 'react-router-dom'
import {PrivateRoute} from '@route-guards/private-route/PrivateRoute.tsx'
import {PublicRoute} from '@route-guards/public-route/PublicRoute.tsx'
import {PublicLayout} from '@layouts/public-layout/PublicLayout.tsx'
import {PrivateLayout} from '@layouts/private-layout/PrivateLayout.tsx'
import {routes} from '@utilities/constants/routes.tsx'

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/">
            <Route element={<PrivateRoute />}>
                <Route element={<PrivateLayout />}>
                    <Route {...routes.HOME} />
                    <Route {...routes.RESTAURANTS} />
                    <Route {...routes.RESTAURANT_DETAILS} />
                    <Route {...routes.CUSTOMERS} />
                    <Route {...routes.CUSTOMER_DETAILS} />
                    <Route {...routes.MENUS} />
                    <Route {...routes.ACCOUNTS} />
                    <Route {...routes.ACCOUNT_DETAILS} />
                    <Route {...routes.LOGS} />
                </Route>
            </Route>
            <Route element={<PublicRoute />}>
                <Route element={<PublicLayout />}>
                    <Route {...routes.LOGIN} />
                    <Route {...routes.SSO_REDIRECT} />
                </Route>
            </Route>
        </Route>
    )
)
