import React from 'react'
import {Container} from '@components/ui/container/Container.tsx'
import {Outlet} from 'react-router-dom'

export const User: React.FC = () => (
    <Container>
        <h1>Single User page</h1>
        <Outlet />
    </Container>
)

User.displayName = 'User'
