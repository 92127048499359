import {Modal} from '@components/ui/modal/Modal.tsx'
import {
    StyledDeleteUserModal,
    StyledContent,
    StyledFooter,
    StyledTrashIcon
} from '@components/commons/delete-modal/style.ts'
import {FC, ReactNode} from 'react'
import {Button} from '@components/ui/button/Button.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import {DefaultNamespace} from 'i18next'

interface DeleteModalProps {
    isLoading: boolean
    onClose: () => void
    onDelete: () => void
    title: string | DefaultNamespace
    paragraph: ReactNode
}

export const DeleteModal: FC<DeleteModalProps> = ({isLoading, onClose, onDelete, title, paragraph}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    return (
        <Modal width={'544px'} closeIconOffset="sm" onClose={onClose} onOverlayClick={onClose}>
            <StyledDeleteUserModal>
                <StyledContent>
                    <StyledTrashIcon size={24} fill={theme.palette.danger['600']} />
                    <div>
                        <h3>{title}</h3>
                        <p>{paragraph}</p>
                    </div>
                </StyledContent>
                <StyledFooter>
                    <Button variant="secondary" onClick={onClose} disabled={isLoading}>
                        {t('commons:cancel')}
                    </Button>
                    <Button variant="primaryDanger" onClick={onDelete} disabled={isLoading}>
                        {t('commons:delete')}
                        {isLoading && <Spinner size={16} />}
                    </Button>
                </StyledFooter>
            </StyledDeleteUserModal>
        </Modal>
    )
}

DeleteModal.displayName = 'DeleteModal'
