import {Box} from '@/components/commons/box/Box'
import HomeSection from '../home-section/HomeSection'
import {useTranslation} from 'react-i18next'
import {HomeBox} from '../home-box/HomeBox'
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell} from 'recharts'
import {
    StyledBarChartWrapper,
    StyledCardsWrapper,
    StyledColumns,
    StyledLegend,
    StyledPieChartLegend,
    StyledPieChartLegendPoint,
    StyledPieChartWrapper
} from './style'
import {ArrowDownIcon, ArrowUpIcon} from '@/components/ui/icon/Icon'
import {formaThousandsNumber} from '@/utilities/helpers'
import {useMemo, useState} from 'react'
import {useGetHomeRestaurantsData} from '../../services/queries/useGetHomeRestaurantsData'
import {LANGUAGES, TimeRange} from '@/utilities/constants/common'
import {HomeRecordSet, getHomeDataByTimeRange} from '../../utils'
import {useTheme} from 'styled-components'
import dayjs from 'dayjs'

export const HomeRestaurants = () => {
    const {t} = useTranslation()
    const [selectedDates, setSelectedDates] = useState<Date[] | null>(null)
    const {data} = useGetHomeRestaurantsData({
        startDate: selectedDates?.[0] ? dayjs(selectedDates[0]).format('YYYY-MM-DD hh:mm:ss') : undefined,
        endDate: selectedDates?.[1] ? dayjs(selectedDates[1]).format('YYYY-MM-DD hh:mm:ss') : undefined
    })
    const [selectedTimeRange, setSelectedTimeRange] = useState<TimeRange>(TimeRange.All)
    const theme = useTheme()

    const LOCATION_COLORS = [
        theme.palette.success[600],
        theme.palette.success[500],
        theme.palette.success[400],
        theme.palette.success[300],
        theme.palette.success[200],
        theme.palette.success[100]
    ]

    const isPositiveEateriesGrowthSinceLastMonth = useMemo(
        () => parseFloat(data?.data.eateriesData.eateriesGrowthSinceLastMonth ?? '0') >= 0,
        [data?.data]
    )

    return (
        <Box>
            <HomeSection
                selectedDates={selectedDates}
                setSelectedDates={setSelectedDates}
                title={t('home:tabs:restaurants')}
                selectedTimeRange={selectedTimeRange}
                setSelectedTimeRange={setSelectedTimeRange}
            >
                <HomeBox title={t('home:restaurants:trendOverTime')}>
                    <StyledBarChartWrapper>
                        <div className="labelWrapper">
                            <span>{t('home:restaurants:restaurantsCreated')}</span>
                        </div>
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                width={100}
                                height={100}
                                data={getHomeDataByTimeRange(
                                    (data?.data.eateries as HomeRecordSet) ?? [],
                                    selectedTimeRange,
                                    selectedDates
                                )}
                            >
                                <CartesianGrid vertical={false} strokeDasharray="3 3" />
                                <XAxis axisLine={false} tickLine={false} dataKey="name" />
                                <YAxis axisLine={false} tickLine={false} />
                                <Tooltip cursor={{fill: 'transparent'}} />
                                <Bar
                                    barSize={30}
                                    radius={[5, 5, 0, 0]}
                                    dataKey="count"
                                    fill={theme.palette.primary[600]}
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    </StyledBarChartWrapper>
                </HomeBox>
                <StyledCardsWrapper>
                    <HomeBox title={t('home:restaurants:totalRestaurants')}>
                        <StyledLegend $isPositive={isPositiveEateriesGrowthSinceLastMonth}>
                            <span className="title">{formaThousandsNumber(data?.data.eateriesData.total ?? 0)}</span>
                            <div className="description">
                                <div>
                                    {isPositiveEateriesGrowthSinceLastMonth ? (
                                        <ArrowUpIcon size={12} />
                                    ) : (
                                        <ArrowDownIcon size={12} />
                                    )}
                                    {data?.data.eateriesData.eateriesGrowthSinceLastMonth}
                                </div>
                                <div>{t('home:restaurants:vsLastMonth')}</div>
                            </div>
                        </StyledLegend>
                    </HomeBox>
                    <HomeBox title={t('home:restaurants:location')}>
                        <StyledColumns>
                            <StyledPieChartWrapper>
                                <ResponsiveContainer width="100%" height="100%">
                                    <PieChart width={400} height={400}>
                                        <Tooltip cursor={{fill: 'transparent'}} />
                                        <Pie
                                            stroke=""
                                            data={
                                                data?.data.eateriesData.languageCounts.map(item => ({
                                                    ...item,
                                                    name: item.language
                                                })) ?? []
                                            }
                                            innerRadius={20}
                                            outerRadius={40}
                                            fill={theme.palette.neutral[100]}
                                            dataKey="count"
                                        >
                                            {data?.data.eateriesData.languageCounts.map((_, index) => (
                                                <Cell
                                                    key={`cell-${index}`}
                                                    fill={LOCATION_COLORS[index % LOCATION_COLORS.length]}
                                                />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                            </StyledPieChartWrapper>
                            <StyledPieChartLegend>
                                {data?.data.eateriesData.languageCounts.map((item, index) => (
                                    <div className="legendItem" key={item.language}>
                                        <StyledPieChartLegendPoint
                                            $color={LOCATION_COLORS[index % LOCATION_COLORS.length]}
                                        />
                                        <span>
                                            {t(LANGUAGES.find(element => element.value === item.language)?.label ?? '')}
                                        </span>
                                    </div>
                                ))}
                            </StyledPieChartLegend>
                        </StyledColumns>
                    </HomeBox>
                </StyledCardsWrapper>
            </HomeSection>
        </Box>
    )
}

export default HomeRestaurants
