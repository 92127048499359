import styled, {css} from 'styled-components'
import {CSSProperties} from 'react'

interface StyledFlexboxProps {
    $justify?: CSSProperties['justifyContent']
    $align?: CSSProperties['alignItems']
    $direction?: CSSProperties['flexDirection']
    $gap?: number
}

export const StyledFlexbox = styled.div<StyledFlexboxProps>(
    ({theme: {spacing}, $justify, $align, $direction, $gap = 0}) => css`
        display: flex;
        flex-direction: ${$direction ?? 'row'};
        justify-content: ${$justify ?? 'flex-start'};
        align-items: ${$align ?? 'flex-start'};
        gap: ${spacing * $gap}px;
    `
)
