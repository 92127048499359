import {FC} from 'react'
import {StyledEmptyPlaceholder} from '@/components/commons/table/empty-placeholder/style'
import {useTranslation} from 'react-i18next'

export const EmptyPlaceholder: FC = () => {
    const {t} = useTranslation()

    return <StyledEmptyPlaceholder>{t('commons:no_results')}</StyledEmptyPlaceholder>
}

EmptyPlaceholder.displayName = 'DesktopTableEmptyPlaceholder'
