import {QueryClient, UseInfiniteQueryResult} from '@tanstack/react-query'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            networkMode: 'online',
            retry: 1,
            retryOnMount: true,
            retryDelay: 1000,
            staleTime: 600000, //10 min
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: true
        }
    }
})

export const QUERY_KEYS = {
    CUSTOMERS: 'customers',
    CUSTOMER_DETAILS: 'customer-details',
    RESTAURANTS: 'restaurants',
    RESTAURANT_DETAILS: 'restaurant-details',
    RESTAURANT_PERIODS: 'restaurant-periods',
    RESTAURANT_DISHES: 'restaurant-dishes',
    RESTAURANT_MENUS: 'restaurant-menus',
    RESTAURANT_STAFF: 'restaurant-staff',
    MENUS: 'menus',
    ACCOUNTS: 'accounts',
    ACCOUNT_DETAILS: 'account-details',
    LOGS: 'logs',
    HOME_RESTAURANTS_DATA: 'home-restaurants-data',
    HOME_PEOPLE_DATA: 'home-people-data',
    HOME_CUSTOMERS_DATA: 'home-customers-data',
    HOME_MENUS_DATA: 'home-menus-data',
    HOME_DISHES_DATA: 'home-dishes-data',
    PLANS: 'plans'
} as const

export const MUTATION_KEYS = {
    CREATE_CUSTOMER: 'create-customer',
    DELETE_CUSTOMER: 'delete-customer',
    UPDATE_CUSTOMER: 'update-customer',
    CREATE_RESTAURANT: 'create-restaurant',
    UPDATE_RESTAURANT: 'update-restaurant',
    DELETE_RESTAURANT: 'delete-restaurant',
    CREATE_MENU: 'create-menu',
    UPDATE_MENU: 'update-menu',
    DELETE_MENU: 'delete-menu',
    CREATE_ACCOUNT: 'create-account',
    UPDATE_ACCOUNT: 'update-account',
    DELETE_ACCOUNT: 'delete-account',
    VERIFY_ACCOUNT: 'verify-account',
    UPLOAD_DISHES: 'upload-dishes',
    DELETE_PERIODS: 'delete-periods',
    DELETE_STAFF: 'delete-staff',
    CREATE_DISHES: 'create-dishes',
    CREATE_PERIODS: 'create-periods',
    CREATE_BEVERAGES: 'create-beverages',
    UPLOAD_BEVERAGES: 'upload-beverages',
    DELETE_DISHES: 'delete-dishes',
    CREATE_MENU_PAGE: 'create-menu-page',
    DELETE_MENU_PAGES: 'delete-menu-pages',
    DELETE_MENU_PAGES_ITEMS: 'delete_menu_pages_items',
    CREATE_MENU_PAGE_ITEMS: 'create-menu-page-items',
    DELETE_BEVERAGES: 'delete-beverages',
    DUPLICATE_MENU: 'duplicate-menu',
    CREATE_STAFF: 'create-staff'
} as const

//rq-helpers
export const infiniteQueryFetchNextPage = async (infiniteQuery: UseInfiniteQueryResult) => {
    if (infiniteQuery.hasNextPage && !infiniteQuery.isFetching) {
        await infiniteQuery.fetchNextPage()
    }
}
