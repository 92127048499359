import styled, {css} from 'styled-components'
import {DefaultTheme} from 'styled-components/dist/types'

type StyledItemProps = {
    theme: DefaultTheme
    $isSelected: boolean
}

export const StyledItem = styled.div<StyledItemProps>`
    ${({theme: {spacing, palette, shadows}, $isSelected}) => css`
        border-radius: 8px;
        border: solid 1px ${$isSelected ? palette.neutral[300] : palette.neutral[200]};
        background-color: ${$isSelected ? palette.neutral[100] : palette.neutral.white};
        padding: ${spacing * 4}px;
        transition: all 300ms ease;
        box-shadow: ${$isSelected ? shadows.md : shadows.sm};
        display: flex;
        align-items: center;
        gap: ${spacing * 4}px;
    `}
`
