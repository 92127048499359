import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpCreateStaff, HttpCreateStaffResponse} from '../restaurantStaff.http'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons'
import {errorHandler} from '@/utilities/genericErrorHandler'

type UseCreateStaffProps = {
    restaurantId: string
    onSuccess?: (data: HttpCreateStaffResponse) => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useCreateStaff = (options: UseCreateStaffProps) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.CREATE_STAFF],
        mutationFn: httpCreateStaff,
        onSuccess: async data => {
            await client.invalidateQueries({queryKey: [QUERY_KEYS.RESTAURANTS]})
            await client.invalidateQueries({queryKey: [QUERY_KEYS.RESTAURANT_DETAILS, options.restaurantId]})
            options?.onSuccess?.(data)
        },
        onError: (error: AxiosError<ErrorResponseData>) => errorHandler(error)
    })
}
