import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {RESTAURANT_FORM_MODEL, RestaurantFormSchema, RestaurantValidationSchema} from './RestaurantFormModel'
import {useTranslation} from 'react-i18next'
import {
    StyledActionsWrapper,
    StyledCurrencySelect,
    StyledAccountSelect,
    StyledLanguageSelect,
    StyledPlanSelect,
    StyledWrapper
} from './style'
import {Button} from '@/components/ui/button/Button'
import React, {useCallback, useEffect} from 'react'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {FormSection} from '@/components/commons/form-section/FormSection'
import {DatePicker} from '@/components/commons/date-picker/DatePicker'
import dayjs from 'dayjs'
import {SelectValue} from '@components/commons/select/Select.tsx'
import {CURRENCIES, LANGUAGES} from '@/utilities/constants/common'
import {usePlansQuery} from '@/features/plan/services/queries/usePlans'
import {debounce} from '@/utilities/helpers'
import {CallbackFunction} from '@/types/commons'
import {httpGetAccounts} from '@/features/account/services/account.http'
import {errorHandler} from '@/utilities/genericErrorHandler'
import {accountsSelectAdapter} from '@/utilities/adapters'
import {useCreateRestaurant} from '../../services/queries/useCreateRestaurant'
import toast from 'react-hot-toast'
import {useUpdateRestaurant} from '../../services/queries/useUpdateRestaurant'
import {adaptRestaurantToCreate, adaptRestaurantToUpdate} from '../../utils'
import {generatePath, useNavigate} from 'react-router-dom'
import {routes} from '@/utilities/constants/routes'
import {RESTAURANT_DETAILS_PATHS} from '../restaurant-details-tabs/RestaurantDetailsTabs'

interface RestaurantFormProps {
    defaultValues?: RestaurantValidationSchema
    restaurantId?: string
    onCancel?: () => void
    readOnly?: boolean
}

const RestaurantForm: React.FC<RestaurantFormProps> = ({defaultValues, restaurantId, onCancel, readOnly = false}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: {errors, touchedFields, isValid, isDirty},
        setValue
    } = useForm<RestaurantValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(RestaurantFormSchema),
        defaultValues: {...defaultValues}
    })

    useEffect(() => {
        reset(defaultValues)
    }, [defaultValues])

    const {data: plans} = usePlansQuery()

    // mutations
    const {mutate: createRestaurantMutation, isPending: isPendingCreate} = useCreateRestaurant({
        onSuccess: data => {
            navigate(
                generatePath(routes.RESTAURANT_DETAILS.path, {
                    id: data.eatery.idEatery ?? '',
                    tab: RESTAURANT_DETAILS_PATHS.masterData
                })
            )
            toast.success(t('restaurant:new_restaurant_success'))
            onCancel?.()
        },
        onError: error => errorHandler(error)
    })

    const {mutate: updateRestaurantMutation, isPending: isPendingUpdate} = useUpdateRestaurant({
        restaurantId: restaurantId ?? '',
        onSuccess: () => {
            toast.success(t(`commons:update_completed`, {entity: t(`restaurant:singular`)}))
            onCancel?.()
        },
        onError: () => {
            reset({...defaultValues})
        }
    })

    // submit handler
    const onSubmit: SubmitHandler<RestaurantValidationSchema> = data => {
        if (restaurantId) {
            updateRestaurantMutation(adaptRestaurantToUpdate(restaurantId, data))
            reset({}, {keepValues: true})
        } else {
            createRestaurantMutation(adaptRestaurantToCreate(data))
        }
    }

    const loadAccounts = async (inputValue: string | undefined, callback: CallbackFunction) => {
        try {
            const response = await httpGetAccounts({
                search: inputValue,
                page: 1,
                limit: 500
            })
            callback(accountsSelectAdapter(response))
        } catch (error) {
            errorHandler(error)
            callback([])
        }
    }

    const loadAccountOptions = useCallback(
        debounce((inputValue: string | undefined, callback: CallbackFunction) => {
            loadAccounts(inputValue, callback)
        }, 400),
        []
    )

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <StyledWrapper>
                <FormSection title={t('restaurant:sections:generalInfo')}>
                    <Flexbox gap={4}>
                        <InputText
                            label={`${t(RESTAURANT_FORM_MODEL.name.label)}`}
                            type={'text'}
                            touched={touchedFields.name}
                            errorMessage={t(errors.name?.message || '')}
                            placeholder={t(RESTAURANT_FORM_MODEL.name.label)}
                            {...register(t(RESTAURANT_FORM_MODEL.name.name))}
                            disabled={readOnly}
                        />
                        <Controller
                            render={({field: {onChange, value}}) => (
                                <StyledLanguageSelect
                                    value={value}
                                    onChange={newValue => {
                                        onChange(newValue as SelectValue)
                                    }}
                                    size={'medium'}
                                    name={RESTAURANT_FORM_MODEL.language.name}
                                    label={`${t(RESTAURANT_FORM_MODEL.language.label)}`}
                                    isClearable={false}
                                    isSearchable={true}
                                    errorMessage={t(errors.language?.message || '')}
                                    placeholder={t(RESTAURANT_FORM_MODEL.language.label)}
                                    options={LANGUAGES.map(item => ({
                                        value: item.value,
                                        label: t(item.label)
                                    }))}
                                    disabled={readOnly}
                                />
                            )}
                            control={control}
                            name={RESTAURANT_FORM_MODEL.language.name}
                        />
                        <Controller
                            render={({field: {onChange, value}}) => (
                                <StyledCurrencySelect
                                    value={value}
                                    onChange={newValue => {
                                        onChange(newValue as SelectValue)
                                    }}
                                    size={'medium'}
                                    name={RESTAURANT_FORM_MODEL.currency.name}
                                    label={`${t(RESTAURANT_FORM_MODEL.currency.label)}`}
                                    isClearable={false}
                                    isSearchable={true}
                                    errorMessage={t(errors.currency?.message || '')}
                                    placeholder={t(RESTAURANT_FORM_MODEL.currency.label)}
                                    options={CURRENCIES.map(item => ({
                                        value: item.value,
                                        label: t(item.label)
                                    }))}
                                    disabled={readOnly}
                                />
                            )}
                            control={control}
                            name={RESTAURANT_FORM_MODEL.currency.name}
                        />
                    </Flexbox>
                </FormSection>
                <FormSection title={t('restaurant:sections:plan')}>
                    <Flexbox gap={4}>
                        <Controller
                            render={({field: {onChange, value}}) => (
                                <StyledAccountSelect
                                    isAsync
                                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                    // @ts-expect-error
                                    loadOptions={loadAccountOptions}
                                    value={value as SelectValue}
                                    onChange={newValue => {
                                        onChange(newValue as SelectValue)
                                    }}
                                    size={'medium'}
                                    name={RESTAURANT_FORM_MODEL.owner.name}
                                    label={`${t(RESTAURANT_FORM_MODEL.owner.label)}`}
                                    isClearable={true}
                                    isCreatable={false}
                                    isSearchable={true}
                                    errorMessage={t(errors.owner?.message || '')}
                                    placeholder={t(RESTAURANT_FORM_MODEL.owner.label)}
                                    disabled={readOnly}
                                    noOptionsMessage={({inputValue}) =>
                                        inputValue ? t('commons:no_item_found') : t('commons:type_to_search')
                                    }
                                />
                            )}
                            control={control}
                            name={RESTAURANT_FORM_MODEL.owner.name}
                        />
                        <Controller
                            render={({field: {onChange, value}}) => (
                                <StyledPlanSelect
                                    value={value}
                                    onChange={newValue => {
                                        onChange(newValue as SelectValue)
                                    }}
                                    size={'medium'}
                                    name={RESTAURANT_FORM_MODEL.plan.name}
                                    label={`${t(RESTAURANT_FORM_MODEL.plan.label)}`}
                                    isClearable={false}
                                    isSearchable={true}
                                    errorMessage={t(errors.plan?.message || '')}
                                    placeholder={t(RESTAURANT_FORM_MODEL.plan.label)}
                                    options={
                                        plans?.map(item => ({
                                            value: item.code.toString(),
                                            label: item.name
                                        })) ?? []
                                    }
                                    disabled={readOnly}
                                />
                            )}
                            control={control}
                            name={RESTAURANT_FORM_MODEL.plan.name}
                        />
                        <Controller
                            control={control}
                            name={RESTAURANT_FORM_MODEL.activationDate.name}
                            render={({field: {onChange, value, onBlur}, fieldState: {error}}) => (
                                <DatePicker
                                    toggle
                                    formatDateFn={date => dayjs(date).format('DD/MM/YYYY')}
                                    numMonths={1}
                                    mode={'single'}
                                    selectedDates={value ? [value] : []}
                                    onDatesChange={dates => onChange(dates[0])}
                                    onBlur={onBlur}
                                    triggerProps={{
                                        label: t(RESTAURANT_FORM_MODEL.activationDate.label),
                                        errorMessage: t(error?.message || ''),
                                        placeholder: t(RESTAURANT_FORM_MODEL.activationDate.label).toString()
                                    }}
                                    disabled={readOnly}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name={RESTAURANT_FORM_MODEL.expiryDate.name}
                            render={({field: {onChange, value, onBlur}, fieldState: {error}}) => (
                                <DatePicker
                                    toggle
                                    formatDateFn={date => dayjs(date).format('DD/MM/YYYY')}
                                    numMonths={1}
                                    mode={'single'}
                                    selectedDates={value ? [value] : []}
                                    onDatesChange={dates => onChange(dates[0])}
                                    onBlur={onBlur}
                                    triggerProps={{
                                        label: t(RESTAURANT_FORM_MODEL.expiryDate.label),
                                        errorMessage: t(error?.message || ''),
                                        placeholder: t(RESTAURANT_FORM_MODEL.expiryDate.label).toString()
                                    }}
                                    disabled={readOnly}
                                />
                            )}
                        />
                    </Flexbox>
                </FormSection>
                <FormSection title={t('restaurant:sections:billingData')}>
                    <StyledWrapper>
                        <Flexbox gap={4}>
                            <InputText
                                label={`${t(RESTAURANT_FORM_MODEL.businessName.label)}`}
                                type={'text'}
                                touched={touchedFields.businessName}
                                errorMessage={t(errors.businessName?.message || '')}
                                placeholder={t(RESTAURANT_FORM_MODEL.businessName.label)}
                                {...register(t(RESTAURANT_FORM_MODEL.businessName.name))}
                                disabled={readOnly}
                            />
                            <InputText
                                label={`${t(RESTAURANT_FORM_MODEL.address.label)}`}
                                type={'text'}
                                touched={touchedFields.address}
                                errorMessage={t(errors.address?.message || '')}
                                placeholder={t(RESTAURANT_FORM_MODEL.address.label)}
                                {...register(t(RESTAURANT_FORM_MODEL.address.name))}
                                disabled={readOnly}
                                maxLength={30}
                            />
                            <InputText
                                label={`${t(RESTAURANT_FORM_MODEL.vatNumber.label)}`}
                                type={'text'}
                                touched={touchedFields.vatNumber}
                                errorMessage={t(errors.vatNumber?.message || '')}
                                placeholder={t(RESTAURANT_FORM_MODEL.vatNumber.label)}
                                {...register(t(RESTAURANT_FORM_MODEL.vatNumber.name))}
                                disabled={readOnly}
                            />
                        </Flexbox>
                        <Flexbox gap={4}>
                            <InputText
                                label={`${t(RESTAURANT_FORM_MODEL.city.label)}`}
                                type={'text'}
                                touched={touchedFields.city}
                                errorMessage={t(errors.city?.message || '')}
                                placeholder={t(RESTAURANT_FORM_MODEL.city.label)}
                                {...register(t(RESTAURANT_FORM_MODEL.city.name))}
                                disabled={readOnly}
                            />
                            <InputText
                                label={`${t(RESTAURANT_FORM_MODEL.CAP.label)}`}
                                type={'text'}
                                touched={touchedFields.CAP}
                                errorMessage={t(errors.CAP?.message || '')}
                                placeholder={t(RESTAURANT_FORM_MODEL.CAP.label)}
                                {...register(t(RESTAURANT_FORM_MODEL.CAP.name))}
                                disabled={readOnly}
                            />
                            <InputText
                                label={`${t(RESTAURANT_FORM_MODEL.province.label)}`}
                                type={'text'}
                                touched={touchedFields.province}
                                errorMessage={t(errors.province?.message || '')}
                                placeholder={t(RESTAURANT_FORM_MODEL.province.label)}
                                {...register(t(RESTAURANT_FORM_MODEL.province.name))}
                                disabled={readOnly}
                                maxLength={2}
                                onChange={event => {
                                    const value = event.target.value
                                    setValue(RESTAURANT_FORM_MODEL.province.name, value.toUpperCase(), {
                                        shouldValidate: true
                                    })
                                    register(RESTAURANT_FORM_MODEL.province.name).onChange(event)
                                }}
                            />
                            <InputText
                                label={`${t(RESTAURANT_FORM_MODEL.country.label)}`}
                                type={'text'}
                                touched={touchedFields.country}
                                errorMessage={t(errors.country?.message || '')}
                                placeholder={t(RESTAURANT_FORM_MODEL.country.label)}
                                {...register(t(RESTAURANT_FORM_MODEL.country.name))}
                                disabled={readOnly}
                                maxLength={3}
                                onChange={event => {
                                    const value = event.target.value
                                    setValue(RESTAURANT_FORM_MODEL.country.name, value.toUpperCase(), {
                                        shouldValidate: true
                                    })
                                    register(RESTAURANT_FORM_MODEL.country.name).onChange(event)
                                }}
                            />
                        </Flexbox>
                        <Flexbox gap={4}>
                            <InputText
                                label={`${t(RESTAURANT_FORM_MODEL.fiscalCode.label)}`}
                                type={'text'}
                                touched={touchedFields.fiscalCode}
                                errorMessage={t(errors.fiscalCode?.message || '')}
                                placeholder={t(RESTAURANT_FORM_MODEL.fiscalCode.label)}
                                {...register(t(RESTAURANT_FORM_MODEL.fiscalCode.name))}
                                disabled={readOnly}
                            />
                            <InputText
                                label={`${t(RESTAURANT_FORM_MODEL.SDICode.label)}`}
                                type={'text'}
                                touched={touchedFields.SDICode}
                                errorMessage={t(errors.SDICode?.message || '')}
                                placeholder={t(RESTAURANT_FORM_MODEL.SDICode.label)}
                                {...register(t(RESTAURANT_FORM_MODEL.SDICode.name))}
                                disabled={readOnly}
                            />
                            <InputText
                                label={`${t(RESTAURANT_FORM_MODEL.PEC.label)}`}
                                type={'text'}
                                touched={touchedFields.PEC}
                                errorMessage={t(errors.PEC?.message || '')}
                                placeholder={t(RESTAURANT_FORM_MODEL.PEC.label)}
                                {...register(t(RESTAURANT_FORM_MODEL.PEC.name))}
                                disabled={readOnly}
                            />
                        </Flexbox>
                    </StyledWrapper>
                </FormSection>
            </StyledWrapper>

            {!readOnly && (
                <StyledActionsWrapper gap={2} justify={'end'}>
                    <Button
                        type="button"
                        onClick={onCancel}
                        variant={'tertiary'}
                        size="md"
                        disabled={isPendingUpdate || isPendingCreate}
                    >
                        {t('commons:cancel')}
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        size="md"
                        disabled={!isValid || isPendingUpdate || isPendingCreate || !isDirty}
                    >
                        {t('commons:save')}
                    </Button>
                </StyledActionsWrapper>
            )}
        </form>
    )
}

export default RestaurantForm
