import {Box} from '@/components/commons/box/Box'
import HomeSection from '../home-section/HomeSection'
import {useTranslation} from 'react-i18next'
import {HomeBox} from '../home-box/HomeBox'
import {StyledBarChartWrapper, StyledCardsWrapper, StyledLegend, StyledMainWrapper} from './style'
import {ArrowDownIcon, ArrowUpIcon} from '@/components/ui/icon/Icon'
import {formaThousandsNumber} from '@/utilities/helpers'
import {useMemo, useState} from 'react'
import {TimeRange} from '@/utilities/constants/common'
import {HomeRecordSet, getHomeDataByTimeRange} from '../../utils'
import {useTheme} from 'styled-components'
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts'
import {useGetHomeMenusData} from '../../services/queries/useGetHomeMenusData'
import {useGetHomeDishesData} from '../../services/queries/useGetHomeDishesData'
import dayjs from 'dayjs'

export const HomeMenus = () => {
    const {t} = useTranslation()
    const [selectedMenusDates, setSelectedMenusDates] = useState<Date[] | null>(null)
    const [selectedDishesDates, setSelectedDishesDates] = useState<Date[] | null>(null)
    const [selectedMenusTimeRange, setSelectedMenusTimeRange] = useState<TimeRange>(TimeRange.All)
    const [selectedDishesTimeRange, setSelectedDishesTimeRange] = useState<TimeRange>(TimeRange.All)
    const theme = useTheme()
    const {data: menusData} = useGetHomeMenusData({
        startDate: selectedMenusDates?.[0] ? dayjs(selectedMenusDates[0]).format('YYYY-MM-DD hh:mm:ss') : undefined,
        endDate: selectedMenusDates?.[1] ? dayjs(selectedMenusDates[1]).format('YYYY-MM-DD hh:mm:ss') : undefined
    })
    const {data: dishesData} = useGetHomeDishesData({
        startDate: selectedDishesDates?.[0] ? dayjs(selectedDishesDates[0]).format('YYYY-MM-DD hh:mm:ss') : undefined,
        endDate: selectedDishesDates?.[1] ? dayjs(selectedDishesDates[1]).format('YYYY-MM-DD hh:mm:ss') : undefined
    })

    const isPositiveMenusGrowthSinceLastMonth = useMemo(
        () => parseFloat(menusData?.data.menuData.menusGrowthSinceLastMonth ?? '0') >= 0,
        [menusData?.data]
    )

    const isPositiveDishesGrowthSinceLastMonth = useMemo(
        () => parseFloat(dishesData?.data.dishData.dishesGrowthSinceLastMonth ?? '0') >= 0,
        [dishesData?.data]
    )

    return (
        <StyledMainWrapper>
            <Box>
                <HomeSection
                    selectedDates={selectedMenusDates}
                    setSelectedDates={setSelectedMenusDates}
                    title={t('home:food:menus')}
                    selectedTimeRange={selectedMenusTimeRange}
                    setSelectedTimeRange={setSelectedMenusTimeRange}
                >
                    <HomeBox title={t('home:food:trendOverTime')}>
                        <StyledBarChartWrapper>
                            <div className="labelWrapper">
                                <span>{t('home:food:menusCreated')}</span>
                            </div>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    width={100}
                                    height={100}
                                    data={getHomeDataByTimeRange(
                                        (menusData?.data.menus as HomeRecordSet) ?? [],
                                        selectedMenusTimeRange,
                                        selectedMenusDates
                                    )}
                                >
                                    <CartesianGrid vertical={false} strokeDasharray="3 3" />
                                    <XAxis axisLine={false} tickLine={false} dataKey="name" />
                                    <YAxis axisLine={false} tickLine={false} />
                                    <Tooltip cursor={{fill: 'transparent'}} />
                                    <Bar
                                        barSize={30}
                                        radius={[5, 5, 0, 0]}
                                        dataKey="count"
                                        fill={theme.palette.primary[600]}
                                    />
                                </BarChart>
                            </ResponsiveContainer>
                        </StyledBarChartWrapper>
                    </HomeBox>
                    <StyledCardsWrapper>
                        <HomeBox title={t('home:food:totalMenus')}>
                            <StyledLegend $isPositive={isPositiveMenusGrowthSinceLastMonth}>
                                <span className="title">
                                    {formaThousandsNumber(menusData?.data.menuData.total ?? 0)}
                                </span>
                                <div className="description">
                                    <div>
                                        {isPositiveMenusGrowthSinceLastMonth ? (
                                            <ArrowUpIcon size={12} />
                                        ) : (
                                            <ArrowDownIcon size={12} />
                                        )}
                                        {menusData?.data.menuData.menusGrowthSinceLastMonth}
                                    </div>
                                    <div>{t('home:food:vsLastMonth')}</div>
                                </div>
                            </StyledLegend>
                        </HomeBox>
                    </StyledCardsWrapper>
                </HomeSection>
            </Box>
            <Box>
                <HomeSection
                    selectedDates={selectedDishesDates}
                    setSelectedDates={setSelectedDishesDates}
                    title={t('home:food:dishes')}
                    selectedTimeRange={selectedDishesTimeRange}
                    setSelectedTimeRange={setSelectedDishesTimeRange}
                >
                    <HomeBox title={t('home:food:trendOverTime')}>
                        <StyledBarChartWrapper>
                            <div className="labelWrapper">
                                <span>{t('home:food:dishesCreated')}</span>
                            </div>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    width={100}
                                    height={100}
                                    data={getHomeDataByTimeRange(
                                        (dishesData?.data.dishes as HomeRecordSet) ?? [],
                                        selectedDishesTimeRange,
                                        selectedDishesDates
                                    )}
                                >
                                    <CartesianGrid vertical={false} strokeDasharray="3 3" />
                                    <XAxis axisLine={false} tickLine={false} dataKey="name" />
                                    <YAxis axisLine={false} tickLine={false} />
                                    <Tooltip cursor={{fill: 'transparent'}} />
                                    <Bar
                                        barSize={30}
                                        radius={[5, 5, 0, 0]}
                                        dataKey="count"
                                        fill={theme.palette.primary[600]}
                                    />
                                </BarChart>
                            </ResponsiveContainer>{' '}
                        </StyledBarChartWrapper>
                    </HomeBox>
                    <StyledCardsWrapper>
                        <HomeBox title={t('home:food:totalDishes')}>
                            <StyledLegend $isPositive={isPositiveDishesGrowthSinceLastMonth}>
                                <span className="title">
                                    {formaThousandsNumber(dishesData?.data.dishData.total ?? 0)}
                                </span>
                                <div className="description">
                                    <div>
                                        {isPositiveDishesGrowthSinceLastMonth ? (
                                            <ArrowUpIcon size={12} />
                                        ) : (
                                            <ArrowDownIcon size={12} />
                                        )}
                                        {dishesData?.data.dishData.dishesGrowthSinceLastMonth}
                                    </div>
                                    <div>{t('home:food:vsLastMonth')}</div>
                                </div>
                            </StyledLegend>
                        </HomeBox>
                    </StyledCardsWrapper>
                </HomeSection>
            </Box>
        </StyledMainWrapper>
    )
}

export default HomeMenus
