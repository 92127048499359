import {StyledEmptySearch} from '@components/commons/empty-search/style.ts'
import {FC} from 'react'
import empty from '@/assets/images/empty.svg'
import {useTranslation} from 'react-i18next'

export const EmptyList: FC = () => {
    const {t} = useTranslation()
    return (
        <StyledEmptySearch>
            <img src={empty} alt="No data" />
            <span>{t('commons:no_data_to_show')}</span>
        </StyledEmptySearch>
    )
}

EmptyList.displayName = 'EmptyList'
