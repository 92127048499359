import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpVerifyAccount, HttpGetAccountDetailsResponse} from '../account.http'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons'
import {errorHandler} from '@/utilities/genericErrorHandler'

type UseVerifyAccountProps = {
    onSuccess?: (data: HttpGetAccountDetailsResponse) => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useVerifyAccount = (options?: UseVerifyAccountProps) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.VERIFY_ACCOUNT],
        mutationFn: httpVerifyAccount,
        onSuccess: async data => {
            await client.invalidateQueries({queryKey: [QUERY_KEYS.ACCOUNTS]})
            await client.invalidateQueries({queryKey: [QUERY_KEYS.ACCOUNT_DETAILS, data.idAccount]})
            options?.onSuccess?.(data)
        },
        onError: (error: AxiosError<ErrorResponseData>) => errorHandler(error)
    })
}
