import {z} from 'zod'

// Home restaurants data

export const HomeRestaurantsData = z.object({
    eateries: z.record(
        z.object({
            dateCreation: z.string()
        })
    ),
    eateriesData: z.object({
        total: z.number(),
        lastMonthTotal: z.number(),
        languageCounts: z.array(
            z.object({
                count: z.number(),
                language: z.string()
            })
        ),
        eateriesGrowthSinceLastMonth: z.string()
    })
})
export type HomeRestaurantsData = z.infer<typeof HomeRestaurantsData>

export const HomeRestaurantsDataSearchParams = z.object({
    startDate: z.string().optional(),
    endDate: z.string().optional()
})
export type HomeRestaurantsDataSearchParams = z.infer<typeof HomeRestaurantsDataSearchParams>

// Home people data

export const HomePeopleData = z.object({
    accounts: z.record(
        z.object({
            dateCreation: z.string()
        })
    ),
    accountData: z.object({
        total: z.number(),
        lastMonthTotal: z.number(),
        accountsGrowthSinceLastMonth: z.string(),
        planCounts: z.array(
            z.object({
                count: z.number(),
                plan: z.string()
            })
        )
    })
})
export type HomePeopleData = z.infer<typeof HomePeopleData>

export const HomePeopleDataSearchParams = z.object({
    startDate: z.string().optional(),
    endDate: z.string().optional()
})
export type HomePeopleDataSearchParams = z.infer<typeof HomePeopleDataSearchParams>

// Home customers data

export const HomeCustomersData = z.object({
    customers: z.record(
        z.object({
            dateCreation: z.string()
        })
    ),
    customerData: z.object({
        total: z.number(),
        lastMonthTotal: z.number(),
        customersGrowthSinceLastMonth: z.string()
    })
})
export type HomeCustomersData = z.infer<typeof HomeCustomersData>

export const HomeCustomersDataSearchParams = z.object({
    startDate: z.string().optional(),
    endDate: z.string().optional()
})
export type HomeCustomersDataSearchParams = z.infer<typeof HomeCustomersDataSearchParams>

// Home menus data

export const HomeMenusData = z.object({
    menus: z.record(
        z.object({
            dateCreation: z.string()
        })
    ),
    menuData: z.object({
        total: z.number(),
        lastMonthTotal: z.number(),
        menusGrowthSinceLastMonth: z.string()
    })
})
export type HomeMenusData = z.infer<typeof HomeMenusData>

export const HomeMenusDataSearchParams = z.object({
    startDate: z.string().optional(),
    endDate: z.string().optional()
})
export type HomeMenusDataSearchParams = z.infer<typeof HomeMenusDataSearchParams>

// Home dishes data

export const HomeDishesData = z.object({
    dishes: z.record(
        z.object({
            dateCreation: z.string()
        })
    ),
    dishData: z.object({
        total: z.number(),
        lastMonthTotal: z.number(),
        dishesGrowthSinceLastMonth: z.string()
    })
})
export type HomeDishesData = z.infer<typeof HomeDishesData>

export const HomeDishesDataSearchParams = z.object({
    startDate: z.string().optional(),
    endDate: z.string().optional()
})
export type HomeDishesDataSearchParams = z.infer<typeof HomeDishesDataSearchParams>
