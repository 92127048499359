import React from 'react'
import {useTranslation} from 'react-i18next'
import {Modal} from '@/components/ui/modal/Modal'
import {StyledModal, StyledTitle} from './style'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import PeriodForm from './PeriodForm'

interface PeriodModalProps {
    onClose: () => void
    restaurantId: string
    lastPeriod: number
}

export const PeriodModal: React.FC<PeriodModalProps> = ({onClose, restaurantId, lastPeriod}) => {
    const {t} = useTranslation()

    return (
        <Modal width={500} closeIconOffset="sm" onClose={onClose}>
            <StyledModal>
                <Flexbox align={'center'}>
                    <StyledTitle>{t('period:addModal:title')}</StyledTitle>
                </Flexbox>
                <PeriodForm onCancel={onClose} restaurantId={restaurantId} lastPeriod={lastPeriod} />
            </StyledModal>
        </Modal>
    )
}

export default PeriodModalProps
