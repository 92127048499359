import React from 'react'
import {StyledContainer} from '@components/ui/container/style.ts'

interface ContainerProps {
    className?: string
    children: React.ReactNode
    fullWidth?: boolean
    fullHeight?: boolean
}

export const Container = ({children, className, fullWidth = false, fullHeight = true}: ContainerProps) => (
    <StyledContainer $fullWidth={fullWidth} $fullHeight={fullHeight} className={className}>
        {children}
    </StyledContainer>
)

Container.displayName = 'Container'
