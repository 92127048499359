import {z} from 'zod'

export const DISH_FORM_MODEL = {
    dishes: {
        idDish: {
            name: 'idDish',
            label: ''
        },
        name: {
            name: 'name',
            label: 'dish:addForm:fields:name'
        },
        description: {
            name: 'description',
            label: 'dish:addForm:fields:description'
        },
        period: {
            name: 'period',
            label: 'dish:addForm:fields:period'
        },
        price: {
            name: 'price',
            label: 'dish:addForm:fields:price'
        },
        image: {
            name: 'image',
            label: ''
        }
    }
} as const

export const DishItemSchema = z.object({
    idDish: z.string().optional(),
    name: z.string().min(1, {message: 'errors:required'}),
    description: z.string().optional(),
    period: z.object({
        value: z.string().min(1, {message: 'errors:required'}),
        label: z.string()
    }),
    price: z.coerce.number(),
    image: z.instanceof(File).optional().nullable()
})
export type DishItem = z.infer<typeof DishItemSchema>

export const DishFormSchema = z.object({
    dishes: z.array(DishItemSchema)
})
export type DishValidationSchema = z.infer<typeof DishFormSchema>
