import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {STAFF_FORM_MODEL, StaffFormSchema, StaffValidationSchema} from './StaffFormModel'
import {useTranslation} from 'react-i18next'
import {StyledActionsWrapper, StyledStaffItemContent} from './style'
import {Button} from '@/components/ui/button/Button'
import React from 'react'
import {Select, SelectValue} from '@/components/commons/select/Select'
import {MEMBER_ROLES} from '@/features/restaurant/utils'
import {useCreateStaff} from '../../services/queries/useCreateStuff'
import {toast} from '@/utilities/toast/toast'
import {errorHandler} from '@/utilities/genericErrorHandler'
import {adaptStaffToCreate} from '../../utils'

interface StaffFormProps {
    onCancel?: () => void
    restaurantId: string
    restaurantName: string
}

const StaffForm: React.FC<StaffFormProps> = ({onCancel, restaurantId, restaurantName}) => {
    const {t} = useTranslation()

    const {
        control,
        register,
        handleSubmit,
        formState: {errors, touchedFields, isValid, isDirty}
    } = useForm<StaffValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(StaffFormSchema)
    })

    // mutations
    const {mutate: createStaffMutation, isPending: isPendingCreate} = useCreateStaff({
        restaurantId,
        onSuccess: () => {
            toast.success(t('staff:new_staff_success'))
            onCancel?.()
        },
        onError: error => errorHandler(error)
    })

    // submit handler
    const onSubmit: SubmitHandler<StaffValidationSchema> = data => {
        createStaffMutation(adaptStaffToCreate(data, restaurantId, restaurantName))
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <StyledStaffItemContent>
                <InputText
                    type={'text'}
                    label={t(STAFF_FORM_MODEL.members.name.label)}
                    touched={touchedFields?.name}
                    errorMessage={t(errors?.name?.message || '')}
                    placeholder={t(STAFF_FORM_MODEL.members.name.label)}
                    {...register(STAFF_FORM_MODEL.members.name.name)}
                />
                <InputText
                    type={'text'}
                    label={t(STAFF_FORM_MODEL.members.email.label)}
                    touched={touchedFields?.email}
                    errorMessage={t(errors?.email?.message || '')}
                    placeholder={t(STAFF_FORM_MODEL.members.email.label)}
                    {...register(STAFF_FORM_MODEL.members.email.name)}
                />
                <Controller
                    render={({field: {onChange, value}}) => (
                        <Select
                            value={value}
                            onChange={newValue => {
                                onChange(newValue as SelectValue)
                            }}
                            size={'medium'}
                            name={STAFF_FORM_MODEL.members.role.name}
                            label={`${t(STAFF_FORM_MODEL.members.role.label)}`}
                            isClearable={false}
                            isSearchable={true}
                            errorMessage={t(errors?.role?.message || '')}
                            placeholder={t(STAFF_FORM_MODEL.members.role.label)}
                            options={MEMBER_ROLES.map(item => ({
                                value: item.value,
                                label: t(item.label)
                            }))}
                        />
                    )}
                    control={control}
                    name={STAFF_FORM_MODEL.members.role.name}
                />
            </StyledStaffItemContent>

            <StyledActionsWrapper gap={2} justify={'end'}>
                <Button type="button" onClick={onCancel} variant={'tertiary'} size="md" disabled={isPendingCreate}>
                    {t('commons:cancel')}
                </Button>
                <Button type="submit" variant="primary" size="md" disabled={!isValid || !isDirty || isPendingCreate}>
                    {t('commons:save')}
                </Button>
            </StyledActionsWrapper>
        </form>
    )
}

export default StaffForm
