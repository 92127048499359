import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledStaffItemContent = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: ${spacing * 2}px;
        margin-top: ${spacing * 4}px;
    `}
`

export const StyledActionsWrapper = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        margin-top: ${spacing * 8}px;
    `}
`

export const StyledModal = styled.div(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 6}px;
    `
)

export const StyledTitle = styled.span`
    ${({theme: {typography, palette}}) => css`
        ${typography.textLg}
        font-weight: bold;
        color: ${palette.neutral['900']};
        display: block;
    `}
`
