export const opacities = {
    '10': '19',
    '20': '33',
    '30': '4C',
    '40': '66',
    '50': '80',
    '60': '99',
    '70': 'B3',
    '80': 'CC',
    '90': 'E5'
} as const
