import {z} from 'zod'
import axios from '@/axiosInstance'

/**
 * httpUploadBeveragesFile
 * Here we will upload a CSV file to create multiple beverages
 */

// Response
export const HttpUploadBeveragesFileResponse = z.array(
    z.object({
        name: z.string(),
        description: z.string(),
        price: z.number(),
        special: z.string().nullable(),
        warning: z.string().nullable()
    })
)
export type HttpUploadBeveragesFileResponse = z.infer<typeof HttpUploadBeveragesFileResponse>

export const httpUploadBeveragesFile = async (file: File): Promise<HttpUploadBeveragesFileResponse> => {
    const formData = new FormData()
    formData.append('file', file)
    const response = await axios.post('/~/dish/parse-csv', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    return response.data
}

/**
 * httpCreateBeverages
 * Here we will create multiple beverage instances
 */

// Response
export const HttpGetBeverageDetailsResponse = z.array(
    z.object({
        name: z.string(),
        description: z.string(),
        price: z.string(),
        status: z.string(),
        period: z.object({
            idPeriod: z.string()
        }),
        idDish: z.string()
    })
)
export type HttpGetBeverageDetailsResponse = z.infer<typeof HttpGetBeverageDetailsResponse>

export const HttpBeverageBodyRequestItem = z.object({
    idDish: z.string().optional(),
    name: z.string(),
    description: z.string().optional(),
    price: z.string(),
    status: z.string(),
    period: z.object({
        idPeriod: z.string()
    })
})
export type HttpBeverageBodyRequestItem = z.infer<typeof HttpBeverageBodyRequestItem>

export const HttpBeverageBodyRequest = z.array(HttpBeverageBodyRequestItem)
export type HttpBeverageBodyRequest = z.infer<typeof HttpBeverageBodyRequest>

export const HttpCreateBeverageParams = z.object({
    idEatery: z.string(),
    data: HttpBeverageBodyRequest
})
export type HttpCreateBeverageParams = z.infer<typeof HttpCreateBeverageParams>

export const httpCreateBeverages = async (
    params: HttpCreateBeverageParams
): Promise<HttpGetBeverageDetailsResponse> => {
    const response = await axios.post(`/~/eatery/${params.idEatery}/dish`, params.data)
    return response.data
}

/**
 * httpDeleteBeverages
 * Here we will delete multiple beverages
 */

export const httpDeleteBeverages = async (idRestaurant: string, idDish: string[]): Promise<void> => {
    await axios.delete(`/~/eatery/${idRestaurant}/dish`, {params: {idDish}})
}

/**
 * httpUploadBeverageImage
 * Here we will upload a new image resource for a beverage
 */

// Response
export const HttpUploadImageResponse = z.void()
export type HttpUploadImageResponse = z.infer<typeof HttpUploadImageResponse>

export const httpUploadBeverageImage = async (file: File, name: string): Promise<HttpUploadImageResponse> => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('name', name)
    const response = await axios.post('/~/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    return response.data
}
