import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpDuplicateMenu, HttpDuplicateMenuResponse} from '../menu.http'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons'
import {errorHandler} from '@/utilities/genericErrorHandler'

type UseDuplicateMenuProps = {
    onSuccess?: (data: HttpDuplicateMenuResponse) => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useDuplicateMenu = (options: UseDuplicateMenuProps) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.DUPLICATE_MENU],
        mutationFn: httpDuplicateMenu,
        onSuccess: async data => {
            await client.invalidateQueries({queryKey: [QUERY_KEYS.MENUS]})
            await client.invalidateQueries({queryKey: [QUERY_KEYS.RESTAURANT_DETAILS, data.idEatery]})
            options?.onSuccess?.(data)
        },
        onError: (error: AxiosError<ErrorResponseData>) => errorHandler(error)
    })
}
