import {QUERY_KEYS} from '@/queryClient'
import {UseQueryOptions, useQuery} from '@tanstack/react-query'
import {HttpGetAccountDetailsParams, HttpGetAccountDetailsResponse, httpGetAccountDetails} from '../account.http'

export const useGetAccountDetails = (
    params: HttpGetAccountDetailsParams,
    options?: Omit<UseQueryOptions<HttpGetAccountDetailsResponse>, 'queryFn' | 'queryKey'>
) => {
    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.ACCOUNT_DETAILS, params.accountId],
        queryFn: () => httpGetAccountDetails(params)
    })
}
