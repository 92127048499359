import {ChevronRightIcon, HomeLineIcon} from '@/components/ui/icon/Icon'
import {HOME_PATHS} from '@/features/home/components/home-tabs/HomeTabs'
import {useGetRestaurantDetails} from '@/features/restaurant/services/queries/useGetRestaurantDetails'
import {routes} from '@/utilities/constants/routes'
import {StyledBreadcrumbs} from '@components/commons/bread-crumbs/style.ts'
import {useTranslation} from 'react-i18next'
import {Link, generatePath, useParams} from 'react-router-dom'

export const RestaurantDetailsBreadcrumb = () => {
    const {t} = useTranslation()
    const {id} = useParams()
    const {data} = useGetRestaurantDetails({restaurantId: `${id}`})

    return (
        <StyledBreadcrumbs>
            <Link to={generatePath(routes.HOME.path, {tab: HOME_PATHS.restaurants})}>
                <HomeLineIcon data-color size={20} />
            </Link>
            <ChevronRightIcon data-color size={16} />
            <Link to={routes.RESTAURANTS.path}>{t('routeNames:restaurants')}</Link>
            <ChevronRightIcon data-color size={16} />
            <Link className="active" to={generatePath(routes.RESTAURANT_DETAILS.path, {id})}>
                {data?.name}
            </Link>
        </StyledBreadcrumbs>
    )
}

RestaurantDetailsBreadcrumb.displayName = 'RestaurantDetailsBreadcrumb'
