import {FC, ReactNode} from 'react'
import {StyledItem} from './style'

interface ListItemProps {
    children: ReactNode
    isSelected?: boolean
}
export const ListItem: FC<ListItemProps> = ({children, isSelected = false}) => {
    return <StyledItem $isSelected={isSelected}>{children}</StyledItem>
}

ListItem.displayName = 'ListItem'
