import styled, {css, DefaultTheme} from 'styled-components'

export const StyledErrorMessage = styled.div(
    ({theme: {typography, palette, spacing}}: {theme: DefaultTheme}) => css`
        padding: ${spacing * 5}px;
        ${typography.textXl};
        font-weight: 500;
        text-align: center;
        color: ${palette.danger['500']};
    `
)
