import {z} from 'zod'

export const STAFF_FORM_MODEL = {
    members: {
        name: {
            name: 'name',
            label: 'staff:addForm:fields:name'
        },
        email: {
            name: 'email',
            label: 'staff:addForm:fields:email'
        },
        role: {
            name: 'role',
            label: 'staff:addForm:fields:role'
        }
    }
} as const

export const StaffFormSchema = z.object({
    name: z.string().min(1, {message: 'errors:required'}),
    email: z.string().email().min(1, {message: 'errors:required'}),
    role: z.object({
        value: z.string().min(1, {message: 'errors:required'}),
        label: z.string()
    })
})
export type StaffValidationSchema = z.infer<typeof StaffFormSchema>
