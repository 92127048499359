import {StyledContainer, Title, TabsWrapper, StyledNavLink} from '@pages/users/style.ts'
import {useTranslation} from 'react-i18next'
import {Outlet} from 'react-router-dom'

export const Users = () => {
    const {t} = useTranslation()

    const tabs = [
        {label: t('users:tabs:program_manager'), path: 'program-managers'},
        {label: t('users:tabs:client_engineer'), path: 'client-engineer'},
        {label: t('users:tabs:vendor_admin'), path: 'vendor-admin'},
        {label: t('users:tabs:vendor_manager'), path: 'vendor-manager'},
        {label: t('users:tabs:team_lead'), path: 'team-lead'},
        {label: t('users:tabs:operator'), path: 'operators'}
    ]

    return (
        <StyledContainer fullHeight>
            <Title>{t('users:headline')}</Title>
            <TabsWrapper>
                {tabs.map(tab => (
                    <StyledNavLink to={tab.path} key={tab.path}>
                        {tab.label}
                    </StyledNavLink>
                ))}
            </TabsWrapper>
            <Outlet />
        </StyledContainer>
    )
}

Users.displayName = 'Users'
