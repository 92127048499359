import {Controller, SubmitHandler, useFieldArray, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {MENU_FORM_MODEL, MenuPageItemSchema, MenuPageItemValidationSchema, PageDish} from './MenuFormModel'
import {useTranslation} from 'react-i18next'
import {StyledActionsWrapper, StyledDishItemContent, StyledDishesActionsWrapper} from './style'
import {Button} from '@/components/ui/button/Button'
import React from 'react'
import {InputText} from '@/components/commons/input-text/InputText'
import {toast} from '@/utilities/toast/toast'
import {errorHandler} from '@/utilities/genericErrorHandler'
import {adaptMenuPageItemToCreate} from '../../utils'
import {useSelectableList} from '@/hooks/useSelectableList'
import {useTheme} from 'styled-components'
import {useCreateRestaurantMenuPageItems} from '../../services/queries/useCreateRestaurantMenuPageItems'
import {PlusIcon, Trash01Icon} from '@/components/ui/icon/Icon'
import {Checkbox} from '@/components/commons/checkbox/CheckBox'
import {List} from '@/components/commons/list/list/List'
import {ListItem} from '@/components/commons/list/list-item/ListItem'
import {Select, SelectValue} from '@/components/commons/select/Select'
import {DishItem, PeriodItem} from '@/features/restaurant/types'

interface MenuPageItemFormProps {
    onCancel?: () => void
    restaurantId: string
    menuId: string
    menuPageId: string
    lastPagePosition: number
    dishes: DishItem[]
    periods: PeriodItem[]
}

const MenuPageItemForm: React.FC<MenuPageItemFormProps> = ({
    onCancel,
    restaurantId,
    menuId,
    menuPageId,
    lastPagePosition,
    dishes,
    periods
}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    const {items, setItems, onSelectItem, onSelectAll, isAllSelected, isNoneSelected} = useSelectableList([
        {
            index: 0,
            selected: false
        }
    ])

    const onClickDeleteSelected = () => {
        const formDishes = getValues('dishes')
        let counter = 0
        const updatedDishes: PageDish[] = []
        items.forEach((item, index) => {
            if (!item.selected) {
                updatedDishes.push(formDishes[index])
                counter++
            }
        })
        setValue('dishes', updatedDishes)
        const updatedSelected = Array.from({length: counter}, (_, index) => ({index, selected: false}))
        setItems(updatedSelected)
    }

    const onClickAppend = () => {
        appendDish({
            dish: {value: '', label: t('commons:select_a_dish')},
            period: {value: '', label: t('commons:select_a_period')},
            price: 0
        })
        setItems(prev => [...prev, {index: prev.length, selected: false}])
    }
    const {
        control,
        register,
        handleSubmit,
        formState: {errors, touchedFields, isValid, isDirty},
        setValue,
        getValues
    } = useForm<MenuPageItemValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(MenuPageItemSchema),
        defaultValues: {
            dishes: [
                {
                    dish: {value: '', label: t('commons:select_a_dish')},
                    period: {value: '', label: t('commons:select_a_period')},
                    price: 0
                }
            ]
        }
    })

    const {fields: rows, append: appendDish} = useFieldArray({
        control,
        name: 'dishes'
    })

    // mutations
    const {mutate: createPageItemMutation, isPending: isPendingCreate} = useCreateRestaurantMenuPageItems({
        idEatery: restaurantId,
        onSuccess: () => {
            toast.success(t('menu:new_menu_page_success'))
            onCancel?.()
        },
        onError: error => errorHandler(error)
    })

    // submit handler
    const onSubmit: SubmitHandler<MenuPageItemValidationSchema> = data => {
        createPageItemMutation(adaptMenuPageItemToCreate(data, restaurantId, menuId, menuPageId, lastPagePosition))
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <StyledDishesActionsWrapper>
                <span>{t('menu:addForm:dishesLabel')}</span>
                <Button
                    type="button"
                    variant="transparentDanger"
                    disabled={isNoneSelected}
                    onClick={onClickDeleteSelected}
                >
                    <Trash01Icon />
                    {t('commons:delete')}
                </Button>
                <Checkbox
                    disabled={items.length === 0}
                    checked={isAllSelected}
                    onChange={onSelectAll}
                    id="selectAll"
                    label={t('commons:select_all')}
                />
                <Button type="button" variant="secondary" onClick={onClickAppend}>
                    <PlusIcon stroke={theme.palette.neutral[500]} />
                    {t('menu:addForm:addDishButton')}
                </Button>
            </StyledDishesActionsWrapper>
            {rows.length === 0 && <span>{t('menu:addForm:emptyDishesList')}</span>}
            <List>
                {rows.map((field, index) => {
                    const checked = items.find(element => element.index === index && element.selected) !== undefined
                    return (
                        <ListItem isSelected={checked} key={field.id}>
                            <Checkbox checked={checked} onChange={() => onSelectItem(index)} id={`${index}`} />
                            <StyledDishItemContent>
                                <Controller
                                    render={({field: {onChange, value}}) => (
                                        <Select
                                            value={value}
                                            onChange={newValue => {
                                                const safeNewValue = newValue as SelectValue
                                                onChange(safeNewValue)
                                                const selectedDish = dishes.find(
                                                    dish => dish.idDish === safeNewValue.value
                                                )
                                                const selectedPeriod = periods.find(
                                                    period => period.idPeriod === selectedDish?.idPeriod
                                                )
                                                setValue(
                                                    `dishes.${index}.period`,
                                                    {
                                                        value: selectedPeriod?.idPeriod ?? '',
                                                        label: selectedPeriod?.name ?? ''
                                                    },
                                                    {shouldValidate: true}
                                                )
                                                setValue(`dishes.${index}.price`, selectedDish?.price ?? 0, {
                                                    shouldValidate: true
                                                })
                                            }}
                                            size={'medium'}
                                            name={`dishes.${index}.dish`}
                                            label={t(MENU_FORM_MODEL.pages.dishes.dish.label)}
                                            isClearable={false}
                                            isSearchable={true}
                                            errorMessage={t(errors?.dishes?.[index]?.dish?.message || '')}
                                            placeholder={t(MENU_FORM_MODEL.pages.dishes.dish.label)}
                                            options={dishes.map(item => ({
                                                value: item.idDish,
                                                label: item.name
                                            }))}
                                        />
                                    )}
                                    control={control}
                                    name={`dishes.${index}.dish`}
                                />
                                <Controller
                                    render={({field: {onChange, value}}) => (
                                        <Select
                                            value={value}
                                            onChange={newValue => {
                                                onChange(newValue as SelectValue)
                                            }}
                                            size={'medium'}
                                            name={`dishes.${index}.period`}
                                            label={t(MENU_FORM_MODEL.pages.dishes.period.label)}
                                            isClearable={false}
                                            isSearchable={true}
                                            errorMessage={t(errors?.dishes?.[index]?.period?.message || '')}
                                            placeholder={t(MENU_FORM_MODEL.pages.dishes.period.label)}
                                            options={periods.map(item => ({
                                                value: item.idPeriod,
                                                label: item.name
                                            }))}
                                        />
                                    )}
                                    control={control}
                                    name={`dishes.${index}.period`}
                                />
                                <InputText
                                    type={'text'}
                                    label={t(MENU_FORM_MODEL.pages.dishes.price.label)}
                                    touched={touchedFields?.dishes?.[index]?.price}
                                    errorMessage={t(errors?.dishes?.[index]?.price?.message || '')}
                                    placeholder={t(MENU_FORM_MODEL.pages.dishes.price.label)}
                                    {...register(`dishes.${index}.price`)}
                                />
                            </StyledDishItemContent>
                        </ListItem>
                    )
                })}
            </List>
            <StyledActionsWrapper gap={2} justify={'end'}>
                <Button type="button" onClick={onCancel} variant={'tertiary'} size="md" disabled={isPendingCreate}>
                    {t('commons:cancel')}
                </Button>
                <Button type="submit" variant="primary" size="md" disabled={!isValid || isPendingCreate || !isDirty}>
                    {t('commons:save')}
                </Button>
            </StyledActionsWrapper>
        </form>
    )
}

export default MenuPageItemForm
