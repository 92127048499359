import styled, {css, DefaultTheme} from 'styled-components'
import {Virtuoso} from 'react-virtuoso'
import {MOBILE_TABLE_SCROLLBAR_WIDTH} from '@/components/commons/table/mobile-table/style'

export const StyledMobileTBody = styled(Virtuoso)`
    ${({theme: {palette}}) => css`
        overflow-y: scroll !important;

        &::-webkit-scrollbar {
            width: ${MOBILE_TABLE_SCROLLBAR_WIDTH};
            background-color: ${palette.neutral[50]};
        }
        &::-webkit-scrollbar-thumb {
            background-color: ${palette.neutral[300]};
            border-radius: 3px;

            &:hover {
                background-color: ${palette.neutral[400]};
            }
        }
    `}
`

export const StyledTRowWrapper = styled.div(
    ({theme: {palette, spacing}}: {theme: DefaultTheme}) => css`
        border: 1px solid ${palette.neutral[200]};
        border-radius: 12px;
        margin-top: ${spacing * 4}px;
        padding: ${spacing * 4}px;
    `
)

export const StyledTRow = styled.div(
    ({theme: {spacing}}: {theme: DefaultTheme}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 4}px;
    `
)

export const StyledTCell = styled.div(
    ({theme: {spacing, palette, typography}}: {theme: DefaultTheme}) => css`
        display: grid;
        grid-auto-flow: column;
        gap: ${spacing * 2}px;
        ${typography.textSm}
        color: ${palette.neutral[600]};
    `
)

export const StyledTCellColumn = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

export const StyledTCellValue = styled.div`
    text-align: end;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`
