import styled, {css} from 'styled-components'

export const StyledToastContainer = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        gap: ${spacing * 8}px;
    `
)
