import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {DefaultTheme} from 'styled-components/dist/types'

export const StyledTabs = styled(Flexbox)`
    ${({theme: {spacing, palette, shadows}}) => css`
        padding: ${spacing * 2}px;
        background-color: ${palette.neutral.white};
        border-radius: 16px;
        box-shadow: ${shadows.md};
        border: solid 1px ${palette.neutral[200]};
    `}
`

type StyledTabItemProps = {
    theme: DefaultTheme
    $isActive: boolean
}

export const StyledTabItem = styled.div<StyledTabItemProps>`
    ${({theme: {spacing, palette}, $isActive}) => css`
        gap: ${spacing * 2}px;
        background-color: ${$isActive ? palette.primary[25] : 'transparent'};
        padding: ${spacing * 3.5}px;
        border-radius: 6px;
        color: ${$isActive ? palette.neutral.black : palette.neutral[700]};
        font-weight: ${$isActive ? 700 : 'normal'};
        cursor: pointer;
    `}
`
