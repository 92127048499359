import {StaffValidationSchema} from './components/staff-form/StaffFormModel'
import {HttpCreateStaffParams} from './services/restaurantStaff.http'

export const adaptStaffToCreate = (
    data: StaffValidationSchema,
    idEatery: string,
    restaurantName: string
): HttpCreateStaffParams => ({
    data: {
        eatery: {
            idEatery,
            name: restaurantName,
            crew: [
                {
                    displayName: data.name
                }
            ]
        },
        email: data.email,
        permission: data.role.value
    }
})
