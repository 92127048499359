import {Link, NavLink, Outlet, generatePath} from 'react-router-dom'
import {StyledAccountWrapper, StyledLogout, StyledMainWrapper, StyledSidebar, StyledUsernameWrapper} from './styles'
import React from 'react'
import Logo from '@assets/images/logo.svg'
import {
    AccountsIcon,
    CustomersIcon,
    DashboardIcon,
    LogsIcon,
    MenuIcon,
    RestaurantsIcon
} from '@/components/ui/icon/Icon'
import {useTheme} from 'styled-components'
import {Avatar} from '@/components/ui/avatar/Avatar'
import {routes} from '@/utilities/constants/routes'
import {HOME_PATHS} from '@/features/home/components/home-tabs/HomeTabs'
import {truncateText} from '@/utilities/helpers'
import useAuthStore from '@/features/auth/store/store'

const ITEMS = [
    {
        isHome: true,
        name: 'Dashboard',
        icon: DashboardIcon,
        path: generatePath(routes.HOME.path, {tab: HOME_PATHS.restaurants})
    },
    {isHome: false, name: 'Restaurants', icon: RestaurantsIcon, path: routes.RESTAURANTS.path},
    {isHome: false, name: 'Accounts', icon: AccountsIcon, path: routes.ACCOUNTS.path},
    {isHome: false, name: 'Menus', icon: MenuIcon, path: routes.MENUS.path},
    {isHome: false, name: 'Customers', icon: CustomersIcon, path: routes.CUSTOMERS.path},
    {isHome: false, name: 'Logs', icon: LogsIcon, path: routes.LOGS.path}
]

export const PrivateLayout: React.FC = () => {
    const theme = useTheme()
    const user = useAuthStore(state => state.user)
    const logout = useAuthStore(state => state.logout)

    return (
        <StyledMainWrapper>
            <StyledSidebar>
                <Link to={generatePath(routes.HOME.path, {tab: HOME_PATHS.restaurants})}>
                    <img className="logo" src={Logo} alt="Dishup" />
                </Link>
                <div className="menu">
                    {ITEMS.map(item => {
                        const Icon = item.icon
                        return (
                            <NavLink
                                to={item.path}
                                key={item.path}
                                className={({isActive}) =>
                                    isActive || (item.isHome && location.pathname.startsWith('/home-')) ? 'active' : ''
                                }
                            >
                                <Icon fill="none" stroke={theme.palette.primary[50]} />
                                <span>{item.name}</span>
                            </NavLink>
                        )
                    })}
                </div>
                <div className="account">
                    <StyledAccountWrapper>
                        <Avatar imageUrl={user?.urlAvatar ?? ''} name={user?.displayName ?? '--'} />
                        <div>
                            <StyledUsernameWrapper>
                                <span>{user?.displayName ?? '--'}</span>
                                <StyledLogout onClick={logout} />
                            </StyledUsernameWrapper>
                            <span>{truncateText(user?.email ?? '--', 20)}</span>
                        </div>
                    </StyledAccountWrapper>
                </div>
            </StyledSidebar>
            <Outlet />
        </StyledMainWrapper>
    )
}
