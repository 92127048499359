import {useTranslation} from 'react-i18next'
import {StyledTabItem, StyledTabs} from './style'
import {Link, generatePath, useNavigate, useParams} from 'react-router-dom'
import {routes} from '@/utilities/constants/routes'
import {useEffect} from 'react'

export const RESTAURANT_DETAILS_PATHS = {
    masterData: 'master-data',
    periods: 'periods',
    dishes: 'dishes',
    beverages: 'beverages',
    menus: 'menus',
    staff: 'staff'
}

export const TABS = [
    {
        label: 'restaurant:tabs:masterData',
        path: RESTAURANT_DETAILS_PATHS.masterData,
        number: '1'
    },
    {
        label: 'restaurant:tabs:periods',
        path: RESTAURANT_DETAILS_PATHS.periods,
        number: '2'
    },
    {
        label: 'restaurant:tabs:dishes',
        path: RESTAURANT_DETAILS_PATHS.dishes,
        number: '3'
    },
    {
        label: 'restaurant:tabs:beverages',
        path: RESTAURANT_DETAILS_PATHS.beverages,
        number: '4'
    },
    {
        label: 'restaurant:tabs:menus',
        path: RESTAURANT_DETAILS_PATHS.menus,
        number: '5'
    },
    {
        label: 'restaurant:tabs:staff',
        path: RESTAURANT_DETAILS_PATHS.staff,
        number: '6'
    }
]

export const RestaurantDetailsTabs = () => {
    const {t} = useTranslation()
    const {id: customerId, tab: currentTab} = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (!currentTab)
            navigate(
                generatePath(routes.RESTAURANT_DETAILS.path, {id: customerId, tab: RESTAURANT_DETAILS_PATHS.masterData})
            )
    }, [currentTab])

    return (
        <StyledTabs gap={8} align="center">
            {TABS.map(tab => (
                <Link to={generatePath(routes.RESTAURANT_DETAILS.path, {id: customerId, tab: tab.path})} key={tab.path}>
                    <StyledTabItem $isActive={tab.path === currentTab}>
                        <span className="number">{tab.number}</span>
                        <span className="label">{t(tab.label)}</span>
                    </StyledTabItem>
                </Link>
            ))}
        </StyledTabs>
    )
}

export default RestaurantDetailsTabs
