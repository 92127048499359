import {Box} from '@/components/commons/box/Box'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {StyledActionsWrapper, StyledStaffInfoWrapper, StyledStaffMainWrapper, StyledMainWrapper} from './style'
import {Button} from '@/components/ui/button/Button'
import {Trash01Icon} from '@/components/ui/icon/Icon'
import {Checkbox} from '@/components/commons/checkbox/CheckBox'
import {List} from '@/components/commons/list/list/List'
import {ListItem} from '@/components/commons/list/list-item/ListItem'
import {useSelectableList} from '@/hooks/useSelectableList'
import {StaffItem} from '@/features/restaurant/types'
import {DeleteModal} from '@/components/commons/delete-modal/DeleteModal'
import {EmptyList} from '@/components/commons/empty-list/EmptyList'
import {useParams} from 'react-router-dom'
import {useDeleteStaff} from '../../services/queries/useDeleteStaff'
import {Chip} from '@/components/ui/chip/Chip'

type StaffListProps = {
    crew: StaffItem[]
}

export const StaffList: React.FC<StaffListProps> = ({crew}) => {
    const {t} = useTranslation()
    const {items, setItems, onSelectItem, onSelectAll, isAllSelected, isNoneSelected} = useSelectableList([], [0])
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const {id: idRestaurant} = useParams()
    const {mutate: deleteStaff} = useDeleteStaff({idRestaurant: `${idRestaurant}`})

    useEffect(() => {
        if (crew && crew?.length) {
            setItems(
                crew?.map((_, index) => ({
                    index,
                    selected: false
                }))
            )
        }
    }, [crew?.length])

    const onCloseDeleteModal = () => {
        setIsDeleteModalVisible(false)
    }

    const onSubmitDeleteModal = () => {
        setIsDeleteModalVisible(false)
        deleteStaff(items.filter(item => item.selected).map(item => crew[item.index].idEateryAccount))
    }

    return (
        <>
            <Box>
                <StyledMainWrapper>
                    {crew.length ? (
                        <>
                            <div>
                                <StyledActionsWrapper>
                                    <span>{t('staff:list:label')}</span>
                                    <Button
                                        type="button"
                                        variant="transparentDanger"
                                        disabled={isNoneSelected}
                                        onClick={() => setIsDeleteModalVisible(true)}
                                    >
                                        <Trash01Icon />
                                        {t('commons:delete')}
                                    </Button>
                                    <Checkbox
                                        disabled={items.length === 0}
                                        checked={isAllSelected}
                                        onChange={onSelectAll}
                                        id="selectAll"
                                        label={t('commons:select_all')}
                                    />
                                </StyledActionsWrapper>
                                <List>
                                    {crew?.map((item, itemIndex) => {
                                        const checked =
                                            items.find((element, index) => index === itemIndex && element.selected) !==
                                            undefined
                                        return (
                                            <ListItem key={item.idAccount} isSelected={checked}>
                                                <Checkbox
                                                    disabled={item.permission === 'OWNER'}
                                                    checked={checked}
                                                    onChange={() => onSelectItem(itemIndex)}
                                                    id={`${item.idAccount}`}
                                                />
                                                <StyledStaffMainWrapper>
                                                    <StyledStaffInfoWrapper $disabled={item.permission === 'OWNER'}>
                                                        <span className="name">{item.displayName}</span>
                                                        <span className="email">{item.email || item.rapidEmail}</span>
                                                        <span className="role">{item.permission}</span>
                                                        <div className="status">
                                                            {item.status === 'ACCEPTED' ? (
                                                                <Chip
                                                                    id={`${item.idAccount}`}
                                                                    variant="success"
                                                                    label={item.status}
                                                                />
                                                            ) : (
                                                                <Chip
                                                                    id={`${item.idAccount}`}
                                                                    variant="default"
                                                                    label={item.status}
                                                                />
                                                            )}
                                                        </div>
                                                    </StyledStaffInfoWrapper>
                                                </StyledStaffMainWrapper>
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </div>
                        </>
                    ) : (
                        <EmptyList />
                    )}
                </StyledMainWrapper>
            </Box>
            {isDeleteModalVisible && (
                <DeleteModal
                    title={t('commons:delete_items_title')}
                    paragraph={t('commons:delete_items_description')}
                    isLoading={false}
                    onClose={onCloseDeleteModal}
                    onDelete={onSubmitDeleteModal}
                />
            )}
        </>
    )
}

export default StaffList
