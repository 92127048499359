import {Controller, SubmitHandler, useFieldArray, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {BEVERAGE_FORM_MODEL, BeverageFormSchema, BeverageItem, BeverageValidationSchema} from './BeverageFormModel'
import {useTranslation} from 'react-i18next'
import {
    StyledActionsWrapper,
    StyledBox,
    StyledBeverageItemContent,
    StyledBeveragesActionsWrapper,
    StyledImageUploaderWrapper
} from './style'
import {Button} from '@/components/ui/button/Button'
import React, {useEffect, useState} from 'react'
import {PlusIcon, Trash01Icon} from '@/components/ui/icon/Icon'
import {useTheme} from 'styled-components'
import {TextArea} from '@/components/commons/textarea/TextArea'
import {List} from '@/components/commons/list/list/List'
import {ListItem} from '@/components/commons/list/list-item/ListItem'
import {Checkbox} from '@/components/commons/checkbox/CheckBox'
import {useSelectableList} from '@/hooks/useSelectableList'
import {HttpUploadBeveragesFileResponse, httpUploadBeverageImage} from '../../services/restaurantBeverages.http'
import toast from 'react-hot-toast'
import {errorHandler} from '@/utilities/genericErrorHandler'
import {useCreateBeverages} from '../../services/queries/useCreateBeverage'
import {adaptBeveragesToCreate} from '../../utils'
import {BeverageImageItem} from '../../types'
import {ImageUploader} from '@/components/commons/image-uploader/ImageUploader'
import {downloadFile} from '@/utilities/helpers'
import {QUERY_KEYS} from '@/queryClient'
import {useQueryClient} from '@tanstack/react-query'

interface BeverageFormProps {
    restaurantId: string
    onCancel?: () => void
    uploadedDishes?: HttpUploadBeveragesFileResponse | null
    beveragePeriodId: string
    defaultValues?: BeverageValidationSchema
    defaultImages?: BeverageImageItem[]
}

const BeverageForm: React.FC<BeverageFormProps> = ({
    onCancel,
    uploadedDishes,
    restaurantId,
    beveragePeriodId,
    defaultValues,
    defaultImages
}) => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()
    const theme = useTheme()
    const [files, setFiles] = useState<BeverageImageItem[]>(defaultImages ?? [])
    const [isUploadingFiles, setIsUploadingFiles] = useState(false)
    const {items, setItems, onSelectItem, onSelectAll, isAllSelected, isNoneSelected} = useSelectableList([
        {
            index: 0,
            selected: false
        }
    ])

    const {
        control,
        register,
        handleSubmit,
        formState: {errors, touchedFields, isValid, isDirty},
        setValue,
        getValues
    } = useForm<BeverageValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(BeverageFormSchema),
        defaultValues: defaultValues || {
            beverages: [
                {
                    name: '',
                    description: '',
                    price: 0,
                    image: null
                }
            ]
        }
    })

    const {
        fields: rows,
        append: appendRow,
        remove: removeRow
    } = useFieldArray({
        control,
        name: 'beverages'
    })

    useEffect(() => {
        if (uploadedDishes?.length && rows.length === 1) {
            removeRow(0)
            uploadedDishes.forEach(item =>
                appendRow({
                    name: item.name,
                    description: item.description,
                    price: item.price,
                    image: null
                })
            )
        }
    }, [uploadedDishes?.length])

    const onClickAppendBeverage = () => {
        appendRow({
            name: '',
            description: '',
            price: 0,
            image: null
        })
        setItems(prev => [...prev, {index: prev.length, selected: false}])
    }

    const onClickDeleteSelected = () => {
        const formBeverages = getValues('beverages')
        let counter = 0
        const updatedBeverages: BeverageItem[] = []
        items.forEach((item, index) => {
            if (!item.selected) {
                updatedBeverages.push(formBeverages[index])
                counter++
            }
        })
        setValue('beverages', updatedBeverages)
        const updatedSelected = Array.from({length: counter}, (_, index) => ({index, selected: false}))
        setItems(updatedSelected)
    }

    // mutations
    const {mutate: createBeverageMutation, isPending: isPendingCreate} = useCreateBeverages({
        restaurantId: restaurantId,
        onSuccess: data => {
            toast.success(t(defaultValues ? 'beverage:update_beverage_success' : 'beverage:new_beverage_success'))

            // uploading images
            const promises: Promise<void>[] = []
            files.forEach(item => {
                const dish = data.find((_, index) => index === item.index)
                if (item.file) promises.push(httpUploadBeverageImage(item.file, dish?.idDish ?? ''))
            })
            setIsUploadingFiles(true)
            Promise.all(promises).finally(() => {
                setIsUploadingFiles(false)
                onCancel?.()
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.RESTAURANT_DETAILS, restaurantId]})
            })
        },
        onError: error => errorHandler(error)
    })

    // submit handler
    const onSubmit: SubmitHandler<BeverageValidationSchema> = data => {
        createBeverageMutation(adaptBeveragesToCreate(data, restaurantId, beveragePeriodId))
    }

    const onChangeFile = (index: number, uploadedFile: File) => {
        const reader = new FileReader()
        reader.onload = () => {
            const updatedFiles = [
                ...files.filter(item => item.index !== index),
                {index, file: uploadedFile, image: reader.result as string}
            ]
            setFiles(updatedFiles)
        }
        reader.readAsDataURL(uploadedFile)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {!defaultValues && (
                <StyledBeveragesActionsWrapper>
                    <Button
                        type="button"
                        variant="transparentDanger"
                        disabled={isNoneSelected}
                        onClick={onClickDeleteSelected}
                    >
                        <Trash01Icon />
                        {t('commons:delete')}
                    </Button>
                    <Checkbox
                        disabled={items.length === 0}
                        checked={isAllSelected}
                        onChange={onSelectAll}
                        id="selectAll"
                        label={t('commons:select_all')}
                    />
                    <Button type="button" variant="secondary" onClick={onClickAppendBeverage}>
                        <PlusIcon stroke={theme.palette.neutral[500]} />
                        {t('beverage:addForm:addButton')}
                    </Button>
                </StyledBeveragesActionsWrapper>
            )}
            <StyledBox>
                {rows.length === 0 && <span>{t('beverage:addForm:emptyList')}</span>}
                <List>
                    {rows.map((field, index) => {
                        const checked = items.find(element => element.index === index && element.selected) !== undefined
                        const file = files.find(file => file.index === index)
                        return (
                            <ListItem isSelected={checked} key={field.id}>
                                {!defaultValues && (
                                    <Checkbox checked={checked} onChange={() => onSelectItem(index)} id={`${index}`} />
                                )}
                                <StyledImageUploaderWrapper>
                                    <Controller
                                        render={({field: {onChange, value}}) => (
                                            <ImageUploader
                                                maxSizeInMB={10}
                                                accept={{'image/jpeg': [], 'image/png': []}}
                                                value={value ?? undefined}
                                                onChange={changedFile => {
                                                    onChangeFile(index, changedFile)
                                                    onChange(changedFile)
                                                }}
                                                downloadCallback={downloadFile}
                                                image={file?.image}
                                            />
                                        )}
                                        control={control}
                                        name={`beverages.${index}.image`}
                                    />
                                </StyledImageUploaderWrapper>
                                <StyledBeverageItemContent>
                                    <input type={'hidden'} {...register(`beverages.${index}.idDish`)} />
                                    <InputText
                                        type={'text'}
                                        label={t(BEVERAGE_FORM_MODEL.beverages.name.label)}
                                        touched={touchedFields?.beverages?.[index]?.name}
                                        errorMessage={t(errors.beverages?.[index]?.name?.message || '')}
                                        placeholder={t(BEVERAGE_FORM_MODEL.beverages.name.label)}
                                        {...register(`beverages.${index}.name`)}
                                    />
                                    <InputText
                                        type={'text'}
                                        label={t(BEVERAGE_FORM_MODEL.beverages.price.label)}
                                        touched={touchedFields?.beverages?.[index]?.price}
                                        errorMessage={t(errors.beverages?.[index]?.price?.message || '')}
                                        placeholder={t(BEVERAGE_FORM_MODEL.beverages.price.label)}
                                        {...register(`beverages.${index}.price`)}
                                    />
                                    <TextArea
                                        label={t(BEVERAGE_FORM_MODEL.beverages.description.label)}
                                        errorMessage={t(errors.beverages?.[index]?.description?.message || '')}
                                        placeholder={t(BEVERAGE_FORM_MODEL.beverages.description.label)}
                                        {...register(`beverages.${index}.description`)}
                                    />
                                </StyledBeverageItemContent>
                            </ListItem>
                        )
                    })}
                </List>
            </StyledBox>

            <StyledActionsWrapper gap={2} justify={'end'}>
                <Button
                    type="button"
                    onClick={onCancel}
                    variant={'tertiary'}
                    size="md"
                    disabled={isPendingCreate || isUploadingFiles}
                >
                    {t('commons:cancel')}
                </Button>
                <Button
                    type="submit"
                    variant="primary"
                    size="md"
                    disabled={!isValid || !isDirty || !rows.length || isPendingCreate || isUploadingFiles}
                >
                    {t('commons:save')}
                </Button>
            </StyledActionsWrapper>
        </form>
    )
}

export default BeverageForm
