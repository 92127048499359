import {forwardRef, ReactNode, TextareaHTMLAttributes} from 'react'
import {Label} from '@components/ui/label/Label.tsx'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {DefaultNamespace} from 'i18next'
import {
    StyledTextarea,
    StyledTextareaContainer,
    StyledTextareaGrid,
    StyledTextareaWrapper
} from '@components/commons/textarea/style.ts'

export interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    className?: string
    label?: string | DefaultNamespace
    name?: string
    typeIcon?: ReactNode
    errorMessage?: string | DefaultNamespace
    helpText?: string | DefaultNamespace
    placeholder?: string
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
    ({label, name, typeIcon, errorMessage, helpText, className, disabled, placeholder, ...rest}, ref) => {
        return (
            <StyledTextareaContainer className={className}>
                {label && <Label htmlFor={name}>{label}</Label>}
                <StyledTextareaGrid>
                    <StyledTextareaWrapper $hasError={!!errorMessage} $disabled={disabled}>
                        {typeIcon}
                        <StyledTextarea ref={ref} name={name} placeholder={placeholder} disabled={disabled} {...rest} />
                    </StyledTextareaWrapper>
                </StyledTextareaGrid>
                {/*help text*/}
                <InputHelpText error={errorMessage} helpText={helpText} />
            </StyledTextareaContainer>
        )
    }
)

TextArea.displayName = 'TextArea'
