import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpCreateMenuPageItems, HttpGetMenuPageItemsDetailsResponse} from '../restaurantMenus.http'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons'
import {errorHandler} from '@/utilities/genericErrorHandler'

type UseCreateMenuPageItemsProps = {
    idEatery: string
    onSuccess?: (data: HttpGetMenuPageItemsDetailsResponse) => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useCreateRestaurantMenuPageItems = (options: UseCreateMenuPageItemsProps) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.CREATE_MENU_PAGE_ITEMS],
        mutationFn: httpCreateMenuPageItems,
        onSuccess: async data => {
            await client.invalidateQueries({queryKey: [QUERY_KEYS.MENUS]})
            await client.invalidateQueries({queryKey: [QUERY_KEYS.RESTAURANT_DETAILS, options.idEatery]})
            options?.onSuccess?.(data)
        },
        onError: (error: AxiosError<ErrorResponseData>) => errorHandler(error)
    })
}
