import React, {FC} from 'react'
import {DPMonth, DPPropGetter} from '@rehookify/datepicker'
import {
    CalendarWrapper,
    StyledBackButton,
    StyledCalendarHeader,
    StyledMonthYearButton,
    StyledYearMonthGrid
} from '@components/commons/calendar/style.ts'
import {capitalize} from '@utilities/helpers.ts'
import dayjs from '@/dayjs.ts'

type MonthSelectorProps = {
    months: DPMonth[]
    monthButton: (month: DPMonth) => DPPropGetter
    currentMonth: string
    locale?: string
    setViewState: React.Dispatch<React.SetStateAction<'date' | 'month' | 'year'>>
}

export const MonthSelector: FC<MonthSelectorProps> = ({
    months,
    monthButton,
    currentMonth,
    locale = 'en',
    setViewState
}) => {
    return (
        <CalendarWrapper className={'monthYearCalendar'}>
            <StyledCalendarHeader>Months</StyledCalendarHeader>
            <StyledYearMonthGrid>
                {months.map(m => (
                    <StyledMonthYearButton
                        key={m.month}
                        variant={m.month == currentMonth ? 'primary' : 'ghost'}
                        {...monthButton(m)}
                    >
                        {capitalize(dayjs(m.$date).locale(locale).format('MMM')).slice(0, 3)}
                    </StyledMonthYearButton>
                ))}
            </StyledYearMonthGrid>
            <StyledBackButton variant="ghost" onClick={() => setViewState('date')}>
                Back
            </StyledBackButton>
        </CalendarWrapper>
    )
}
