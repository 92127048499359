import {Container} from '@components/ui/container/Container.tsx'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'
import {useTranslation} from 'react-i18next'
import {SearchLgIcon} from '@components/ui/icon/Icon.tsx'
import {toast} from '@/utilities/toast/toast'
import {useEffect, useRef} from 'react'
import {EmptySearch} from '@/components/commons/empty-search/EmptySearch'
import {InputText} from '@/components/commons/input-text/InputText'
import {FiltersContainer} from '@/components/commons/filters-container/FiltersContainer'
import {ListPageLayout} from '@/layouts/list-page-layout/ListPageLayout'
import {useLogsTable} from '@/features/log/services/queries/useLogsTable'
import {LogsTable} from '@/features/log/components/logs-table/LogsTable'
import {Button} from '@/components/ui/button/Button'
import axios from '@/axiosInstance'

export const Logs = () => {
    const {t} = useTranslation()
    const logsQuery = useLogsTable()
    const searchRef = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
        if (logsQuery.isError) toast.error(logsQuery.error?.message)
    }, [logsQuery.isError])

    const onClickExportLogs = async () => {
        const response = await axios.get('/~/export-logs')
        const blob = new Blob([response.data], {type: 'text/csv'})
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'logs.csv'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
    }

    return (
        <ListPageLayout>
            <Container fullWidth>
                <PageHero
                    title={t('log:title')}
                    ctaComponent={
                        <Button size={'sm'} variant={'secondary'} onClick={onClickExportLogs}>
                            {t('commons:export')}
                        </Button>
                    }
                />

                <FiltersContainer>
                    <InputText
                        onChange={e => logsQuery.onSearch(e.currentTarget.value)}
                        defaultValue={logsQuery.searchValue}
                        type={'text'}
                        placeholder={t('commons:search_placeholder')}
                        typeIcon={<SearchLgIcon size={18} />}
                        inputSize={'sm'}
                        ref={searchRef}
                        width={200}
                    />
                </FiltersContainer>

                <LogsTable
                    data={logsQuery.remappedData}
                    isChangingPage={logsQuery.isFetchingNextPage}
                    isError={logsQuery.isError}
                    isLoading={logsQuery.isLoading}
                    onChangePage={logsQuery.fetchNextPage}
                    searchValue={logsQuery.searchValue}
                    onResetSearchCb={() => logsQuery.onResetSearch(searchRef)}
                    emptySearchStateComponent={<EmptySearch title={t('commons:no_results')} icon={<SearchLgIcon />} />}
                    sorter={logsQuery.sorter}
                />
            </Container>
        </ListPageLayout>
    )
}

Logs.displayName = 'Logs'
