import React from 'react'
import {useTranslation} from 'react-i18next'
import {Modal} from '@/components/ui/modal/Modal'
import {StyledModal, StyledTitle} from './style'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import AccountForm from './AccountForm'
import {AccountValidationSchema} from './AccountFormModel'

interface AccountModalProps {
    onClose: () => void
    defaultValues?: AccountValidationSchema
    accountId?: string
}

export const AccountModal: React.FC<AccountModalProps> = ({onClose, defaultValues, accountId}) => {
    const {t} = useTranslation()

    return (
        <Modal width={1000} closeIconOffset="sm" onClose={onClose}>
            <StyledModal>
                <Flexbox align={'center'}>
                    <StyledTitle>{t('account:details:title')}</StyledTitle>
                </Flexbox>
                <AccountForm accountId={accountId} defaultValues={defaultValues} onCancel={onClose} />
            </StyledModal>
        </Modal>
    )
}

export default AccountModalProps
