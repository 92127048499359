import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledLoginWrapper = styled.form`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`

export const StyledLoginBox = styled(Flexbox)`
    ${({theme: {typography, palette, spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 360px;

        & > h4 {
            ${typography.displaySm};
            font-weight: 700;
            color: ${palette.neutral['900']};
            text-align: center;
        }

        & > p {
            ${typography.textMd};
            margin: ${spacing * 2}px 0 ${spacing * 14}px 0;
            text-align: center;
        }

        & > .circles {
            border-radius: 50%;
            margin: 0 auto ${spacing * 6}px;
            box-shadow:
                0 0 0 38px ${palette.neutral.white},
                0 0 0 39px ${palette.neutral[100]},
                0 0 0 88px ${palette.neutral.white},
                0 0 0 89px ${palette.neutral[100]},
                0 0 0 138px ${palette.neutral.white},
                0 0 0 139px ${palette.neutral[100]},
                0 0 0 188px ${palette.neutral.white},
                0 0 0 189px ${palette.neutral[50]},
                0 0 0 238px ${palette.neutral.white},
                0 0 0 239px ${palette.neutral[25]};
        }
    `}
`
