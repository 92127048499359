import React from 'react'
import {useTranslation} from 'react-i18next'
import {Modal} from '@/components/ui/modal/Modal'
import {StyledModal, StyledTitle} from './style'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import MenuForm from './MenuForm'
import {DishItem, PeriodItem} from '@/features/restaurant/types'

interface MenuModalProps {
    onClose: () => void
    periods: PeriodItem[]
    restaurantId: string
    dishes: DishItem[]
}

export const MenuModal: React.FC<MenuModalProps> = ({onClose, periods, restaurantId, dishes}) => {
    const {t} = useTranslation()

    return (
        <Modal width={1000} closeIconOffset="sm" onClose={onClose}>
            <StyledModal>
                <Flexbox align={'center'}>
                    <StyledTitle>{t('menu:addModal:title')}</StyledTitle>
                </Flexbox>
                <MenuForm dishes={dishes} restaurantId={restaurantId} periods={periods} onCancel={onClose} />
            </StyledModal>
        </Modal>
    )
}

export default MenuModalProps
