import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {AccountsSearchParams} from '@/features/account/types'
import {useAccountsQuery} from './useAccounts'
import {useEffect} from 'react'
import {useInputSearch} from '@/hooks/useInputSearch'

export const useAccountsTable = () => {
    const multiParams = [] as const satisfies ReadonlyArray<keyof AccountsSearchParams>
    const {searchParams, setSearchParams} = useParsedSearchParams(multiParams, AccountsSearchParams)
    const {searchValue, searchError, onSearch, onResetSearch} = useInputSearch(searchParams.search)
    const accountsQuery = useAccountsQuery({
        ...searchParams,
        limit: 20
    })

    useEffect(() => {
        setSearchParams({search: searchValue})
    }, [searchValue])

    const sort = (sorter: {
        orderBy: AccountsSearchParams['orderBy']
        orderDirection: AccountsSearchParams['orderDirection']
    }) => {
        if (sorter.orderDirection) {
            setSearchParams({orderBy: sorter.orderBy, orderDirection: sorter.orderDirection})
        } else {
            setSearchParams({orderBy: undefined, orderDirection: undefined})
        }
    }

    return {
        ...accountsQuery,
        onSearch,
        onResetSearch,
        searchValue,
        searchError,
        sorter: {orderBy: searchParams.orderBy, orderDirection: searchParams.orderDirection, onSort: sort}
    }
}
