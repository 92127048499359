import React, {LabelHTMLAttributes} from 'react'
import {StyledLabel} from '@components/ui/label/style.ts'

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
    children: React.ReactNode
    htmlFor?: string
}

export const Label = ({children, htmlFor}: LabelProps) => <StyledLabel htmlFor={htmlFor}>{children}</StyledLabel>

Label.displayName = 'Label'
