import {z} from 'zod'

export const Customer = z.object({
    id: z.coerce.number(),
    lastLogin: z.date(),
    lastUpdate: z.date(),
    creationDate: z.date(),
    registrationDate: z.date(),
    name: z.string(),
    email: z.string().nullable(),
    phoneNumber: z.string().nullable(),
    language: z.string().nullable(),
    currency: z.object({
        value: z.string(),
        label: z.string()
    })
})
export type Customer = z.infer<typeof Customer>

export const CustomerListItem = z.object({
    email: z.string(),
    mobile: z.string().nullable(),
    idApple: z.string().nullable(),
    idGoogle: z.string().nullable(),
    idStripe: z.string().nullable(),
    language: z.string(),
    givenName: z.string(),
    urlAvatar: z.string().nullable(),
    dateSignup: z.string(),
    familyName: z.string(),
    idCustomer: z.string(),
    idFacebook: z.string().nullable(),
    displayName: z.string().nullable(),
    dateCreation: z.string()
})
export type CustomerListItem = z.infer<typeof CustomerListItem>

export const CustomersSearchParams = z.object({
    orderBy: z.enum(['displayName']).optional(),
    orderDirection: z.enum(['asc', 'desc']).optional(),
    search: z.string().optional()
})
export type CustomersSearchParams = z.infer<typeof CustomersSearchParams>
