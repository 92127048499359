import {Box} from '@/components/commons/box/Box'
import {Button} from '@/components/ui/button/Button'
import {Container} from '@/components/ui/container/Container'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Spinner} from '@/components/ui/spinner/Spinner'
import CustomerForm from '@/features/customer/components/customer-form/CustomerForm'
import {CustomerModal} from '@/features/customer/components/customer-form/CustomerModal'
import {adaptCustomerToForm} from '@/features/customer/components/customer-form/utils'
import {useGetCustomerDetails} from '@/features/customer/services/queries/useGetCustomerDetails'
import {DetailsPageLayout} from '@/layouts/details-page-layout/DetailsPageLayout'
import {toast} from '@/utilities/toast/toast'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {useTheme} from 'styled-components'

export const CustomerDetails = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const {id: customerId} = useParams()
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
    const customerQuery = useGetCustomerDetails(
        {
            customerId: customerId ?? ''
        },
        {enabled: !!customerId}
    )

    useEffect(() => {
        if (customerQuery.isError) toast.error(customerQuery.error?.message)
    }, [customerQuery.isError])

    return (
        <>
            <DetailsPageLayout
                title={customerQuery.data?.customer.displayName || ''}
                subtitle={customerQuery.data?.customer.idCustomer || ''}
                sideContent={
                    <Button onClick={() => setIsInfoModalOpen(true)} style={{display: 'none'}}>
                        {t('commons:update_info')}
                    </Button>
                }
            >
                <Container fullWidth>
                    {customerQuery.isLoading && (
                        <Flexbox justify="center">
                            <Spinner color={theme.palette.neutral[400]} size={30} />
                        </Flexbox>
                    )}
                    {customerQuery.data && (
                        <Box>
                            <CustomerForm
                                readOnly
                                defaultValues={adaptCustomerToForm(customerQuery.data)}
                                customerId={customerId}
                            />
                        </Box>
                    )}
                </Container>
            </DetailsPageLayout>
            {isInfoModalOpen && (
                <CustomerModal
                    customerId={customerId}
                    defaultValues={customerQuery.data ? adaptCustomerToForm(customerQuery.data) : undefined}
                    onClose={() => setIsInfoModalOpen(false)}
                />
            )}
        </>
    )
}

CustomerDetails.displayName = 'CustomerDetails'
