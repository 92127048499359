import axios from '@/axiosInstance'
import {z} from 'zod'

/**
 * httpDeletePeriods
 * Here we will delete multiple periods
 */

export const httpDeletePeriods = async (idRestaurant: string, idPeriod: string[]): Promise<void> => {
    await axios.delete(`/~/eatery/${idRestaurant}/period`, {params: {idPeriod}})
}

/**
 * httpCreatePeriods
 * Here we will create multiple period instances
 */

// Response
export const HttpGetPeriodDetailsResponse = z.array(
    z.object({
        name: z.string(),
        period: z.number(),
        idEatery: z.string(),
        idPeriod: z.string()
    })
)
export type HttpGetPeriodDetailsResponse = z.infer<typeof HttpGetPeriodDetailsResponse>

export const HttpPeriodBodyRequest = z.array(
    z.object({
        name: z.string(),
        period: z.number()
    })
)
export type HttpPeriodBodyRequest = z.infer<typeof HttpPeriodBodyRequest>

export const HttpCreatePeriodParams = z.object({
    restaurantId: z.string(),
    data: HttpPeriodBodyRequest
})
export type HttpCreatePeriodParams = z.infer<typeof HttpCreatePeriodParams>

export const httpCreatePeriods = async (params: HttpCreatePeriodParams): Promise<HttpGetPeriodDetailsResponse> => {
    const response = await axios.post(`/~/eatery/${params.restaurantId}/period`, params.data)
    return response.data
}
