import {TimeRange} from '@/utilities/constants/common'
import {formatLocaleDate} from '@/utilities/helpers'
import dayjs from 'dayjs'

// Types

interface HomRecordSetItem {
    dateCreation: string
}

export interface HomeRecordSet {
    [key: string]: HomRecordSetItem
}

interface Counter {
    name: string
    count: number
}

// Weekly home data

const getHomeWeeklyData = (recordSet: HomeRecordSet): Counter[] => {
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const dayCounts: {[key: string]: number} = {
        Sun: 0,
        Mon: 0,
        Tue: 0,
        Wed: 0,
        Thu: 0,
        Fri: 0,
        Sat: 0
    }

    for (const key in recordSet) {
        const record = recordSet[key]
        const date = dayjs(record.dateCreation)
        const dayName = dayNames[date.day()]
        dayCounts[dayName]++
    }

    return dayNames.map(day => ({
        name: day,
        count: dayCounts[day]
    }))
}

// Monthly home data

const getHomeMonthlyData = (recordSet: HomeRecordSet): Counter[] => {
    const today = dayjs()
    const daysInMonth = today.daysInMonth()

    const dayCounts: {[key: string]: number} = {}

    for (let i = 1; i <= daysInMonth; i++) {
        const day = i < 10 ? `0${i}` : `${i}`
        dayCounts[day] = 0
    }

    for (const key in recordSet) {
        const record = recordSet[key]
        const date = dayjs(record.dateCreation)
        if (date.isSame(today, 'month')) {
            const day = date.format('DD')
            dayCounts[day]++
        }
    }

    return Object.keys(dayCounts)
        .sort((a, b) => parseInt(a) - parseInt(b))
        .map(day => ({
            name: day,
            count: dayCounts[day]
        }))
}

// Yearly home data

const getHomeYearlyData = (recordSet: HomeRecordSet): Counter[] => {
    const today = dayjs()
    const monthsInYear = 12

    const monthCounts: {[key: string]: number} = {}

    for (let i = 1; i <= monthsInYear; i++) {
        const month = i < 10 ? `0${i}` : `${i}`
        monthCounts[month] = 0
    }

    for (const key in recordSet) {
        const record = recordSet[key]
        const date = dayjs(record.dateCreation)
        if (date.isSame(today, 'year')) {
            const month = date.format('MM')
            monthCounts[month]++
        }
    }

    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    return Object.keys(monthCounts)
        .sort((a, b) => parseInt(a) - parseInt(b))
        .map((month, index) => ({
            name: monthNames[index],
            count: monthCounts[month]
        }))
}

// All home data
const getHomeAllData = (recordSet: HomeRecordSet): Counter[] => {
    const quarterCounts: {[key: string]: number} = {}

    // Find the earliest and latest date in the recordset
    let minDate = dayjs().endOf('month')
    let maxDate = dayjs().startOf('month')

    for (const key in recordSet) {
        const record = recordSet[key]
        const date = dayjs(record.dateCreation)

        if (date.isBefore(minDate)) {
            minDate = date
        }
        if (date.isAfter(maxDate)) {
            maxDate = date
        }
    }

    // Initialize quarterCounts for all quarters between minDate and maxDate
    let currentDate = minDate.startOf('quarter')
    const endDate = maxDate.endOf('quarter')

    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'quarter')) {
        const quarter = `Q${currentDate.quarter()} ${currentDate.year()}`
        quarterCounts[quarter] = 0
        currentDate = currentDate.add(1, 'quarter')
    }

    // Count occurrences of each quarter in the recordset
    for (const key in recordSet) {
        const record = recordSet[key]
        const date = dayjs(record.dateCreation).startOf('quarter')
        const quarter = `Q${date.quarter()} ${date.year()}`
        quarterCounts[quarter]++
    }

    // Create the final result array
    const result: Counter[] = Object.keys(quarterCounts).map(quarter => ({
        name: quarter,
        count: quarterCounts[quarter]
    }))

    return result
}

// Home data with custom dates range

const getHomeCustomData = (recordSet: HomeRecordSet, dateRange: Date[]): Counter[] => {
    const [startDate, endDate] = dateRange.map(date => dayjs(date).startOf('day'))

    if (!startDate.isValid() || !endDate.isValid() || startDate.isAfter(endDate)) {
        throw new Error('Invalid date range provided.')
    }

    const dayCounts: {[key: string]: number} = {}

    // Initialize dayCounts for all days between startDate and endDate
    let currentDate = startDate
    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
        const day = currentDate.format('YYYY-MM-DD')
        dayCounts[day] = 0
        currentDate = currentDate.add(1, 'day')
    }

    // Count occurrences of each day in the recordset
    for (const key in recordSet) {
        const record = recordSet[key]
        const date = dayjs(record.dateCreation).startOf('day')
        if (!date.isBefore(startDate) && !date.isAfter(endDate)) {
            // Inclusive range check
            const day = date.format('YYYY-MM-DD')
            dayCounts[day]++
        }
    }

    // Create the final result array
    const result: Counter[] = Object.keys(dayCounts).map(day => ({
        name: formatLocaleDate(day),
        count: dayCounts[day]
    }))

    return result
}

// Get the correct data by time range

export const getHomeDataByTimeRange = (
    recordSet: HomeRecordSet,
    timeRange: TimeRange,
    selectedDates: Date[] | null
): Counter[] => {
    switch (timeRange) {
        case TimeRange.Week:
            return getHomeWeeklyData(recordSet)
        case TimeRange.Month:
            return getHomeMonthlyData(recordSet)
        case TimeRange.Year:
            return getHomeYearlyData(recordSet)
        case TimeRange.All:
            return getHomeAllData(recordSet)
        case TimeRange.Custom:
            if (selectedDates?.length === 2) {
                return getHomeCustomData(recordSet, selectedDates)
            }
            return []
        default:
            return []
    }
}
