import {QUERY_KEYS} from '@/queryClient'
import {UseQueryOptions, useQuery} from '@tanstack/react-query'
import {HttpGetCustomerDetailsParams, HttpGetCustomerDetailsResponse, httpGetCustomerDetails} from '../customer.http'

export const useGetCustomerDetails = (
    params: HttpGetCustomerDetailsParams,
    options?: Omit<UseQueryOptions<HttpGetCustomerDetailsResponse>, 'queryFn' | 'queryKey'>
) => {
    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.CUSTOMER_DETAILS, params.customerId],
        queryFn: () => httpGetCustomerDetails(params)
    })
}
