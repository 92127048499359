import {Box} from '@/components/commons/box/Box'
import HomeSection from '../home-section/HomeSection'
import {useTranslation} from 'react-i18next'
import {HomeBox} from '../home-box/HomeBox'
import {StyledBarChartWrapper, StyledCardsWrapper, StyledLegend, StyledMainWrapper} from './style'
import {ArrowDownIcon, ArrowUpIcon} from '@/components/ui/icon/Icon'
import {formaThousandsNumber} from '@/utilities/helpers'
import {useMemo, useState} from 'react'
import {TimeRange} from '@/utilities/constants/common'
import {HomeRecordSet, getHomeDataByTimeRange} from '../../utils'
import {useTheme} from 'styled-components'
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts'
import {useGetHomeCustomersData} from '../../services/queries/useGetHomeCustomersData'
import dayjs from 'dayjs'

export const HomeCustomers = () => {
    const {t} = useTranslation()
    const [selectedCustomersDates, setSelectedCustomersDates] = useState<Date[] | null>(null)
    const [selectedCustomersTimeRange, setSelectedCustomersTimeRange] = useState<TimeRange>(TimeRange.All)
    const theme = useTheme()
    const {data: customersData} = useGetHomeCustomersData({
        startDate: selectedCustomersDates?.[0]
            ? dayjs(selectedCustomersDates[0]).format('YYYY-MM-DD hh:mm:ss')
            : undefined,
        endDate: selectedCustomersDates?.[1]
            ? dayjs(selectedCustomersDates[1]).format('YYYY-MM-DD hh:mm:ss')
            : undefined
    })
    const isPositiveCustomersGrowthSinceLastMonth = useMemo(
        () => parseFloat(customersData?.data.customerData.customersGrowthSinceLastMonth ?? '0') >= 0,
        [customersData?.data]
    )

    return (
        <StyledMainWrapper>
            <Box>
                <HomeSection
                    selectedDates={selectedCustomersDates}
                    setSelectedDates={setSelectedCustomersDates}
                    title={t('home:people:customers')}
                    selectedTimeRange={selectedCustomersTimeRange}
                    setSelectedTimeRange={setSelectedCustomersTimeRange}
                >
                    <HomeBox title={t('home:people:trendOverTime')}>
                        <StyledBarChartWrapper>
                            <div className="labelWrapper">
                                <span>{t('home:people:accountsCreated')}</span>
                            </div>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    width={100}
                                    height={100}
                                    data={getHomeDataByTimeRange(
                                        (customersData?.data.customers as HomeRecordSet) ?? [],
                                        selectedCustomersTimeRange,
                                        selectedCustomersDates
                                    )}
                                >
                                    <CartesianGrid vertical={false} strokeDasharray="3 3" />
                                    <XAxis axisLine={false} tickLine={false} dataKey="name" />
                                    <YAxis axisLine={false} tickLine={false} />
                                    <Tooltip cursor={{fill: 'transparent'}} />
                                    <Bar
                                        barSize={30}
                                        radius={[5, 5, 0, 0]}
                                        dataKey="count"
                                        fill={theme.palette.primary[600]}
                                    />
                                </BarChart>
                            </ResponsiveContainer>
                        </StyledBarChartWrapper>
                    </HomeBox>
                    <StyledCardsWrapper>
                        <HomeBox title={t('home:people:totalCustomers')}>
                            <StyledLegend $isPositive={isPositiveCustomersGrowthSinceLastMonth}>
                                <span className="title">
                                    {formaThousandsNumber(customersData?.data.customerData.total ?? 0)}
                                </span>
                                <div className="description">
                                    <div>
                                        {isPositiveCustomersGrowthSinceLastMonth ? (
                                            <ArrowUpIcon size={12} />
                                        ) : (
                                            <ArrowDownIcon size={12} />
                                        )}
                                        {customersData?.data.customerData.customersGrowthSinceLastMonth}
                                    </div>
                                    <div>{t('home:people:vsLastMonth')}</div>
                                </div>
                            </StyledLegend>
                        </HomeBox>
                    </StyledCardsWrapper>
                </HomeSection>
            </Box>
        </StyledMainWrapper>
    )
}

export default HomeCustomers
