import styled, {css, DefaultTheme} from 'styled-components'

export const StyledMainContainer = styled.div(
    ({theme: {spacing}}: {theme: DefaultTheme}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 4}px;
    `
)

export const StyledBreadcrumbsWrapper = styled.div(
    ({theme: {spacing}}: {theme: DefaultTheme}) => css`
        padding: 0 ${spacing * 8}px;
        height: 80px;
        display: flex;
        align-items: center;
    `
)

export const StyledContent = styled.div`
    height: calc(100vh - 100px);
    overflow: auto;
`
