import {z} from 'zod'
import {Plan} from '../types'
import axios from '@/axiosInstance'

/**
 * httpGetPlans
 * Here we will fetch all the plans data
 */

// Response
export const HttpPlansResponse = z.array(Plan)
export type HttpPlansResponse = z.infer<typeof HttpPlansResponse>

// Http request
export const httpGetPlans = async (): Promise<HttpPlansResponse> => {
    const response = await axios.get('/~/plans')
    return HttpPlansResponse.parse(response.data)
}
