import {Link, RouteObject, generatePath, matchPath, useLocation, useMatches} from 'react-router-dom'
import {StyledBreadcrumbs} from '@components/commons/bread-crumbs/style.ts'
import {ChevronRightIcon, HomeLineIcon} from '@components/ui/icon/Icon.tsx'
import {Fragment} from 'react'
import {routes} from '@utilities/constants/routes.tsx'
import {useTranslation} from 'react-i18next'
import {RestaurantDetailsBreadcrumb} from './RestaurantDetailsBreadcrumb'
import {CustomerDetailsBreadcrumb} from './CustomerDetailsBreadcrumb'
import {AccountDetailsBreadcrumb} from './AccountDetailsBreadcrumb'
import {HOME_PATHS} from '@/features/home/components/home-tabs/HomeTabs'

export type HandleRouterObj = {
    path: string
    paramName?: string
    name: string
}

type Crumb = {
    item: HandleRouterObj
    match: RouteObject
}
export const BreadCrumbs = () => {
    const {t} = useTranslation()
    const location = useLocation()
    const matches: RouteObject[] = useMatches()
    const crumbs = matches
        // first get rid of any matches that don't have handle and crumb
        .filter(match => Boolean(match.handle))
        // now map them into an array of elements, passing the loader
        // data to each one
        .map((match): Crumb => ({item: match.handle, match: match}))

    if (matchPath(routes.RESTAURANT_DETAILS.path, location.pathname)) {
        return <RestaurantDetailsBreadcrumb />
    } else if (matchPath(routes.CUSTOMER_DETAILS.path, location.pathname)) {
        return <CustomerDetailsBreadcrumb />
    } else if (matchPath(routes.ACCOUNT_DETAILS.path, location.pathname)) {
        return <AccountDetailsBreadcrumb />
    }

    return (
        <StyledBreadcrumbs>
            <Link to={generatePath(routes.HOME.path, {tab: HOME_PATHS.restaurants})}>
                <HomeLineIcon data-color size={20} />
            </Link>
            <ChevronRightIcon data-color size={16} />
            {crumbs.map(({item, match}, index) => {
                const path = !item?.paramName
                    ? item.path
                    : // @ts-expect-error [TS2339] Necessary because params is not mapped in the RouteObject Type
                      item.path.replace(`:${item.paramName}`, match?.params[item.paramName])
                // @ts-expect-error [TS2339] Necessary because params is not mapped in the RouteObject Type
                const name = item?.name ? t(item.name) : match.params[item.paramName]

                return (
                    <Fragment key={item.name}>
                        <Link className={index === crumbs.length - 1 ? 'active' : ''} to={path}>
                            {name}
                        </Link>

                        {index < crumbs.length - 1 && (
                            <span>
                                <ChevronRightIcon data-color size={16} />
                            </span>
                        )}
                    </Fragment>
                )
            })}
        </StyledBreadcrumbs>
    )
}

BreadCrumbs.displayName = 'BreadCrumbs'
