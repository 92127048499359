import {FC, ReactNode} from 'react'
import {StyledBox} from './style'

interface BoxProps {
    children: ReactNode
    className?: string
}
export const Box: FC<BoxProps> = ({children, className}) => {
    return <StyledBox className={className}>{children}</StyledBox>
}

Box.displayName = 'Box'
