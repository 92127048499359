import styled, {css, DefaultTheme} from 'styled-components'

export const StyledMainContainer = styled.div(
    () => css`
        display: flex;
        flex-direction: column;
    `
)

export const StyledBreadcrumbsWrapper = styled.div(
    ({theme: {spacing, palette}}: {theme: DefaultTheme}) => css`
        padding: 0 ${spacing * 8}px;
        height: 80px;
        display: flex;
        align-items: center;
        background-color: ${palette.neutral[50]};
    `
)

export const StyledHeader = styled.div(
    ({theme: {spacing, palette}}: {theme: DefaultTheme}) => css`
        padding: ${spacing * 2}px ${spacing * 8}px ${spacing * 7}px ${spacing * 8}px;
        height: 80px;
        background-color: ${palette.neutral[50]};
    `
)

export const StyledTitleWrapper = styled.div(
    ({theme: {spacing}}: {theme: DefaultTheme}) => css`
        display: grid;
        grid-template-columns: 1fr auto;
        gap: ${spacing * 4}px;
        & > div:last-child {
            display: flex;
            align-items: flex-end;
        }
    `
)

export const StyledTitle = styled.div(
    ({theme: {palette, typography}}: {theme: DefaultTheme}) => css`
        ${typography.textXl}
        color: ${palette.neutral[900]};
        font-weight: 700;
    `
)

export const StyledSubtitle = styled.div(
    ({theme: {typography}}: {theme: DefaultTheme}) => css`
        ${typography.textSm}
    `
)

export const StyledContent = styled.div(
    ({theme: {spacing}}: {theme: DefaultTheme}) => css`
        height: calc(100vh - 170px);
        overflow: auto;
        padding-top: ${spacing * 8}px;
    `
)
