import {LogoutIcon} from '@/components/ui/icon/Icon'
import styled, {css, DefaultTheme} from 'styled-components'

export const StyledMainWrapper = styled.div`
    display: grid;
    grid-template-columns: 280px 1fr;
`

export const StyledSidebar = styled.div(
    ({theme: {palette, spacing}}: {theme: DefaultTheme}) => css`
        background-color: ${palette.primary[950]};
        padding: ${spacing * 8}px ${spacing * 6}px;
        height: 100vh;
        color: ${palette.primary[100]};
        display: flex;
        flex-direction: column;
        gap: ${spacing * 12}px;
        & > a > .logo {
            width: 115px;
            cursor: pointer;
        }
        & > .menu {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: ${spacing * 2}px;
            & > a {
                display: grid;
                grid-template-columns: 40px 1fr;
                align-items: center;
                cursor: pointer;
                padding: ${spacing * 4}px;
                border-radius: 7px;
            }
            & > a.active {
                background-color: ${palette.primary[800]};
                color: ${palette.neutral.white};
                & > svg {
                    stroke: ${palette.neutral.white};
                }
            }
        }
        & > .account {
            border-top: solid 1px ${palette.primary[800]};
            padding-top: ${spacing * 4}px;
        }
    `
)

export const StyledAccountWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 45px 1fr;
        gap: ${spacing * 2}px;
        align-items: center;
    `
)

export const StyledUsernameWrapper = styled.div(
    ({theme: {spacing, palette}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 2}px;
        margin-bottom: ${spacing}px;
        color: ${palette.neutral.white};
    `
)

export const StyledLogout = styled(LogoutIcon)(
    () => css`
        cursor: pointer;
    `
)
