import {typography} from '@/theme/typography'
import styled, {css} from 'styled-components'
import {DefaultTheme} from 'styled-components/dist/types'

export const StyledMainWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 1fr;
        gap: ${spacing * 8}px;
    `}
`

export const StyledSectionTitleWrapper = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: grid;
        align-items: center;
        grid-template-columns: 1fr auto;
        gap: ${spacing * 8}px;
        margin-bottom: ${spacing * 8}px;
        & > .title {
            ${typography.textLg}
            color: ${palette.neutral[900]};
            font-weight: 700;
        }
    `}
`

export const StyledBarChartWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        height: 40vh;
        display: grid;
        grid-template-columns: 15px 1fr;
        gap: ${spacing * 8}px;
        margin-top: ${spacing * 8}px;
        & > .labelWrapper {
            height: 100%;
            display: flex;
            align-items: center;
            & > span {
                margin-left: -50px;
                transform: rotate(-90deg);
                white-space: nowrap;
                display: block;
            }
        }
    `}
`

export const StyledCardsWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(3, 300px);
        gap: ${spacing * 4}px;
        margin-top: ${spacing * 4}px;
    `}
`

type StyledLegendProps = {
    theme: DefaultTheme
    $isPositive: boolean
}

export const StyledLegend = styled.div<StyledLegendProps>`
    ${({theme: {spacing, typography, palette}, $isPositive}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
        & > .title {
            ${typography.textLg}
            font-weight: 700;
            color: ${palette.neutral[700]};
            text-align: center;
        }
        & > .description {
            display: flex;
            gap: ${spacing * 2}px;
            justify-content: center;
            & > div:first-child {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: ${spacing}px;
                color: ${$isPositive ? palette.success[500] : palette.danger[500]};
            }
            & > div:last-child {
                ${typography.textSm}
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    `}
`
