import axios from '@/axiosInstance'
import {z} from 'zod'
import {PaginationResponse} from '@/types/commons'
import {CustomerListItem} from '../types'
import {CustomersSearchParams} from '@/features/customer/types'

/**
 * httpGetCustomers
 * Here we will fetch all the customers data for the dash table
 */

// Params schema
export const HttpCustomersParams = z.object({
    ...CustomersSearchParams.shape,
    page: z.number().nullable(),
    limit: z.number().nullish()
})
export type HttpCustomersParams = z.infer<typeof HttpCustomersParams>

// Response
export const HttpCustomersResponse = z.object({
    data: z.array(CustomerListItem),
    paginateMetadata: PaginationResponse
})
export type HttpCustomersResponse = z.infer<typeof HttpCustomersResponse>

// Http request
export const httpGetCustomers = async (params: HttpCustomersParams): Promise<HttpCustomersResponse> => {
    const response = await axios.get('-/customers', {params})
    return HttpCustomersResponse.parse(response.data)
}

/**
 * httpGetCustomerDetails
 * Here we will get Customer details data
 */

// Params
export const HttpGetCustomerDetailsParams = z.object({
    customerId: z.string()
})
export type HttpGetCustomerDetailsParams = z.infer<typeof HttpGetCustomerDetailsParams>

// Response
export const HttpGetCustomerDetailsResponse = z.object({
    customer: z.object({
        email: z.string(),
        mobile: z.string().nullable(),
        idApple: z.string().nullable(),
        idGoogle: z.string().nullable(),
        idStripe: z.string().nullable(),
        language: z.string(),
        givenName: z.string(),
        urlAvatar: z.string().nullable(),
        dateSignup: z.string(),
        familyName: z.string(),
        idCustomer: z.string(),
        idFacebook: z.string().nullable(),
        displayName: z.string().nullable(),
        dateCreation: z.string()
    })
})
export type HttpGetCustomerDetailsResponse = z.infer<typeof HttpGetCustomerDetailsResponse>

// Http request
export const httpGetCustomerDetails = async (
    params: HttpGetCustomerDetailsParams
): Promise<HttpGetCustomerDetailsResponse> => {
    const response = await axios.get(`/-/customers/${params.customerId}`)
    return HttpGetCustomerDetailsResponse.parse(response.data)
}

/**
 * httpCreateCustomer
 * Here we will create a new customer instance
 */
export const HttpCustomerBodyRequest = z.object({
    idCustomer: z.string().optional(),
    email: z.string(),
    mobile: z.string().nullable(),
    language: z.string(),
    givenName: z.string(),
    familyName: z.string(),
    displayName: z.string(),
    urlAvatar: z.string()
})
export type HttpCustomerBodyRequest = z.infer<typeof HttpCustomerBodyRequest>

export const HttpCreateCustomerParams = z.object({
    data: HttpCustomerBodyRequest
})
export type HttpCreateCustomerParams = z.infer<typeof HttpCreateCustomerParams>

// Response
export const HttpCustomerResponse = z.object({
    email: z.string(),
    mobile: z.string().nullable(),
    idApple: z.string().nullable(),
    idGoogle: z.string().nullable(),
    idStripe: z.string().nullable(),
    language: z.string(),
    givenName: z.string(),
    urlAvatar: z.string().nullable(),
    familyName: z.string(),
    idCustomer: z.string(),
    idFacebook: z.string().nullable(),
    displayName: z.string().nullable()
})
export type HttpCustomerResponse = z.infer<typeof HttpCustomerResponse>

export const httpCreateCustomer = async (params: HttpCreateCustomerParams): Promise<HttpCustomerResponse> => {
    const response = await axios.post('/-/customer/signin', {
        ...params.data
    })
    return response.data
}

/**
 * httpUpdateCustomer
 * Here we will update a customer instance
 */
export const HttpUpdateCustomerParams = z.object({
    data: HttpCustomerBodyRequest
})
export type HttpUpdateCustomerParams = z.infer<typeof HttpUpdateCustomerParams>

export const httpUpdateCustomer = async (params: HttpUpdateCustomerParams) => {
    const response = await axios.post('/-/customer/signin', {...params.data})
    return HttpCustomerResponse.parse(response.data)
}

/**
 * httpDeleteCustomer
 * Here we will delete a customer instance
 */

export const httpDeleteCustomer = async (id: string): Promise<void> => {
    await axios.delete(`/-/customers/${id}`)
}
