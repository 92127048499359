import {Box} from '@/components/commons/box/Box'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
    StyledDishesActionsWrapper,
    StyledMenuInfoWrapper,
    StyledMenuMainWrapper,
    StyledMenuPriceWrapper,
    StyledMainWrapper,
    StyledMenuListWrapper,
    StyledMenuListItem,
    StyledPagesActionsWrapper,
    StyledPagesListWrapper,
    StyledPagesListItem
} from './style'
import {Button} from '@/components/ui/button/Button'
import {PlusIcon, Trash01Icon} from '@/components/ui/icon/Icon'
import {Checkbox} from '@/components/commons/checkbox/CheckBox'
import {List} from '@/components/commons/list/list/List'
import {ListItem} from '@/components/commons/list/list-item/ListItem'
import {formatCurrency} from '@/utilities/helpers'
import {useSelectableList} from '@/hooks/useSelectableList'
import {Chip} from '@/components/ui/chip/Chip'
import {DeleteModal} from '@/components/commons/delete-modal/DeleteModal'
import {EmptyList} from '@/components/commons/empty-list/EmptyList'
import {DishItem, Menu, PeriodItem} from '@/features/restaurant/types'
import {useSearchParams} from 'react-router-dom'
import MenuPageModal from '../menu-form/MenuPageModal'
import {useDeleteMenuPage} from '../../services/queries/useDeleteMenuPage'
import {useDeleteMenuPageItems} from '../../services/queries/useDeleteMenuPageItems'
import MenuPageItemsModal from '../menu-form/MenuPageItemsModal'

type MenusListProps = {
    menus: Menu[]
    periods: PeriodItem[]
    dishes: DishItem[]
    restaurantId: string
}

export const MenusList: React.FC<MenusListProps> = ({menus, periods, restaurantId, dishes}) => {
    const {t} = useTranslation()
    const [searchParams] = useSearchParams()
    const [selectedMenu, setSelectedMenu] = useState(0)
    const [selectedPage, setSelectedPage] = useState(0)
    const {items, setItems, onSelectItem, onSelectAll, isAllSelected, isNoneSelected} = useSelectableList([])
    const [isDeleteDishesModalVisible, setIsDeleteDishesModalVisible] = useState(false)
    const [isMenuPageModalOpen, setIsMenuPageModalOpen] = useState(false)
    const [isMenuPageItemsModalOpen, setIsMenuPageItemsModalOpen] = useState(false)
    const {mutate: deletePage, isPending: isPendingDeletePage} = useDeleteMenuPage({idRestaurant: `${restaurantId}`})
    const {mutate: deletePageItems, isPending: isPendingDeletePageItems} = useDeleteMenuPageItems({
        idRestaurant: `${restaurantId}`
    })
    const [toDeletePageId, setToDeletePageId] = useState<string | null>(null)

    useEffect(() => {
        if (menus) {
            setItems(
                menus?.[selectedMenu]?.menuPages?.[selectedPage]?.menuPageItems?.map((_, index) => ({
                    index,
                    selected: false
                })) ?? []
            )
        }
    }, [menus, selectedMenu, selectedPage])

    useEffect(() => {
        if (menus.length && searchParams.get('idMenu'))
            setSelectedMenu(menus.findIndex(item => item.idMenu === searchParams.get('idMenu')))
    }, [menus.length, searchParams])

    const onCloseDeletePageModal = () => {
        setToDeletePageId(null)
    }

    const onSubmitDeletePageModal = () => {
        deletePage([toDeletePageId ?? ''])
        setToDeletePageId(null)
        setSelectedPage(0)
    }

    const onCloseDeletePageItemsModal = () => {
        setIsDeleteDishesModalVisible(false)
    }

    const onSubmitDeletePageItemsModal = () => {
        setIsDeleteDishesModalVisible(false)
        deletePageItems(
            items
                .filter(item => item.selected)
                .map(
                    item =>
                        menus?.[selectedMenu]?.menuPages?.[selectedPage]?.menuPageItems?.[item.index].idMenuPageItem ??
                        ''
                )
        )
        setItems(
            menus?.[selectedMenu]?.menuPages?.[selectedPage]?.menuPageItems?.map((_, index) => ({
                index,
                selected: false
            })) ?? []
        )
    }

    return (
        <>
            {menus?.length ? (
                <>
                    <StyledMenuListWrapper>
                        {menus?.map((menu, menuIndex) => (
                            <StyledMenuListItem
                                $isSelected={selectedMenu === menuIndex}
                                key={menuIndex}
                                onClick={() => {
                                    setSelectedMenu(menuIndex)
                                    setSelectedPage(0)
                                }}
                            >
                                {menu.name}
                            </StyledMenuListItem>
                        ))}
                    </StyledMenuListWrapper>
                    <Box>
                        <StyledMainWrapper>
                            <div>
                                <StyledPagesActionsWrapper>
                                    <span>{t('menu:pagesList:label')}</span>
                                    <Button
                                        type="button"
                                        variant="secondary"
                                        onClick={() => setIsMenuPageModalOpen(true)}
                                    >
                                        <PlusIcon />
                                        {t('commons:add')}
                                    </Button>
                                </StyledPagesActionsWrapper>
                                <StyledPagesListWrapper>
                                    {menus?.[selectedMenu]?.menuPages?.map((page, pageIndex) => (
                                        <StyledPagesListItem
                                            $isSelected={selectedPage === pageIndex}
                                            key={pageIndex}
                                            onClick={() => setSelectedPage(pageIndex)}
                                        >
                                            <span>{page.name}</span>
                                            <Chip
                                                variant="default"
                                                id={`${pageIndex}`}
                                                label={
                                                    menus?.[selectedMenu]?.menuPages?.[
                                                        pageIndex
                                                    ]?.menuPageItems?.length.toString() ?? '0'
                                                }
                                            />
                                            <div className="deleteButtonWrapper">
                                                <Button
                                                    type="button"
                                                    variant="transparentDanger"
                                                    onClick={() => setToDeletePageId(page.idMenuPage)}
                                                >
                                                    <Trash01Icon />
                                                </Button>
                                            </div>
                                        </StyledPagesListItem>
                                    ))}
                                </StyledPagesListWrapper>
                            </div>
                            <div>
                                <StyledDishesActionsWrapper>
                                    <span>{t('menu:dishesList:label')}</span>
                                    {menus?.[selectedMenu]?.menuPages?.[selectedPage]?.menuPageItems?.length && (
                                        <>
                                            <Button
                                                type="button"
                                                variant="transparentDanger"
                                                disabled={isNoneSelected}
                                                onClick={() => setIsDeleteDishesModalVisible(true)}
                                            >
                                                <Trash01Icon />
                                                {t('commons:delete')}
                                            </Button>
                                            <Checkbox
                                                disabled={items.length === 0}
                                                checked={isAllSelected}
                                                onChange={onSelectAll}
                                                id="selectAll"
                                                label={t('commons:select_all')}
                                            />
                                        </>
                                    )}
                                    <Button
                                        type="button"
                                        variant="secondary"
                                        onClick={() => setIsMenuPageItemsModalOpen(true)}
                                    >
                                        <PlusIcon />
                                        {t('menu:dishesList:addDish')}
                                    </Button>
                                </StyledDishesActionsWrapper>
                                {menus?.[selectedMenu]?.menuPages?.[selectedPage]?.menuPageItems?.length ? (
                                    <List>
                                        {menus?.[selectedMenu]?.menuPages?.[selectedPage]?.menuPageItems?.map(
                                            (dish, dishIndex) => {
                                                const checked =
                                                    items.find(
                                                        (element, index) => index === dishIndex && element.selected
                                                    ) !== undefined
                                                let period = periods.find(
                                                    period => period.idPeriod === dish.idPeriodOverride
                                                )
                                                if (!period)
                                                    period = periods.find(
                                                        period => period.idPeriod === dish.dish?.idPeriod
                                                    )
                                                return (
                                                    <ListItem key={dish.idDish} isSelected={checked}>
                                                        <Checkbox
                                                            checked={checked}
                                                            onChange={() => onSelectItem(dishIndex)}
                                                            id={`${dish.idDish}`}
                                                        />
                                                        <StyledMenuMainWrapper>
                                                            <StyledMenuInfoWrapper>
                                                                <span className="dish">{dish.dish?.name}</span>
                                                                <span className="period">{period?.name ?? '--'}</span>
                                                                <span className="description">
                                                                    {dish.dish?.description}
                                                                </span>
                                                            </StyledMenuInfoWrapper>
                                                            <StyledMenuPriceWrapper>
                                                                <span className="price">
                                                                    {formatCurrency(
                                                                        dish.priceOverride || dish.dish?.price || 0,
                                                                        'EUR'
                                                                    )}
                                                                </span>
                                                            </StyledMenuPriceWrapper>
                                                        </StyledMenuMainWrapper>
                                                    </ListItem>
                                                )
                                            }
                                        )}
                                    </List>
                                ) : (
                                    <EmptyList />
                                )}
                            </div>
                        </StyledMainWrapper>
                    </Box>
                </>
            ) : (
                <EmptyList />
            )}
            {isDeleteDishesModalVisible && (
                <DeleteModal
                    title={t('commons:delete_items_title')}
                    paragraph={t('commons:delete_items_description')}
                    isLoading={isPendingDeletePageItems}
                    onClose={onCloseDeletePageItemsModal}
                    onDelete={onSubmitDeletePageItemsModal}
                />
            )}
            {toDeletePageId !== null && (
                <DeleteModal
                    title={t('menuPage:deleteTitle')}
                    paragraph={t('menuPage:deleteDescription')}
                    isLoading={isPendingDeletePage}
                    onClose={onCloseDeletePageModal}
                    onDelete={onSubmitDeletePageModal}
                />
            )}
            {isMenuPageModalOpen && (
                <MenuPageModal
                    restaurantId={restaurantId}
                    menuId={menus?.[selectedMenu]?.idMenu ?? ''}
                    onClose={() => setIsMenuPageModalOpen(false)}
                    lastPagePosition={
                        menus?.[selectedMenu]?.menuPages?.reduce(
                            (max, item) => (item.position > max ? item.position : max),
                            menus?.[selectedMenu]?.menuPages?.[0]?.position ?? 0
                        ) ?? 0
                    }
                />
            )}
            {isMenuPageItemsModalOpen && (
                <MenuPageItemsModal
                    restaurantId={restaurantId}
                    menuId={menus?.[selectedMenu]?.idMenu ?? ''}
                    menuPageId={menus?.[selectedMenu]?.menuPages?.[selectedPage]?.idMenuPage ?? ''}
                    periods={periods}
                    dishes={dishes}
                    onClose={() => setIsMenuPageItemsModalOpen(false)}
                    lastPagePosition={
                        menus?.[selectedMenu]?.menuPages?.[selectedPage]?.menuPageItems?.reduce(
                            (max, item) => (item.position > max ? item.position : max),
                            menus?.[selectedMenu]?.menuPages?.[selectedPage]?.menuPageItems?.[0].position ?? 0
                        ) ?? 0
                    }
                />
            )}
        </>
    )
}

export default MenusList
