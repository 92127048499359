import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {DefaultTheme} from 'styled-components/dist/types'

export const StyledTabs = styled(Flexbox)`
    ${({theme: {spacing, palette, shadows}}) => css`
        padding: ${spacing * 4}px;
        background-color: ${palette.neutral.white};
        border-radius: 5px;
        box-shadow: ${shadows.md};
    `}
`

type StyledTabItemProps = {
    theme: DefaultTheme
    $isActive: boolean
}

export const StyledTabItem = styled.div<StyledTabItemProps>`
    ${({theme: {spacing, palette, typography}, $isActive}) => css`
        display: grid;
        grid-template-columns: 20px 1fr;
        gap: ${spacing * 2}px;
        align-items: center;
        background-color: ${$isActive ? palette.primary[25] : 'transparent'};
        padding: ${spacing * 4}px;
        border-radius: 5px;
        color: ${$isActive ? palette.neutral.black : palette.neutral[700]};
        cursor: pointer;
        & > .number {
            ${typography.textSm}
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${$isActive ? palette.primary[700] : palette.neutral[200]};
            color: ${$isActive ? palette.neutral.white : palette.neutral[700]};
            width: 20px;
            height: 20px;
            border-radius: 20px;
        }
    `}
`
