import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpDeleteAccount} from '../account.http'
import toast from 'react-hot-toast'
import {useTranslation} from 'react-i18next'

export const useDeleteAccount = ({onSuccess}: {onSuccess: () => void}) => {
    const client = useQueryClient()
    const {t} = useTranslation()

    return useMutation({
        mutationKey: [MUTATION_KEYS.DELETE_ACCOUNT],
        mutationFn: (id: string) => httpDeleteAccount(id),
        onSuccess: () => {
            toast.success(t('commons:delete_completed', {entity: t('account:singular')}))
            onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.ACCOUNTS]})
        }
    })
}
