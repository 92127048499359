import {Box} from '@/components/commons/box/Box'
import HomeSection from '../home-section/HomeSection'
import {useTranslation} from 'react-i18next'
import {HomeBox} from '../home-box/HomeBox'
import {
    StyledBarChartWrapper,
    StyledCardsWrapper,
    StyledColumns,
    StyledLegend,
    StyledMainWrapper,
    StyledPieChartLegend,
    StyledPieChartLegendPoint,
    StyledPieChartWrapper
} from './style'
import {ArrowDownIcon, ArrowUpIcon} from '@/components/ui/icon/Icon'
import {formaThousandsNumber} from '@/utilities/helpers'
import {useMemo, useState} from 'react'
import {TimeRange} from '@/utilities/constants/common'
import {HomeRecordSet, getHomeDataByTimeRange} from '../../utils'
import {useTheme} from 'styled-components'
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell} from 'recharts'
import {useGetHomePeopleData} from '../../services/queries/useGetHomePeopleData'
import dayjs from 'dayjs'

export const HomeAccounts = () => {
    const {t} = useTranslation()
    const [selectedPeopleDates, setSelectedPeopleDates] = useState<Date[] | null>(null)
    const [selectedPeopleTimeRange, setSelectedPeopleTimeRange] = useState<TimeRange>(TimeRange.All)
    const theme = useTheme()
    const {data: peopleData} = useGetHomePeopleData({
        startDate: selectedPeopleDates?.[0] ? dayjs(selectedPeopleDates[0]).format('YYYY-MM-DD hh:mm:ss') : undefined,
        endDate: selectedPeopleDates?.[1] ? dayjs(selectedPeopleDates[1]).format('YYYY-MM-DD hh:mm:ss') : undefined
    })

    const isPositiveAccountsGrowthSinceLastMonth = useMemo(
        () => parseFloat(peopleData?.data.accountData.accountsGrowthSinceLastMonth ?? '0') >= 0,
        [peopleData?.data]
    )

    const PLAN_COLORS = [
        theme.palette.success[600],
        theme.palette.success[500],
        theme.palette.success[400],
        theme.palette.success[300],
        theme.palette.success[200],
        theme.palette.success[100]
    ]

    return (
        <StyledMainWrapper>
            <Box>
                <HomeSection
                    selectedDates={selectedPeopleDates}
                    setSelectedDates={setSelectedPeopleDates}
                    title={t('home:tabs:accounts')}
                    selectedTimeRange={selectedPeopleTimeRange}
                    setSelectedTimeRange={setSelectedPeopleTimeRange}
                >
                    <HomeBox title={t('home:people:trendOverTime')}>
                        <StyledBarChartWrapper>
                            <div className="labelWrapper">
                                <span>{t('home:people:accountsCreated')}</span>
                            </div>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    width={100}
                                    height={100}
                                    data={getHomeDataByTimeRange(
                                        (peopleData?.data.accounts as HomeRecordSet) ?? [],
                                        selectedPeopleTimeRange,
                                        selectedPeopleDates
                                    )}
                                >
                                    <CartesianGrid vertical={false} strokeDasharray="3 3" />
                                    <XAxis axisLine={false} tickLine={false} dataKey="name" />
                                    <YAxis axisLine={false} tickLine={false} />
                                    <Tooltip cursor={{fill: 'transparent'}} />
                                    <Bar
                                        barSize={30}
                                        radius={[5, 5, 0, 0]}
                                        dataKey="count"
                                        fill={theme.palette.primary[600]}
                                    />
                                </BarChart>
                            </ResponsiveContainer>
                        </StyledBarChartWrapper>
                    </HomeBox>
                    <StyledCardsWrapper>
                        <HomeBox title={t('home:people:totalAccounts')}>
                            <StyledLegend $isPositive={isPositiveAccountsGrowthSinceLastMonth}>
                                <span className="title">
                                    {formaThousandsNumber(peopleData?.data.accountData.total ?? 0)}
                                </span>
                                <div className="description">
                                    <div>
                                        {isPositiveAccountsGrowthSinceLastMonth ? (
                                            <ArrowUpIcon size={12} />
                                        ) : (
                                            <ArrowDownIcon size={12} />
                                        )}
                                        {peopleData?.data.accountData.accountsGrowthSinceLastMonth}
                                    </div>
                                    <div>{t('home:people:vsLastMonth')}</div>
                                </div>
                            </StyledLegend>
                        </HomeBox>
                        <HomeBox title={t('home:people:plan')}>
                            <StyledColumns>
                                <StyledPieChartWrapper>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <PieChart width={400} height={400}>
                                            <Tooltip cursor={{fill: 'transparent'}} />
                                            <Pie
                                                stroke=""
                                                data={
                                                    peopleData?.data.accountData.planCounts.map(item => ({
                                                        ...item,
                                                        name: item.plan
                                                    })) ?? []
                                                }
                                                innerRadius={20}
                                                outerRadius={40}
                                                fill={theme.palette.neutral[100]}
                                                dataKey="count"
                                            >
                                                {peopleData?.data.accountData.planCounts.map((_, index) => (
                                                    <Cell
                                                        key={`cell-${index}`}
                                                        fill={PLAN_COLORS[index % PLAN_COLORS.length]}
                                                    />
                                                ))}
                                            </Pie>
                                        </PieChart>
                                    </ResponsiveContainer>
                                </StyledPieChartWrapper>
                                <StyledPieChartLegend>
                                    {peopleData?.data.accountData.planCounts.map((item, index) => (
                                        <div className="legendItem" key={item.plan}>
                                            <StyledPieChartLegendPoint
                                                $color={PLAN_COLORS[index % PLAN_COLORS.length]}
                                            />
                                            <span>{item.plan}</span>
                                        </div>
                                    ))}
                                </StyledPieChartLegend>
                            </StyledColumns>
                        </HomeBox>
                    </StyledCardsWrapper>
                </HomeSection>
            </Box>
        </StyledMainWrapper>
    )
}

export default HomeAccounts
