import {z} from 'zod'

export const PERIOD_FORM_MODEL = {
    periods: {
        name: {
            name: 'name',
            label: 'period:addForm:fields:name'
        }
    }
} as const

export const PeriodFormSchema = z.object({
    periods: z.array(
        z.object({
            name: z.string().min(1, {message: 'errors:required'})
        })
    )
})

export type PeriodValidationSchema = z.infer<typeof PeriodFormSchema>
