import React from 'react'
import {useTranslation} from 'react-i18next'
import {Modal} from '@/components/ui/modal/Modal'
import {StyledModal, StyledTitle} from './style'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import StaffForm from './StaffForm'

interface StaffModalProps {
    onClose: () => void
    restaurantId: string
    restaurantName: string
}

export const StaffModal: React.FC<StaffModalProps> = ({onClose, restaurantId, restaurantName}) => {
    const {t} = useTranslation()

    return (
        <Modal width={900} closeIconOffset="sm" onClose={onClose}>
            <StyledModal>
                <Flexbox align={'center'}>
                    <StyledTitle>{t('staff:addModal:title')}</StyledTitle>
                </Flexbox>
                <StaffForm onCancel={onClose} restaurantId={restaurantId} restaurantName={restaurantName} />
            </StyledModal>
        </Modal>
    )
}

export default StaffModalProps
