import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpUpdateAccount} from '../account.http'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons'
import {errorHandler} from '@/utilities/genericErrorHandler'

type UseUpdateProjectProps = {
    onSuccess?: () => void
    onError?: () => void
}

export const useUpdateAccount = ({onSuccess, onError}: UseUpdateProjectProps) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.UPDATE_ACCOUNT],
        mutationFn: httpUpdateAccount,
        onSuccess: data => {
            client.invalidateQueries({queryKey: [QUERY_KEYS.ACCOUNT_DETAILS, data.idAccount]})
            client.invalidateQueries({queryKey: [QUERY_KEYS.ACCOUNTS]})
            onSuccess?.()
        },
        onError: (error: AxiosError<ErrorResponseData>) => {
            onError?.()
            errorHandler(error)
        }
    })
}
