import ReactSelect, {ActionMeta, CSSObjectWithLabel, MultiValue, SingleValue} from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {customStyles, StyledContainer} from './style.ts'
import {useTheme} from 'styled-components'
import {Label} from '@components/ui/label/Label.tsx'
import {ReactNode, RefCallback, useCallback, useMemo} from 'react'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {customComponents} from '@components/commons/select/commons.tsx'
import {DefaultNamespace} from 'i18next'
import AsyncCreatableSelect from 'react-select/async-creatable'
import AsyncSelect from 'react-select/async'
import {FilterOptionOption} from 'node_modules/react-select/dist/declarations/src/filters'

export type SelectValue = {
    label: string
    value: string
}

export interface SelectProps {
    className?: string
    name?: string
    options: SelectValue[]
    label?: string | DefaultNamespace
    placeholder?: string | DefaultNamespace
    helpText?: string | DefaultNamespace
    readOnly?: boolean
    defaultValue?: SelectValue | []
    isSearchable?: boolean
    isClearable?: boolean
    isCreatable?: boolean
    isAsync?: boolean
    cacheOptions?: boolean
    defaultOptions?: boolean
    loadOptions?: (inputText?: string) => Promise<SelectValue[]>
    formatCreateLabel?: (value: string) => ReactNode
    addOptionMessage?: string
    maxItems?: number
    isMulti?: boolean
    closeMenuOnSelect?: boolean
    disabled?: boolean
    size?: 'medium' | 'large'
    /**
     * How to use the onChange based on controlled or uncontrolled select:
     *
     * uncontrolled: onChange={(event) => console.log(event)}
     * controlled multi: onChange={(newValue) => {onChange(newValue as SelectValue[])}}
     * controlled single: onChange={(newValue) => {onChange([newValue] as SelectValue[])}}
     */
    onChange?: (
        newValue: SingleValue<SelectValue> | MultiValue<SelectValue> | SelectValue[],
        actionMeta: ActionMeta<SelectValue>
    ) => void
    value?: SelectValue | SelectValue[]
    ref?: RefCallback<unknown>
    errorMessage?: string | DefaultNamespace
    onSearchValueCb?: (searchText: string) => void
    onMenuScrollToBottom?: () => void
    noOptionsMessage?: (params: {inputValue: string}) => ReactNode
}

export const Select = ({
    className,
    name,
    options = [],
    label,
    placeholder,
    helpText,
    defaultValue,
    isSearchable = false,
    isCreatable = false,
    addOptionMessage = '',
    maxItems = 100,
    isMulti = false,
    closeMenuOnSelect = !isMulti,
    disabled,
    onChange,
    errorMessage,
    isAsync,
    cacheOptions,
    defaultOptions,
    loadOptions,
    onSearchValueCb,
    onMenuScrollToBottom,
    noOptionsMessage,
    ...rest
}: SelectProps) => {
    const theme = useTheme()

    // Label for new item creation
    const createLabel = useCallback(
        (value: string) => (
            <span style={{fontSize: 14}}>
                {addOptionMessage}
                <span>{value}</span>
            </span>
        ),
        [addOptionMessage]
    )

    const selectProps = {
        options,
        name,
        closeMenuOnSelect,
        isSearchable,
        isCreatable,
        isMulti,
        cacheOptions,
        loadOptions,
        defaultOptions,
        isDisabled: rest.readOnly || disabled,
        classNamePrefix: isCreatable ? 'creatable_select' : 'select',
        placeholder,
        createLabel,
        disabled,
        maxItems,
        defaultValue,
        onChange,
        noOptionsMessage,
        ...rest
    }

    const selectComponentProps = {
        formatCreateLabel: createLabel,
        components: customComponents,
        ...selectProps,
        menuPortalTarget: document.body,
        styles: {
            ...customStyles({theme, $isError: !!errorMessage}),
            menuPortal: (base: CSSObjectWithLabel) => ({...base, zIndex: 9999})
        }
    }

    const Component = useMemo(() => {
        if (isCreatable) {
            if (isAsync) return AsyncCreatableSelect
            return CreatableSelect
        } else {
            if (isAsync) return AsyncSelect
            return ReactSelect
        }
    }, [isCreatable, isAsync])

    const customFilter = (_: FilterOptionOption<SelectValue>, inputValue: string): boolean => {
        if (onSearchValueCb) {
            inputValue ? onSearchValueCb(inputValue) : onSearchValueCb('')
        }

        return true
    }

    return (
        <StyledContainer className={className}>
            {label && <Label htmlFor={name}>{label}</Label>}
            <Component
                onMenuScrollToBottom={onMenuScrollToBottom}
                filterOption={onSearchValueCb ? customFilter : undefined}
                {...selectComponentProps}
            />
            <InputHelpText helpText={helpText} error={errorMessage} />
        </StyledContainer>
    )
}
