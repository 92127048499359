import {AvatarFallback, AvatarImage} from '@radix-ui/react-avatar'
import {AvatarRoot} from '@components/ui/avatar/style.ts'
import {getInitials} from '@utilities/helpers.ts'

interface AvatarProps {
    name: string
    imageUrl: string
    size?: 'sm' | 'md' | 'lg'
}

export const Avatar = ({name, imageUrl, size = 'md'}: AvatarProps) => (
    <AvatarRoot $size={size}>
        <AvatarImage src={imageUrl} alt={name} />
        <AvatarFallback delayMs={600}>{getInitials(name)}</AvatarFallback>
    </AvatarRoot>
)

Avatar.displayName = 'Avatar'
