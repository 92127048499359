import {z} from 'zod'
import {
    HomeCustomersData,
    HomeCustomersDataSearchParams,
    HomeDishesData,
    HomeDishesDataSearchParams,
    HomeMenusData,
    HomeMenusDataSearchParams,
    HomePeopleData,
    HomePeopleDataSearchParams,
    HomeRestaurantsData,
    HomeRestaurantsDataSearchParams
} from '@/features/home/types'
import axios from '@/axiosInstance'

/**
 * getHomeRestaurantsData
 * Here we will fetch home restaurants data
 */

// Params schema
export const HttpHomeRestaurantsDataParams = z.object({
    ...HomeRestaurantsDataSearchParams.shape
})
export type HttpHomeRestaurantsDataParams = z.infer<typeof HttpHomeRestaurantsDataParams>

// Response
export const HttpHomeRestaurantsDataResponse = z.object({
    data: HomeRestaurantsData
})
export type HttpHomeRestaurantsDataResponse = z.infer<typeof HttpHomeRestaurantsDataResponse>

// Http request
export const httpGetHomeRestaurantsData = async (
    params: HttpHomeRestaurantsDataParams
): Promise<HttpHomeRestaurantsDataResponse> => {
    const response = await axios.get('~/dashboard/eateries', {params})
    return HttpHomeRestaurantsDataResponse.parse(response)
}

/**
 * getHomePeopleData
 * Here we will fetch home people data
 */

// Params schema
export const HttpHomePeopleDataParams = z.object({
    ...HomePeopleDataSearchParams.shape
})
export type HttpHomePeopleDataParams = z.infer<typeof HttpHomePeopleDataParams>

// Response
export const HttpHomePeopleDataResponse = z.object({
    data: HomePeopleData
})
export type HttpHomePeopleDataResponse = z.infer<typeof HttpHomePeopleDataResponse>

// Http request
export const httpGetHomePeopleData = async (params: HttpHomePeopleDataParams): Promise<HttpHomePeopleDataResponse> => {
    const response = await axios.get('~/dashboard/accounts', {params})
    return HttpHomePeopleDataResponse.parse(response)
}

/**
 * getHomeCustomersData
 * Here we will fetch home customers data
 */

// Params schema
export const HttpHomeCustomersDataParams = z.object({
    ...HomeCustomersDataSearchParams.shape
})
export type HttpHomeCustomersDataParams = z.infer<typeof HttpHomeCustomersDataParams>

// Response
export const HttpHomeCustomersDataResponse = z.object({
    data: HomeCustomersData
})
export type HttpHomeCustomersDataResponse = z.infer<typeof HttpHomeCustomersDataResponse>

// Http request
export const httpGetHomeCustomersData = async (
    params: HttpHomeCustomersDataParams
): Promise<HttpHomeCustomersDataResponse> => {
    const response = await axios.get('~/dashboard/customers', {params})
    return HttpHomeCustomersDataResponse.parse(response)
}

/**
 * getHomeMenusData
 * Here we will fetch home menus data
 */

// Params schema
export const HttpHomeMenusDataParams = z.object({
    ...HomeMenusDataSearchParams.shape
})
export type HttpHomeMenusDataParams = z.infer<typeof HttpHomeMenusDataParams>

// Response
export const HttpHomeMenusDataResponse = z.object({
    data: HomeMenusData
})
export type HttpHomeMenusDataResponse = z.infer<typeof HttpHomeMenusDataResponse>

// Http request
export const httpGetHomeMenusData = async (params: HttpHomeMenusDataParams): Promise<HttpHomeMenusDataResponse> => {
    const response = await axios.get('~/dashboard/menus', {params})
    return HttpHomeMenusDataResponse.parse(response)
}

/**
 * getHomeDishesData
 * Here we will fetch home dishes data
 */

// Params schema
export const HttpHomeDishesDataParams = z.object({
    ...HomeDishesDataSearchParams.shape
})
export type HttpHomeDishesDataParams = z.infer<typeof HttpHomeDishesDataParams>

// Response
export const HttpHomeDishesDataResponse = z.object({
    data: HomeDishesData
})
export type HttpHomeDishesDataResponse = z.infer<typeof HttpHomeDishesDataResponse>

// Http request
export const httpGetHomeDishesData = async (params: HttpHomeDishesDataParams): Promise<HttpHomeDishesDataResponse> => {
    const response = await axios.get('~/dashboard/dishes', {params})
    return HttpHomeDishesDataResponse.parse(response)
}
