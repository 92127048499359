import * as pages from '@/pages'

export const routes = {
    LOGIN: {
        path: '/login',
        element: <pages.Login />
    },
    SSO_REDIRECT: {
        path: '/redirect',
        element: <pages.SsoRedirect />
    },
    HOME: {
        path: '/:tab?',
        element: <pages.Home />,
        handle: {
            path: '/:tab?',
            paramName: '',
            name: 'routeNames:home'
        }
    },
    RESTAURANTS: {
        path: '/restaurants',
        element: <pages.Restaurants />,
        handle: {
            path: '/restaurants',
            paramName: '',
            name: 'routeNames:restaurants'
        }
    },
    RESTAURANT_DETAILS: {
        path: '/restaurants/:id/:tab?',
        element: <pages.RestaurantDetails />,
        handle: {
            path: '/restaurants/:id/:tab?',
            paramName: '',
            name: 'routeNames:restaurantDetails'
        }
    },
    CUSTOMERS: {
        path: '/customers',
        element: <pages.Customers />,
        handle: {
            path: '/customers',
            paramName: '',
            name: 'routeNames:customers'
        }
    },
    CUSTOMER_DETAILS: {
        path: '/customers/:id',
        element: <pages.CustomerDetails />,
        handle: {
            path: '/customers/:id',
            paramName: '',
            name: 'routeNames:customerDetails'
        }
    },
    MENUS: {
        path: '/menus',
        element: <pages.Menus />,
        handle: {
            path: '/menus',
            paramName: '',
            name: 'routeNames:menu'
        }
    },
    ACCOUNTS: {
        path: '/accounts',
        element: <pages.Accounts />,
        handle: {
            path: '/accounts',
            paramName: '',
            name: 'routeNames:accounts'
        }
    },
    ACCOUNT_DETAILS: {
        path: '/accounts/:id',
        element: <pages.AccountDetails />,
        handle: {
            path: '/accounts/:id',
            paramName: '',
            name: 'routeNames:accountDetails'
        }
    },
    LOGS: {
        path: '/logs',
        element: <pages.Logs />,
        handle: {
            path: '/logs',
            paramName: '',
            name: 'routeNames:logs'
        }
    },
    HOME_EXTENDED: {
        path: '/home',
        element: <pages.Home />,
        handle: {
            path: '/home',
            paramName: '',
            name: 'routeNames:home'
        }
    },
    USERS: {
        path: '/users',
        element: <pages.Users />,
        handle: {
            path: '/users',
            paramName: '',
            name: 'routeNames:users'
        }
    },
    USER_DETAILS: {
        path: `:id/settings`,
        element: <pages.User />,
        handle: {
            path: `/user/:id/settings`,
            paramName: 'id',
            name: ''
        }
    },
    USERS_PROGRAM_MANAGER: {
        path: 'program-managers',
        element: <h1> Program managers table </h1>
    },
    USERS_CLIENT_ENGINEER: {
        path: 'client-engineer',
        element: <h1> Client engineer table </h1>
    },
    USERS_VENDOR_ADMIN: {
        path: 'vendor-admin',
        element: <h1> Vendor admin table </h1>
    },
    USERS_VENDOR_MANAGER: {
        path: 'vendor-manager',
        element: <h1> Vendor Manager table </h1>
    },
    USERS_TEAM_LEAD: {
        path: 'team-lead',
        element: <h1> Team leads table </h1>
    },
    USERS_OPERATOR: {
        path: 'operators',
        element: <h1> Operators table </h1>
    }
}
