import {RefObject, useState} from 'react'
import {debounce} from '@/utilities/helpers'

export const useInputSearch = (defaultSearchValue?: string) => {
    const [searchError, setSearchError] = useState('')
    const [searchValue, setSearchValue] = useState(defaultSearchValue ?? '')

    const searchCallback = (value: string) => {
        if (value?.length >= 1) {
            setSearchValue(value)
            setSearchError('')
        } else if (!value?.length) {
            setSearchValue('')
            setSearchError('')
        } else {
            setSearchError('errors:search_invalid_length')
        }
    }

    const onSearch = debounce((value: string) => {
        searchCallback(value)
    }, 250)

    const onResetSearch = (searchRef: RefObject<HTMLInputElement | null>) => {
        if (searchRef.current) {
            searchRef.current.value = ''
        }
        setSearchValue('')
    }

    return {
        searchValue,
        searchError,
        onSearch,
        onResetSearch
    }
}
