import {number, z} from 'zod'
import axios from '@/axiosInstance'
import {Menu, MenuPage} from '@/features/restaurant/types'

/**
 * httpCreateMenu
 * Here we will create a new menu instance
 */

export const HttpGetMenuDetailsResponse = Menu
export type HttpGetMenuDetailsResponse = z.infer<typeof HttpGetMenuDetailsResponse>

export const HttpMenuBodyRequest = z.array(
    z.object({
        name: z.string(),
        type: z.string(),
        menuPages: z.array(
            z.object({
                position: z.number(),
                name: z.string(),
                menuPageItems: z.array(
                    z.object({
                        idDish: z.string(),
                        position: z.number(),
                        priceOverride: z.number(),
                        idPeriodOverride: z.string()
                    })
                )
            })
        )
    })
)
export type HttpMenuBodyRequest = z.infer<typeof HttpMenuBodyRequest>

export const HttpCreateMenuParams = z.object({
    restaurantId: z.string(),
    data: HttpMenuBodyRequest
})
export type HttpCreateMenuParams = z.infer<typeof HttpCreateMenuParams>

export const httpCreateMenu = async (params: HttpCreateMenuParams): Promise<HttpGetMenuDetailsResponse> => {
    const response = await axios.post(`/~/eatery/${params.restaurantId}/menu`, params.data)
    return response.data
}

/**
 * httpCreateMenuPage
 * Here we will create a new menu page instance
 */

export const HttpGetMenuPageDetailsResponse = MenuPage
export type HttpGetMenuPageDetailsResponse = z.infer<typeof HttpGetMenuPageDetailsResponse>

export const HttpMenuPageBodyRequest = z.array(
    z.object({
        position: z.number(),
        name: z.string()
    })
)
export type HttpMenuPageBodyRequest = z.infer<typeof HttpMenuPageBodyRequest>

export const HttpCreateMenuPageParams = z.object({
    restaurantId: z.string(),
    menuId: z.string(),
    data: HttpMenuPageBodyRequest
})
export type HttpCreateMenuPageParams = z.infer<typeof HttpCreateMenuPageParams>

export const httpCreateMenuPage = async (params: HttpCreateMenuPageParams): Promise<HttpGetMenuPageDetailsResponse> => {
    const response = await axios.post(`/~/eatery/${params.restaurantId}/menu/${params.menuId}/menu-page`, params.data)
    return response.data
}

/**
 * httpDeleteMenuPages
 * Here we will delete multiple menu pages
 */

export const httpDeleteMenuPages = async (idMenuPage: string[]): Promise<void> => {
    await axios.delete('/~/menu-pages', {params: {idMenuPage}})
}

/**
 * httpDeleteMenuPageItems
 * Here we will delete multiple menu pages items
 */

export const httpDeleteMenuPageItems = async (idMenuPageItem: string[]): Promise<void> => {
    await axios.delete('/~/menu-item', {params: {idMenuPageItem}})
}

/**
 * httpCreateMenuPageItems
 * Here we will create multiple menu page page instances
 */

export const HttpGetMenuPageItemsDetailsResponse = z.array(
    z.object({
        idDish: z.string(),
        position: z.number(),
        priceOverride: number(),
        idPeriodOverride: z.string()
    })
)
export type HttpGetMenuPageItemsDetailsResponse = z.infer<typeof HttpGetMenuPageItemsDetailsResponse>

export const HttpMenuPageItemBodyRequest = z.array(
    z.object({
        idDish: z.string(),
        position: z.number(),
        priceOverride: number(),
        idPeriodOverride: z.string()
    })
)
export type HttpMenuPageItemBodyRequest = z.infer<typeof HttpMenuPageItemBodyRequest>

export const HttpCreateMenuPageItemParams = z.object({
    restaurantId: z.string(),
    menuId: z.string(),
    menuPageId: z.string(),
    data: HttpMenuPageItemBodyRequest
})
export type HttpCreateMenuPageItemParams = z.infer<typeof HttpCreateMenuPageItemParams>

export const httpCreateMenuPageItems = async (
    params: HttpCreateMenuPageItemParams
): Promise<HttpGetMenuPageItemsDetailsResponse> => {
    const response = await axios.post(
        `/~/eatery/${params.restaurantId}/menu/${params.menuId}/menu-pages/${params.menuPageId}/menu-item`,
        params.data
    )
    return response.data
}
