import styled, {css} from 'styled-components'

export const StyledBox = styled.div`
    ${({theme: {spacing, palette}}) => css`
        border-radius: 8px;
        border: solid 1px ${palette.neutral[200]};
        background-color: ${palette.neutral[50]};
        padding: ${spacing * 4}px;
    `}
`
