import {Container} from '@components/ui/container/Container.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'
import {useTranslation} from 'react-i18next'
import {PlusIcon, SearchLgIcon} from '@components/ui/icon/Icon.tsx'
import {toast} from '@/utilities/toast/toast'
import {useTheme} from 'styled-components'
import {useCustomersTable} from '@/features/customer/services/queries/useCustomersTable'
import {useEffect, useRef, useState} from 'react'
import {CustomersTable} from '@/features/customer/components/customers-table/CustomersTable'
import {EmptySearch} from '@/components/commons/empty-search/EmptySearch'
import {CustomerModal} from '@/features/customer/components/customer-form/CustomerModal'
import {InputText} from '@/components/commons/input-text/InputText'
import {FiltersContainer} from '@/components/commons/filters-container/FiltersContainer'
import {ListPageLayout} from '@/layouts/list-page-layout/ListPageLayout'
import {CustomerListItem} from '@/features/customer/types'
import {DeleteModal} from '@/components/commons/delete-modal/DeleteModal'
import {useDeleteCustomer} from '@/features/customer/services/queries/useDeleteCustomer'

export const Customers = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const customersQuery = useCustomersTable()
    const searchRef = useRef<HTMLInputElement | null>(null)
    const [isAddModalOpen, setIsAddModalOpen] = useState(false)
    const [customerToDelete, setCustomerToDelete] = useState<CustomerListItem | null>(null)
    const {
        mutate: deleteMutate,
        isPending: deletePending,
        isError: isErrorDelete,
        error: errorDelete
    } = useDeleteCustomer({
        onSuccess: () => setCustomerToDelete(null)
    })

    useEffect(() => {
        if (customersQuery.isError || isErrorDelete) toast.error(customersQuery.error?.message || errorDelete?.message)
    }, [customersQuery.isError, isErrorDelete])

    const onClickAddCustomer = () => setIsAddModalOpen(true)

    const onCancelAddCustomer = () => setIsAddModalOpen(false)

    const onSubmitCustomerToDelete = () => {
        if (customerToDelete) {
            return deleteMutate(customerToDelete.idCustomer)
        }
    }

    return (
        <ListPageLayout>
            <Container fullWidth>
                <PageHero
                    title={t('customer:title')}
                    ctaComponent={
                        <Button size={'sm'} variant={'primary'} onClick={onClickAddCustomer} style={{display: 'none'}}>
                            <PlusIcon stroke={theme.palette.neutral.white} />
                            {t('customer:new_customer')}
                        </Button>
                    }
                />

                <FiltersContainer>
                    <InputText
                        onChange={e => customersQuery.onSearch(e.currentTarget.value)}
                        defaultValue={customersQuery.searchValue}
                        type={'text'}
                        placeholder={t('commons:search_placeholder')}
                        typeIcon={<SearchLgIcon size={18} />}
                        ref={searchRef}
                        width={200}
                    />
                </FiltersContainer>

                <CustomersTable
                    data={customersQuery.remappedData}
                    isChangingPage={customersQuery.isFetchingNextPage}
                    isError={customersQuery.isError}
                    isLoading={customersQuery.isLoading}
                    onChangePage={customersQuery.fetchNextPage}
                    searchValue={customersQuery.searchValue}
                    onResetSearchCb={() => customersQuery.onResetSearch(searchRef)}
                    emptySearchStateComponent={<EmptySearch title={t('commons:no_results')} icon={<SearchLgIcon />} />}
                    sorter={customersQuery.sorter}
                    onClickAddCustomer={onClickAddCustomer}
                    setCustomerToDelete={setCustomerToDelete}
                />
            </Container>
            {isAddModalOpen && <CustomerModal onClose={onCancelAddCustomer} />}
            {customerToDelete && (
                <DeleteModal
                    title={t('customer:delete:title')}
                    paragraph={t('customer:delete:description')}
                    isLoading={deletePending}
                    onClose={() => setCustomerToDelete(null)}
                    onDelete={() => onSubmitCustomerToDelete()}
                />
            )}
        </ListPageLayout>
    )
}

Customers.displayName = 'Customers'
