import {QUERY_KEYS} from '@/queryClient'
import {UseQueryOptions, useQuery} from '@tanstack/react-query'
import {HttpHomePeopleDataParams, HttpHomePeopleDataResponse, httpGetHomePeopleData} from '../home.http'

export const useGetHomePeopleData = (
    params: HttpHomePeopleDataParams,
    options?: Omit<UseQueryOptions<HttpHomePeopleDataResponse>, 'queryFn' | 'queryKey'>
) => {
    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.HOME_PEOPLE_DATA, params.startDate, params.endDate],
        queryFn: () => httpGetHomePeopleData(params),
        enabled:
            (params.startDate !== undefined && params.endDate !== undefined) ||
            (params.startDate === undefined && params.endDate === undefined)
    })
}
