import {Table, TableSorter} from '@/components/commons/table/Table'
import {FC, ReactElement, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {LogListItem, LogsSearchParams} from '@/features/log/types'
import {TableDataCell} from '@/components/commons/table/table-data-cell/TableDataCell'
import 'dayjs/locale/en'
import {EmptyResults} from '@/components/commons/empty-results/EmptyResults'
import {EmptySearch} from '@/components/commons/empty-search/EmptySearch'
import {SearchMdIcon} from '@/components/ui/icon/Icon'
import {formatLocaleDate} from '@/utilities/helpers'

interface LogsTableProps {
    data: LogListItem[]
    isChangingPage: boolean
    isError: boolean
    isLoading: boolean
    searchValue?: string
    emptySearchStateComponent?: ReactElement
    sorter?: TableSorter<LogsSearchParams['orderBy']> | undefined
    onChangePage?: () => void
    onResetSearchCb?: () => void
}

export const LogsTable: FC<LogsTableProps> = ({
    data,
    isLoading,
    sorter,
    searchValue,
    onChangePage,
    onResetSearchCb
}) => {
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 960, [width])

    return (
        <Table
            onChangePage={onChangePage}
            isMobile={isMobile}
            data={data}
            isLoading={isLoading}
            sorter={sorter}
            searchValue={searchValue}
            columns={[
                {
                    label: t('log:table:labels:id'),
                    width: '10%',
                    cellRender: log => <TableDataCell size="sm">{log.idLog}</TableDataCell>
                },
                {
                    label: t('log:table:labels:date'),
                    width: '15%',
                    sortName: 'timestamp',
                    cellRender: log => (
                        <TableDataCell size="sm">{formatLocaleDate(log.timestamp, 'll - hh:mm')}</TableDataCell>
                    )
                },
                {
                    label: t('log:table:labels:user'),
                    width: '15%',
                    sortName: 'email',
                    cellRender: log => <TableDataCell size="sm">{log.email}</TableDataCell>
                },
                {
                    label: t('log:table:labels:log'),
                    width: '50%',
                    cellRender: log => <TableDataCell size="sm">{`${log.method} ${log.resource}`}</TableDataCell>
                }
            ]}
            emptyStateComponent={
                <EmptyResults title={t('log:table:empty:title')} subtitle={t('log:table:empty:subtitle')} />
            }
            emptySearchStateComponent={
                <EmptySearch
                    title={t('commons:no_results')}
                    onClearSearch={() => onResetSearchCb && onResetSearchCb()}
                    icon={<SearchMdIcon />}
                />
            }
        />
    )
}
