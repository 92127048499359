import {SelectValue} from '@/components/commons/select/Select'
import {z} from 'zod'

export type ErrorResponseData = {
    message: string
}

export const PaginationResponse = z.object({
    total: z.number(),
    last_page: z.number(),
    per_page: z.number(),
    current_page: z.number()
})
export type PaginationResponse = z.infer<typeof PaginationResponse>

export type SerializedSearchParam = {
    [key: string]: string[] | null
}

export type CallbackFunction = (options: SelectValue[]) => void
