import {Dispatch, FC, ReactNode, SetStateAction} from 'react'
import {ButtonGroup, StyledButtonsWrapper, StyledCustomDateWrapper, StyledSectionTitleWrapper} from './style'
import {Button} from '@/components/ui/button/Button'
import {useTranslation} from 'react-i18next'
import dayjs from 'dayjs'
import {DatePicker} from '@/components/commons/date-picker/DatePicker'
import {CalendarIcon} from '@/components/ui/icon/Icon'
import {useTheme} from 'styled-components'
import {TimeRange} from '@/utilities/constants/common'

type HomeSectionProps = {
    title: string
    children: ReactNode
    selectedDates: Date[] | null
    setSelectedDates: Dispatch<SetStateAction<Date[] | null>>
    selectedTimeRange: TimeRange
    setSelectedTimeRange: Dispatch<SetStateAction<TimeRange>>
}

export const HomeSection: FC<HomeSectionProps> = ({
    title,
    children,
    selectedDates,
    setSelectedDates,
    selectedTimeRange,
    setSelectedTimeRange
}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    return (
        <>
            <StyledSectionTitleWrapper>
                <span className="title">{title}</span>
                <StyledButtonsWrapper>
                    <ButtonGroup>
                        <Button
                            className={selectedTimeRange === TimeRange.Week ? 'active' : ''}
                            onClick={() => {
                                setSelectedTimeRange(TimeRange.Week)
                                setSelectedDates([dayjs().startOf(TimeRange.Week).toDate(), new Date()])
                            }}
                            variant="secondary"
                        >
                            {t('home:section:week')}
                        </Button>
                        <Button
                            className={selectedTimeRange === TimeRange.Month ? 'active' : ''}
                            onClick={() => {
                                setSelectedTimeRange(TimeRange.Month)
                                setSelectedDates([dayjs().startOf(TimeRange.Month).toDate(), new Date()])
                            }}
                            variant="secondary"
                        >
                            {t('home:section:month')}
                        </Button>
                        <Button
                            className={selectedTimeRange === TimeRange.Year ? 'active' : ''}
                            onClick={() => {
                                setSelectedTimeRange(TimeRange.Year)
                                setSelectedDates([dayjs().startOf(TimeRange.Year).toDate(), new Date()])
                            }}
                            variant="secondary"
                        >
                            {t('home:section:year')}
                        </Button>
                        <Button
                            className={selectedTimeRange === TimeRange.Custom ? 'active' : ''}
                            onClick={() => {
                                setSelectedTimeRange(TimeRange.Custom)
                                setSelectedDates(null)
                            }}
                            variant="secondary"
                        >
                            {t('home:section:custom')}
                        </Button>
                    </ButtonGroup>
                    {selectedTimeRange === TimeRange.Custom && (
                        <StyledCustomDateWrapper>
                            <DatePicker
                                toggle
                                formatDateFn={date => dayjs(date).format('DD/MM/YYYY')}
                                numMonths={2}
                                mode={'range'}
                                selectedDates={selectedDates ?? []}
                                onDatesChange={setSelectedDates}
                                triggerProps={{
                                    placeholder: t('commons:select_dates_range'),
                                    typeIcon: <CalendarIcon stroke={theme.palette.neutral[900]} />
                                }}
                            />
                        </StyledCustomDateWrapper>
                    )}
                    <Button
                        className={selectedTimeRange === TimeRange.All ? 'active' : ''}
                        onClick={() => {
                            setSelectedTimeRange(TimeRange.All)
                            setSelectedDates(null)
                        }}
                        variant="primary"
                    >
                        {t('home:section:all')}
                    </Button>
                </StyledButtonsWrapper>
            </StyledSectionTitleWrapper>
            <div>{children}</div>
        </>
    )
}

export default HomeSection
