import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Box} from '@/components/commons/box/Box'

export const StyledBox = styled(Box)(
    ({theme: {spacing}}) => css`
        margin-top: ${spacing * 4}px;
    `
)

export const StyledBeverageItemContent = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: ${spacing * 2}px;
    `}
`

export const StyledBeveragesActionsWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        margin-top: ${spacing * 4}px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: ${spacing * 4}px;
    `}
`

export const StyledActionsWrapper = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        margin-top: ${spacing * 8}px;
    `}
`

export const StyledModal = styled.div(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 6}px;
    `
)

export const StyledTitle = styled.span`
    ${({theme: {typography, palette}}) => css`
        ${typography.textLg}
        font-weight: bold;
        color: ${palette.neutral['900']};
        display: block;
    `}
`

export const StyledImageUploaderWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        margin-top: ${spacing}px;
    `}
`
