export const phoneRegex = new RegExp(/^([+]\d{2})?\d{10}$/)

export const CURRENCIES = [
    {
        value: 'EUR',
        label: 'currencies:eur'
    },
    {
        value: 'USD',
        label: 'currencies:usd'
    },
    {
        value: 'CHF',
        label: 'currencies:chf'
    }
]

export const LANGUAGES = [
    {
        value: 'EN',
        label: 'languages:en'
    },
    {
        value: 'IT',
        label: 'languages:it'
    },
    {
        value: 'DE',
        label: 'languages:de'
    },
    {
        value: 'FR',
        label: 'languages:fr'
    },
    {
        value: 'DK',
        label: 'languages:dk'
    },
    {
        value: 'SE',
        label: 'languages:se'
    }
]

export enum TimeRange {
    Week = 'week',
    Month = 'month',
    Year = 'year',
    Custom = 'custom',
    All = 'all'
}
