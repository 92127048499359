import styled, {css} from 'styled-components'

export const StyledEmptySearch = styled('div')(
    ({theme: {spacing}}) => css`
        width: 100%;
        padding: ${spacing * 16}px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        height: max-content;
        & > span {
            padding: ${spacing * 2}px;
        }
    `
)
