import {Box} from '@/components/commons/box/Box'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {StyledActionsWrapper, StyledMainWrapper} from './style'
import {Button} from '@/components/ui/button/Button'
import {Trash01Icon} from '@/components/ui/icon/Icon'
import {Checkbox} from '@/components/commons/checkbox/CheckBox'
import {List} from '@/components/commons/list/list/List'
import {ListItem} from '@/components/commons/list/list-item/ListItem'
import {useSelectableList} from '@/hooks/useSelectableList'
import {PeriodItem} from '@/features/restaurant/types'
import {DeleteModal} from '@/components/commons/delete-modal/DeleteModal'
import {useDeletePeriods} from '../../services/queries/useDeletePeriods'
import {useParams} from 'react-router-dom'
import {EmptyList} from '@/components/commons/empty-list/EmptyList'

type PeriodsListProps = {
    periods: PeriodItem[]
}

export const PeriodsList: React.FC<PeriodsListProps> = ({periods}) => {
    const {t} = useTranslation()
    const {items, setItems, onSelectItem, onSelectAll, isAllSelected, isNoneSelected} = useSelectableList([], [0])
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const {id: idRestaurant} = useParams()
    const {mutate: deletePeriods} = useDeletePeriods({idRestaurant: `${idRestaurant}`})

    useEffect(() => {
        if (periods && periods?.length) {
            setItems(
                periods?.map((_, index) => ({
                    index,
                    selected: false
                }))
            )
        }
    }, [periods?.length])

    const onCloseDeleteModal = () => {
        setIsDeleteModalVisible(false)
    }

    const onSubmitDeleteModal = () => {
        setIsDeleteModalVisible(false)
        deletePeriods(items.filter(item => item.selected).map(item => periods[item.index].idPeriod))
    }

    return (
        <>
            <Box>
                <StyledMainWrapper>
                    {periods.length ? (
                        <>
                            <StyledActionsWrapper>
                                <Button
                                    type="button"
                                    variant="transparentDanger"
                                    disabled={isNoneSelected}
                                    onClick={() => setIsDeleteModalVisible(true)}
                                >
                                    <Trash01Icon />
                                    {t('commons:delete')}
                                </Button>
                                <Checkbox
                                    disabled={items.length === 0}
                                    checked={isAllSelected}
                                    onChange={onSelectAll}
                                    id="selectAll"
                                    label={t('commons:select_all')}
                                />
                            </StyledActionsWrapper>
                            <List>
                                {periods?.map((item, itemIndex) => {
                                    const checked =
                                        items.find((element, index) => index === itemIndex && element.selected) !==
                                        undefined
                                    return (
                                        <ListItem key={item.idPeriod} isSelected={checked}>
                                            <Checkbox
                                                disabled={item.period === 0}
                                                label={item.name}
                                                checked={checked}
                                                onChange={() => onSelectItem(itemIndex)}
                                                id={`${item.idPeriod}`}
                                            />
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </>
                    ) : (
                        <EmptyList />
                    )}
                </StyledMainWrapper>
            </Box>
            {isDeleteModalVisible && (
                <DeleteModal
                    title={t('commons:delete_items_title')}
                    paragraph={t('commons:delete_items_description')}
                    isLoading={false}
                    onClose={onCloseDeleteModal}
                    onDelete={onSubmitDeleteModal}
                />
            )}
        </>
    )
}

export default PeriodsList
