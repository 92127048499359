import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {RestaurantsSearchParams} from '@/features/restaurant/types'
import {useRestaurantsQuery} from './useRestaurants'
import {useEffect, useState} from 'react'
import {useInputSearch} from '@/hooks/useInputSearch'
import dayjs from 'dayjs'

export const useRestaurantsTable = () => {
    const multiParams = [] as const satisfies ReadonlyArray<keyof RestaurantsSearchParams>
    const {searchParams, setSearchParams} = useParsedSearchParams(multiParams, RestaurantsSearchParams)
    const {searchValue, searchError, onSearch, onResetSearch} = useInputSearch(searchParams.search)
    const [licenseExpireValue, setLicenseExpireValue] = useState<Date | undefined>(undefined)
    const restaurantsQuery = useRestaurantsQuery({
        ...searchParams,
        limit: 20
    })

    useEffect(() => {
        setSearchParams({search: searchValue})
    }, [searchValue])

    useEffect(() => {
        const computedValue = licenseExpireValue ? dayjs(licenseExpireValue).format('YYYY-MM-DD') : undefined
        setSearchParams({licenseExpire: computedValue})
    }, [licenseExpireValue])

    useEffect(() => {
        const computedValue = searchParams.licenseExpire ? dayjs(searchParams.licenseExpire).toDate() : undefined
        setLicenseExpireValue(computedValue)
    }, [])

    const sort = (sorter: {
        orderBy: RestaurantsSearchParams['orderBy']
        orderDirection: RestaurantsSearchParams['orderDirection']
    }) => {
        if (sorter.orderDirection) {
            setSearchParams({orderBy: sorter.orderBy, orderDirection: sorter.orderDirection})
        } else {
            setSearchParams({orderBy: undefined, orderDirection: undefined})
        }
    }

    return {
        ...restaurantsQuery,
        licenseExpireValue,
        setLicenseExpireValue,
        onSearch,
        onResetSearch,
        searchValue,
        searchError,
        sorter: {orderBy: searchParams.orderBy, orderDirection: searchParams.orderDirection, onSort: sort}
    }
}
