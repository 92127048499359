import React from 'react'
import {useTranslation} from 'react-i18next'
import {Modal} from '@/components/ui/modal/Modal'
import {StyledModal, StyledTitle} from './style'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import MenuPageItemForm from './MenuPageItemForm'
import {DishItem, PeriodItem} from '@/features/restaurant/types'

interface MenuPageItemsModalProps {
    onClose: () => void
    restaurantId: string
    menuId: string
    menuPageId: string
    lastPagePosition: number
    dishes: DishItem[]
    periods: PeriodItem[]
}

export const MenuPageItemsModal: React.FC<MenuPageItemsModalProps> = ({
    onClose,
    restaurantId,
    menuId,
    menuPageId,
    lastPagePosition,
    dishes,
    periods
}) => {
    const {t} = useTranslation()

    return (
        <Modal width={800} closeIconOffset="sm" onClose={onClose}>
            <StyledModal>
                <Flexbox align={'center'}>
                    <StyledTitle>{t('menu:addPageItemModal:title')}</StyledTitle>
                </Flexbox>
                <MenuPageItemForm
                    restaurantId={restaurantId}
                    menuId={menuId}
                    menuPageId={menuPageId}
                    onCancel={onClose}
                    lastPagePosition={lastPagePosition}
                    dishes={dishes}
                    periods={periods}
                />
            </StyledModal>
        </Modal>
    )
}

export default MenuPageItemsModal
