import {
    StyledBreadcrumbsWrapper,
    StyledContent,
    StyledHeader,
    StyledMainContainer,
    StyledSubtitle,
    StyledTitle,
    StyledTitleWrapper
} from './styles'
import React, {ReactNode} from 'react'
import {BreadCrumbs} from '@components/commons/bread-crumbs/BreadCrumbs'

type DetailsPageLayoutProps = {
    children: ReactNode
    title: string
    subtitle?: string | number
    sideContent?: ReactNode
}

export const DetailsPageLayout: React.FC<DetailsPageLayoutProps> = ({children, title, subtitle, sideContent}) => {
    return (
        <StyledMainContainer>
            <div>
                <StyledBreadcrumbsWrapper>
                    <BreadCrumbs />
                </StyledBreadcrumbsWrapper>
                <StyledHeader>
                    <StyledTitleWrapper>
                        <div>
                            <StyledTitle>{title}</StyledTitle>
                            <StyledSubtitle>{subtitle}</StyledSubtitle>
                        </div>
                        <div>{sideContent}</div>
                    </StyledTitleWrapper>
                </StyledHeader>
            </div>
            <StyledContent>{children}</StyledContent>
        </StyledMainContainer>
    )
}
