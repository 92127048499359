import styled, {css} from 'styled-components'

export const StyledSectionTitle = styled.span`
    ${({theme: {spacing, typography, palette}}) => css`
        ${typography.textLg}
        display: block;
        margin-bottom: ${spacing * 4}px;
        font-weight: 700;
        color: ${palette.neutral[900]};
    `}
`
