import {useTheme} from 'styled-components'
import {StyledEmptyResults} from './style'
import {FC, ReactElement, ReactNode} from 'react'
import {DefaultNamespace} from 'i18next'
import {IconContainer} from '@/components/ui/icon-container/IconContainer'
import {ButtonLink} from '@/components/ui/button-link/ButtonLink'
import {Button} from '@/components/ui/button/Button'
import {FolderIcon} from '@/components/ui/icon/Icon'

type Cta = {
    path: string
    text: DefaultNamespace
    icon?: ReactElement
}

type Btn = {
    onClick: () => void
    text: DefaultNamespace
    icon?: ReactElement
}

interface EmptyResultsProps {
    cta?: Cta
    btn?: Btn
    icon?: ReactNode
    title: DefaultNamespace
    subtitle: DefaultNamespace
    customContent?: ReactNode
    children?: ReactNode
}
export const EmptyResults: FC<EmptyResultsProps> = ({children, cta, btn, title, subtitle, icon, customContent}) => {
    const theme = useTheme()
    return (
        <StyledEmptyResults direction="column" justify="center" align="center">
            <IconContainer size="lg">{icon ?? <FolderIcon fill={theme.palette.neutral['700']} />}</IconContainer>
            <h3>{title}</h3>
            {!!subtitle && <h4>{subtitle}</h4>}
            {customContent}

            {cta && (
                <ButtonLink size="lg" variant="primary" to={cta.path}>
                    {cta?.icon}
                    {cta.text}
                </ButtonLink>
            )}

            {btn && (
                <Button size="lg" variant="primary" onClick={btn.onClick}>
                    {btn?.icon}
                    {btn.text}
                </Button>
            )}

            {children}
        </StyledEmptyResults>
    )
}

EmptyResults.displayName = 'EmptyResults'
