import {Table, TableSorter} from '@/components/commons/table/Table'
import {Dispatch, FC, ReactElement, SetStateAction, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {generatePath, useNavigate} from 'react-router-dom'
import {MenuListItem, MenusSearchParams} from '@/features/menu/types'
import {TableDataCell} from '@/components/commons/table/table-data-cell/TableDataCell'
import 'dayjs/locale/en'
import {EmptyResults} from '@/components/commons/empty-results/EmptyResults'
import {EmptySearch} from '@/components/commons/empty-search/EmptySearch'
import {CopyIcon, DetailsIcon, DotsVerticalIcon, SearchMdIcon, Trash01Icon} from '@/components/ui/icon/Icon'
import {useTheme} from 'styled-components'
import {DropdownMenu} from '@/components/ui/dropdown-menu/DropdownMenu'
import {routes} from '@/utilities/constants/routes'
import {formatLocaleDate} from '@/utilities/helpers'
import {Chip} from '@/components/ui/chip/Chip'

interface MenusTableProps {
    data: MenuListItem[]
    isChangingPage: boolean
    isError: boolean
    isLoading: boolean
    searchValue?: string
    emptySearchStateComponent?: ReactElement
    sorter?: TableSorter<MenusSearchParams['orderBy']> | undefined
    onChangePage?: () => void
    onResetSearchCb?: () => void
    setMenuToDelete: Dispatch<SetStateAction<MenuListItem | null>>
    onDuplicateMenu: (menuId: string) => void
}

export const MenusTable: FC<MenusTableProps> = ({
    data,
    isLoading,
    sorter,
    searchValue,
    onChangePage,
    onResetSearchCb,
    setMenuToDelete,
    onDuplicateMenu
}) => {
    const {t} = useTranslation()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 960, [width])
    const theme = useTheme()
    const navigate = useNavigate()

    return (
        <Table
            onChangePage={onChangePage}
            isMobile={isMobile}
            data={data}
            isLoading={isLoading}
            sorter={sorter}
            searchValue={searchValue}
            columns={[
                {
                    label: t('menu:table:labels:restaurantId'),
                    width: '20%',
                    cellRender: menu => (
                        <TableDataCell isBold={true} size="sm">
                            <p>{menu.idEatery}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('menu:table:labels:id'),
                    width: '20%',
                    cellRender: menu => (
                        <TableDataCell size="sm">
                            <p>{menu.idMenu}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('menu:table:labels:name'),
                    width: '20%',
                    sortName: 'name',
                    cellRender: menu => <TableDataCell size="sm">{menu.name}</TableDataCell>
                },
                {
                    label: t('menu:table:labels:status'),
                    width: '11%',
                    cellRender: menu => {
                        switch (menu.status) {
                            case 'PUBLISHED':
                                return <Chip variant="success" id={menu.idMenu} label={menu.status} />
                            case 'DRAFT':
                                return <Chip variant="default" id={menu.idMenu} label={menu.status} />
                            default:
                                return <Chip variant="warning" id={menu.idMenu} label={menu.status} />
                        }
                    }
                },
                {
                    label: t('menu:table:labels:creationDate'),
                    width: '12%',
                    cellRender: menu => <TableDataCell size="sm">{formatLocaleDate(menu.dateCreation)}</TableDataCell>
                },
                {
                    label: t('menu:table:labels:lastUpdateDate'),
                    width: '12%',
                    cellRender: menu => <TableDataCell size="sm">{formatLocaleDate(menu.lastUpdate)}</TableDataCell>
                },
                {
                    label: ' ',
                    width: '5%',
                    alignment: 'right',
                    cellRender: menu => (
                        <TableDataCell>
                            <DropdownMenu
                                sideOffset={8}
                                trigger={<DotsVerticalIcon />}
                                actions={[
                                    {
                                        component: 'Duplicate',
                                        withSeparator: true,
                                        icon: <CopyIcon stroke={theme.palette.neutral[700]} size={18} />,
                                        onClickCb: () => onDuplicateMenu(menu.idMenu)
                                    },
                                    {
                                        component: 'Details',
                                        withSeparator: true,
                                        icon: <DetailsIcon stroke={theme.palette.neutral[700]} size={18} />,
                                        onClickCb: () =>
                                            navigate(
                                                `${generatePath(routes.RESTAURANT_DETAILS.path, {
                                                    id: menu.idEatery,
                                                    tab: 'menus'
                                                })}?idMenu=${menu.idMenu}`
                                            )
                                    },
                                    {
                                        component: 'Delete',
                                        isDanger: true,
                                        icon: <Trash01Icon stroke={theme.palette.danger[700]} size={16} />,
                                        onClickCb: () => {
                                            setMenuToDelete(menu)
                                        }
                                    }
                                ]}
                            />
                        </TableDataCell>
                    )
                }
            ]}
            emptyStateComponent={
                <EmptyResults title={t('menu:table:empty:title')} subtitle={t('menu:table:empty:subtitle')} />
            }
            emptySearchStateComponent={
                <EmptySearch
                    title={t('commons:no_results')}
                    onClearSearch={() => onResetSearchCb && onResetSearchCb()}
                    icon={<SearchMdIcon />}
                />
            }
        />
    )
}
