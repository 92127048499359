import {PageHero} from '@/components/commons/page-hero/PageHero'
import {Button} from '@/components/ui/button/Button'
import RestaurantDetailsTabs, {
    RESTAURANT_DETAILS_PATHS
} from '@/features/restaurant/components/restaurant-details-tabs/RestaurantDetailsTabs'
import RestaurantForm from '@/features/restaurant/components/restaurant-form/RestaurantForm'
import {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {StyledBreadcrumbsWrapper, StyledContent, StyledHeader, StyledMainContainer, StyledTabsWrapper} from './style'
import {PlusIcon} from '@/components/ui/icon/Icon'
import {useTheme} from 'styled-components'
import {PeriodModal} from '@/features/restaurant-periods/components/period-form/PeriodModal'
import {PeriodsList} from '@/features/restaurant-periods/components/periods-list/PeriodsList'
import DishesList from '@/features/restaurant-dishes/components/dishes-list/DishesList'
import {DishModal} from '@/features/restaurant-dishes/components/dish-form/DishModal'
import {RestaurantModal} from '@/features/restaurant/components/restaurant-form/RestaurantModal'
import MenusList from '@/features/restaurant-menus/components/menus-list/MenusList'
import StaffList from '@/features/restaurant-staff/components/staff-list/StaffList'
import {StaffModal} from '@/features/restaurant-staff/components/staff-form/StaffModal'
import {MenuModal} from '@/features/restaurant-menus/components/menu-form/MenuModal'
import {BreadCrumbs} from '@/components/commons/bread-crumbs/BreadCrumbs'
import {useGetRestaurantDetails} from '@/features/restaurant/services/queries/useGetRestaurantDetails'
import {adaptRestaurantToForm} from '@/features/restaurant/utils'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import toast from 'react-hot-toast'
import {usePlansQuery} from '@/features/plan/services/queries/usePlans'
import {HttpUploadDishesFileResponse} from '@/features/restaurant-dishes/services/restaurantDishes.http'
import BeveragesList from '@/features/restaurant-beverages/components/beverages-list/BeveragesList'
import {BeverageModal} from '@/features/restaurant-beverages/components/beverage-form/BeverageModal'

export const RestaurantDetails = () => {
    const {t} = useTranslation()
    const {tab: currentTab} = useParams()
    const theme = useTheme()
    const {id} = useParams()
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
    const [isPeriodsModalOpen, setIsPeriodsModalOpen] = useState(false)
    const [isDishModalOpen, setIsDishModalOpen] = useState(false)
    const [isBeverageModalOpen, setIsBeverageModalOpen] = useState(false)
    const [isStaffModalOpen, setIsStaffModalOpen] = useState(false)
    const [isMenuModalOpen, setIsMenuModalOpen] = useState(false)
    const [uploadedDishes, setUploadedDishes] = useState<HttpUploadDishesFileResponse | null>(null)
    const {data, isLoading, isError, error} = useGetRestaurantDetails({
        restaurantId: `${id}`
    })
    const {data: plans, isLoading: isLoadingPlans} = usePlansQuery()

    useEffect(() => {
        if (isError) toast.error(error?.message)
    }, [isError])

    const onUploadDishes = (dishes: HttpUploadDishesFileResponse) => {
        setIsDishModalOpen(true)
        setUploadedDishes(dishes)
    }

    const onUploadBeverages = (dishes: HttpUploadDishesFileResponse) => {
        setIsBeverageModalOpen(true)
        setUploadedDishes(dishes)
    }

    const tabContent = useMemo(() => {
        if (isLoading || isLoadingPlans) {
            return (
                <Flexbox justify="center">
                    <Spinner color={theme.palette.neutral[400]} size={30} />
                </Flexbox>
            )
        }
        switch (currentTab) {
            case RESTAURANT_DETAILS_PATHS.masterData:
                return (
                    <RestaurantForm
                        readOnly
                        defaultValues={data ? adaptRestaurantToForm(data, plans ?? []) : undefined}
                        restaurantId={id ?? ''}
                    />
                )
            case RESTAURANT_DETAILS_PATHS.periods:
                return <PeriodsList periods={data?.periods ?? []} />
            case RESTAURANT_DETAILS_PATHS.dishes:
                return (
                    <DishesList
                        periods={data?.periods ?? []}
                        dishes={data?.dishes ?? []}
                        onUploadDishes={onUploadDishes}
                    />
                )
            case RESTAURANT_DETAILS_PATHS.beverages:
                return (
                    <BeveragesList
                        beveragePeriodId={data?.periods?.find(item => item.period === 0)?.idPeriod ?? ''}
                        beverages={data?.beverages ?? []}
                        onUploadBeverages={onUploadBeverages}
                    />
                )
            case RESTAURANT_DETAILS_PATHS.menus:
                return (
                    <MenusList
                        dishes={data?.dishes ?? []}
                        restaurantId={id ?? ''}
                        menus={data?.menus ?? []}
                        periods={data?.periods ?? []}
                    />
                )
            case RESTAURANT_DETAILS_PATHS.staff:
                return <StaffList crew={[...(data?.crew ?? []), ...(data?.crewInvitation ?? [])]} />
            default:
                return undefined
        }
    }, [currentTab, isLoading, isLoadingPlans, data])

    const button = useMemo(() => {
        switch (currentTab) {
            case RESTAURANT_DETAILS_PATHS.masterData:
                return (
                    <Button type="button" size="sm" variant="primary" onClick={() => setIsInfoModalOpen(true)}>
                        {t('commons:update_info')}
                    </Button>
                )
            case RESTAURANT_DETAILS_PATHS.periods:
                return (
                    <Button type="button" size="sm" variant="primary" onClick={() => setIsPeriodsModalOpen(true)}>
                        <PlusIcon stroke={theme.palette.neutral.white} />
                        {t('restaurant:ctas:addPeriod')}
                    </Button>
                )
            case RESTAURANT_DETAILS_PATHS.dishes:
                return (
                    <Button type="button" size="sm" variant="primary" onClick={() => setIsDishModalOpen(true)}>
                        <PlusIcon stroke={theme.palette.neutral.white} />
                        {t('restaurant:ctas:addDish')}
                    </Button>
                )
            case RESTAURANT_DETAILS_PATHS.beverages:
                return (
                    <Button type="button" size="sm" variant="primary" onClick={() => setIsBeverageModalOpen(true)}>
                        <PlusIcon stroke={theme.palette.neutral.white} />
                        {t('restaurant:ctas:addBeverage')}
                    </Button>
                )
            case RESTAURANT_DETAILS_PATHS.menus:
                return (
                    <Button type="button" size="sm" variant="primary" onClick={() => setIsMenuModalOpen(true)}>
                        <PlusIcon stroke={theme.palette.neutral.white} />
                        {t('restaurant:ctas:addMenu')}
                    </Button>
                )
            case RESTAURANT_DETAILS_PATHS.staff:
                return (
                    <Button type="button" size="sm" variant="primary" onClick={() => setIsStaffModalOpen(true)}>
                        <PlusIcon stroke={theme.palette.neutral.white} />
                        {t('restaurant:ctas:addStaff')}
                    </Button>
                )
            default:
                return undefined
        }
    }, [currentTab])

    return (
        <>
            <StyledMainContainer>
                <StyledBreadcrumbsWrapper>
                    <BreadCrumbs />
                </StyledBreadcrumbsWrapper>
                <StyledHeader>
                    <PageHero title={data?.name ?? ''} ctaComponent={button} />
                </StyledHeader>
                <StyledTabsWrapper>
                    <RestaurantDetailsTabs />
                </StyledTabsWrapper>
                <StyledContent fullWidth>{tabContent}</StyledContent>
            </StyledMainContainer>
            {isPeriodsModalOpen && (
                <PeriodModal
                    onClose={() => setIsPeriodsModalOpen(false)}
                    restaurantId={id ?? ''}
                    lastPeriod={
                        data?.periods?.reduce(
                            (max, item) => (item.period > max ? item.period : max),
                            data?.periods?.[0]?.period ?? 0
                        ) ?? 0
                    }
                />
            )}
            {isDishModalOpen && (
                <DishModal
                    periods={data?.periods ?? []}
                    onClose={() => {
                        setIsDishModalOpen(false)
                        setUploadedDishes(null)
                    }}
                    uploadedDishes={uploadedDishes}
                    restaurantId={id ?? ''}
                />
            )}
            {isBeverageModalOpen && (
                <BeverageModal
                    onClose={() => {
                        setIsBeverageModalOpen(false)
                        setUploadedDishes(null)
                    }}
                    uploadedDishes={uploadedDishes}
                    restaurantId={id ?? ''}
                    beveragePeriodId={data?.periods?.find(item => item.period === 0)?.idPeriod ?? ''}
                />
            )}
            {isInfoModalOpen && (
                <RestaurantModal
                    onClose={() => setIsInfoModalOpen(false)}
                    defaultValues={data ? adaptRestaurantToForm(data, plans ?? []) : undefined}
                    restaurantId={id ?? ''}
                />
            )}
            {isStaffModalOpen && (
                <StaffModal
                    restaurantId={id ?? ''}
                    restaurantName={data?.name ?? ''}
                    onClose={() => setIsStaffModalOpen(false)}
                />
            )}
            {isMenuModalOpen && (
                <MenuModal
                    restaurantId={id ?? ''}
                    periods={data?.periods ?? []}
                    onClose={() => setIsMenuModalOpen(false)}
                    dishes={data?.dishes ?? []}
                />
            )}
        </>
    )
}

RestaurantDetails.displayName = 'RestaurantDetails'
