import React, {FC, Fragment, PropsWithChildren, ReactNode, useCallback} from 'react'
import {DPCalendar, DPDay, DPPropGetter} from '@rehookify/datepicker'
import {
    CalendarWrapper,
    DayButtonWrapper,
    StyledCalendarHeader,
    StyledDayButton,
    StyledDayGrid,
    StyledWeekDayContainer
} from '@components/commons/calendar/style.ts'
import {capitalize} from '@utilities/helpers.ts'
import dayjs from '@/dayjs.ts'
import {ChevronLeftIcon, ChevronRightIcon} from '@components/ui/icon/Icon.tsx'
import {PopoverClose} from '@radix-ui/react-popover'

type DateSelectorProps = {
    mode: 'single' | 'multiple' | 'range'
    calendar: DPCalendar
    previousMonthButton?: () => DPPropGetter
    nextMonthButton?: () => DPPropGetter
    dayButton: (day: DPDay) => DPPropGetter
    weekDays: string[]
    locale?: string
    setViewState?: React.Dispatch<React.SetStateAction<'date' | 'month' | 'year'>>
}

export const DateSelector: FC<DateSelectorProps> = ({
    calendar,
    previousMonthButton,
    nextMonthButton,
    weekDays,
    dayButton,
    setViewState,
    locale = 'en',
    mode
}) => {
    const {month, year, days} = calendar
    const inMonthDays = days.filter(dpDay => dpDay.inCurrentMonth)

    const PopoverCloseWrapper = useCallback(
        ({children}: {children?: ReactNode}) => <PopoverClose asChild>{children}</PopoverClose>,
        []
    )
    let ButtonWrapper: FC<PropsWithChildren> = Fragment
    if (mode == 'single') {
        ButtonWrapper = PopoverCloseWrapper
    }
    return (
        <CalendarWrapper>
            <StyledCalendarHeader>
                {previousMonthButton && (
                    <button className={'prevButton dayButton'} {...previousMonthButton()}>
                        <ChevronLeftIcon size={20} />
                    </button>
                )}
                <span>
                    <span
                        role={'button'}
                        className={`${setViewState ? 'clickableText' : ''}`}
                        onClick={() => setViewState?.('month')}
                    >
                        {dayjs(`1-${month}-${year}`)
                            .locale(locale || 'en')
                            .format('MMMM')}
                    </span>{' '}
                    <span
                        role={'button'}
                        className={`${setViewState ? 'clickableText' : ''}`}
                        onClick={() => setViewState?.('year')}
                    >
                        {dayjs(year).locale(locale).format('YYYY')}
                    </span>
                </span>
                {nextMonthButton && (
                    <button className={'nextButton dayButton'} {...nextMonthButton()}>
                        <ChevronRightIcon size={20} />
                    </button>
                )}
            </StyledCalendarHeader>
            <StyledDayGrid>
                {weekDays.map((_, index) => (
                    <StyledWeekDayContainer key={index}>
                        {capitalize(
                            dayjs()
                                .locale(locale)
                                .day((index + 7) % 7)
                                .format('dddd')
                                .slice(0, 2)
                        )}
                    </StyledWeekDayContainer>
                ))}
            </StyledDayGrid>
            <StyledDayGrid>
                {days.map((dpDay, index) => {
                    const isPrimary =
                        dpDay.inCurrentMonth &&
                        (dpDay.selected || dpDay.range == 'will-be-range-end' || dpDay.range == 'will-be-range-start')

                    const isFirstDayOfTheMonth = inMonthDays[0].$date.toISOString() == dpDay.$date.toISOString()
                    const isLastDayOfTheMonth =
                        inMonthDays[inMonthDays.length - 1].$date.toISOString() == dpDay.$date.toISOString()
                    return (
                        <ButtonWrapper key={`${year}-${month}-${dpDay.day}-${index}`}>
                            <DayButtonWrapper
                                className={`${isFirstDayOfTheMonth ? 'firstMonthDay' : ''} ${
                                    isLastDayOfTheMonth ? 'lastMonthDay' : ''
                                }`}
                                $inCurrentMonth={dpDay.inCurrentMonth}
                                $rangeStatus={dpDay.range}
                            >
                                <StyledDayButton
                                    {...dayButton(dpDay)}
                                    variant={isPrimary ? 'primary' : 'ghost'}
                                    disabled={!dpDay.inCurrentMonth || dpDay.disabled}
                                >
                                    {dpDay.day}
                                </StyledDayButton>
                            </DayButtonWrapper>
                        </ButtonWrapper>
                    )
                })}
            </StyledDayGrid>
        </CalendarWrapper>
    )
}
