import {Container} from '@components/ui/container/Container.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'
import {useTranslation} from 'react-i18next'
import {CalendarIcon, PlusIcon, SearchLgIcon} from '@components/ui/icon/Icon.tsx'
import {toast} from '@/utilities/toast/toast'
import {useTheme} from 'styled-components'
import {useRestaurantsTable} from '@/features/restaurant/services/queries/useRestaurantsTable'
import {useEffect, useRef, useState} from 'react'
import {RestaurantsTable} from '@/features/restaurant/components/restaurants-table/RestaurantsTable'
import {EmptySearch} from '@/components/commons/empty-search/EmptySearch'
import {RestaurantModal} from '@/features/restaurant/components/restaurant-form/RestaurantModal'
import {InputText} from '@/components/commons/input-text/InputText'
import {ListPageLayout} from '@/layouts/list-page-layout/ListPageLayout'
import {RestaurantListItem} from '@/features/restaurant/types'
import {DeleteModal} from '@/components/commons/delete-modal/DeleteModal'
import {useDeleteRestaurant} from '@/features/restaurant/services/queries/useDeleteRestaurant'
import {DatePicker} from '@/components/commons/date-picker/DatePicker'
import dayjs from 'dayjs'
import {StyledExpiryDateWrapper, StyledFiltersWrapper} from './style'

export const Restaurants = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const restaurantsQuery = useRestaurantsTable()
    const searchRef = useRef<HTMLInputElement | null>(null)
    const [isAddModalOpen, setIsAddModalOpen] = useState(false)
    const [restaurantToDelete, setRestaurantToDelete] = useState<RestaurantListItem | null>(null)
    const {
        mutate: deleteMutate,
        isPending: deletePending,
        isError: isErrorDelete,
        error: errorDelete
    } = useDeleteRestaurant({
        onSuccess: () => setRestaurantToDelete(null)
    })

    useEffect(() => {
        if (restaurantsQuery.isError || isErrorDelete)
            toast.error(restaurantsQuery.error?.message || errorDelete?.message)
    }, [restaurantsQuery.isError, isErrorDelete])

    const onClickAddRestaurant = () => setIsAddModalOpen(true)

    const onCancelAddRestaurant = () => setIsAddModalOpen(false)

    const onSubmitRestaurantToDelete = () => {
        if (restaurantToDelete) {
            return deleteMutate(restaurantToDelete.idEatery)
        }
    }

    return (
        <ListPageLayout>
            <Container fullWidth>
                <PageHero
                    title={t('restaurants:title')}
                    ctaComponent={
                        <Button
                            style={{display: 'none'}}
                            size={'sm'}
                            variant={'primary'}
                            onClick={onClickAddRestaurant}
                        >
                            <PlusIcon stroke={theme.palette.neutral.white} />
                            {t('restaurant:new_restaurant')}
                        </Button>
                    }
                />

                <StyledFiltersWrapper>
                    <InputText
                        onChange={e => restaurantsQuery.onSearch(e.currentTarget.value)}
                        defaultValue={restaurantsQuery.searchValue}
                        type={'text'}
                        placeholder={t('commons:search_placeholder')}
                        typeIcon={<SearchLgIcon size={18} />}
                        ref={searchRef}
                        width={200}
                    />
                    <StyledExpiryDateWrapper>
                        <DatePicker
                            toggle
                            formatDateFn={date => dayjs(date).format('DD/MM/YYYY')}
                            numMonths={1}
                            mode={'single'}
                            selectedDates={
                                restaurantsQuery.licenseExpireValue ? [restaurantsQuery.licenseExpireValue] : []
                            }
                            onDatesChange={dates => restaurantsQuery.setLicenseExpireValue(dates[0])}
                            triggerProps={{
                                placeholder: t('restaurant:expiry_date_filter'),
                                typeIcon: <CalendarIcon stroke={theme.palette.neutral[900]} />
                            }}
                        />
                    </StyledExpiryDateWrapper>
                </StyledFiltersWrapper>

                <RestaurantsTable
                    data={restaurantsQuery.remappedData}
                    isChangingPage={restaurantsQuery.isFetchingNextPage}
                    isError={restaurantsQuery.isError}
                    isLoading={restaurantsQuery.isLoading}
                    onChangePage={restaurantsQuery.fetchNextPage}
                    searchValue={restaurantsQuery.searchValue}
                    onResetSearchCb={() => restaurantsQuery.onResetSearch(searchRef)}
                    emptySearchStateComponent={<EmptySearch title={t('commons:no_results')} icon={<SearchLgIcon />} />}
                    sorter={restaurantsQuery.sorter}
                    setRestaurantToDelete={setRestaurantToDelete}
                />
            </Container>
            {isAddModalOpen && <RestaurantModal onClose={onCancelAddRestaurant} />}
            {restaurantToDelete && (
                <DeleteModal
                    title={t('restaurant:delete:title')}
                    paragraph={t('restaurant:delete:description')}
                    isLoading={deletePending}
                    onClose={() => setRestaurantToDelete(null)}
                    onDelete={() => onSubmitRestaurantToDelete()}
                />
            )}
        </ListPageLayout>
    )
}

Restaurants.displayName = 'Restaurants'
