import {PeriodValidationSchema} from './components/period-form/PeriodFormModel'
import {HttpCreatePeriodParams} from './services/restaurantPeriods.http'

export const adaptPeriodsToCreate = (
    data: PeriodValidationSchema,
    restaurantId: string,
    lastPeriod: number
): HttpCreatePeriodParams => ({
    restaurantId,
    data: data.periods.map((period, index) => ({
        name: period.name,
        period: lastPeriod + index + 1
    }))
})
