import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpDeleteCustomer} from '../customer.http'
import toast from 'react-hot-toast'
import {useTranslation} from 'react-i18next'

export const useDeleteCustomer = ({onSuccess}: {onSuccess: () => void}) => {
    const client = useQueryClient()
    const {t} = useTranslation()

    return useMutation({
        mutationKey: [MUTATION_KEYS.DELETE_CUSTOMER],
        mutationFn: (id: string) => httpDeleteCustomer(id),
        onSuccess: () => {
            toast.success(t('commons:delete_completed', {entity: t('customer:singular')}))
            onSuccess()
            client.invalidateQueries({queryKey: [QUERY_KEYS.CUSTOMERS]})
        }
    })
}
