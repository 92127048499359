import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpDeleteStaff} from '../restaurantStaff.http'
import toast from 'react-hot-toast'
import {useTranslation} from 'react-i18next'

export const useDeleteStaff = ({idRestaurant}: {idRestaurant: string}) => {
    const client = useQueryClient()
    const {t} = useTranslation()

    return useMutation({
        mutationKey: [MUTATION_KEYS.DELETE_STAFF],
        mutationFn: (idEateryAccount: string[]) => httpDeleteStaff(idRestaurant, idEateryAccount),
        onSuccess: () => {
            toast.success(t('commons:delete_completed', {entity: t('staff:plural')}))
            client.invalidateQueries({queryKey: [QUERY_KEYS.RESTAURANT_DETAILS, idRestaurant]})
        }
    })
}
