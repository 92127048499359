import {z} from 'zod'

export const enum LOGIN_MODEL {
    Email = 'email',
    Password = 'password'
}

export const LoginSchema = z.object({
    [LOGIN_MODEL.Email]: z
        .string()
        .min(1, {message: 'errors:email'})
        .email({
            message: 'errors:email_valid'
        })
        .default(''),
    [LOGIN_MODEL.Password]: z.string().min(6, {message: 'errors:password'}).default('')
})

export type LoginValidationSchema = z.infer<typeof LoginSchema>
