import {z} from 'zod'

export const Plan = z.object({
    idPlan: z.string(),
    name: z.string(),
    code: z.number(),
    currency: z.string(),
    price: z.string(),
    vat: z.number()
})
export type Plan = z.infer<typeof Plan>
