import React, {useEffect} from 'react'
import {Button} from '@components/ui/button/Button.tsx'
import {Container} from '@components/ui/container/Container.tsx'
import {StyledLoginBox, StyledLoginWrapper} from '@pages/login/style.ts'
import {useTranslation} from 'react-i18next'
import Logo from '@assets/images/logo_icon.svg'
import {LOGIN_MODEL, LoginSchema, LoginValidationSchema} from '@/features/auth/services/LoginFormModel'
import {SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {InputText} from '@/components/commons/input-text/InputText'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import useAuthStore from '@/features/auth/store/store'
import {handleApiError} from '@/utilities/helpers'

export const Login: React.FC = () => {
    const {t} = useTranslation()
    const login = useAuthStore(store => store.login)
    const isLoading = useAuthStore(store => store.isLoading)
    const error = useAuthStore(store => store.error)
    const isError = useAuthStore(store => store.isError)
    const {
        register,
        handleSubmit,
        setError,
        formState: {errors, isValid, isDirty}
    } = useForm<LoginValidationSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(LoginSchema)
    })

    useEffect(() => {
        if (isError && error instanceof Error) handleApiError({setError, error})
    }, [isError])

    const onSubmit: SubmitHandler<LoginValidationSchema> = values => {
        login(values)
    }

    const isSubmitDisabled = !isValid || !isDirty || isLoading

    return (
        <Container>
            <StyledLoginWrapper onSubmit={handleSubmit(onSubmit)}>
                <StyledLoginBox>
                    <div className="circles">
                        <img src={Logo} alt={'logo'} width={48} />
                    </div>
                    <h4>{t('login:title')}</h4>
                    <p>{t('login:subtitle')}</p>
                    <Flexbox direction="column" gap={4}>
                        <InputText
                            {...register(LOGIN_MODEL.Email)}
                            type="text"
                            label={`${t('login:email')}*`}
                            errorMessage={`${t(errors[LOGIN_MODEL.Email]?.message || '')}`}
                        />

                        <InputText
                            {...register(LOGIN_MODEL.Password)}
                            label={`${t('login:password')}*`}
                            type="password"
                            visibilityToggle
                            errorMessage={`${t(errors[LOGIN_MODEL.Password]?.message || '')}`}
                        />

                        <Button type="submit" fullWidth variant={'primary'} size={'md'} disabled={isSubmitDisabled}>
                            {t('login:cta')}
                        </Button>
                    </Flexbox>
                </StyledLoginBox>
            </StyledLoginWrapper>
        </Container>
    )
}

Login.displayName = 'Login'
