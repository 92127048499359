import {ChevronRightIcon, HomeLineIcon} from '@/components/ui/icon/Icon'
import {useGetAccountDetails} from '@/features/account/services/queries/useGetAccountDetails'
import {HOME_PATHS} from '@/features/home/components/home-tabs/HomeTabs'
import {routes} from '@/utilities/constants/routes'
import {StyledBreadcrumbs} from '@components/commons/bread-crumbs/style.ts'
import {useTranslation} from 'react-i18next'
import {Link, generatePath, useParams} from 'react-router-dom'

export const AccountDetailsBreadcrumb = () => {
    const {t} = useTranslation()
    const {id} = useParams()
    const {data} = useGetAccountDetails({accountId: `${id}`})

    return (
        <StyledBreadcrumbs>
            <Link to={generatePath(routes.HOME.path, {tab: HOME_PATHS.restaurants})}>
                <HomeLineIcon data-color size={20} />
            </Link>
            <ChevronRightIcon data-color size={16} />
            <Link to={routes.ACCOUNTS.path}>{t('routeNames:accounts')}</Link>
            <ChevronRightIcon data-color size={16} />
            <Link className="active" to={generatePath(routes.ACCOUNT_DETAILS.path, {id})}>
                {data?.displayName}
            </Link>
        </StyledBreadcrumbs>
    )
}

AccountDetailsBreadcrumb.displayName = 'AccountDetailsBreadcrumb'
