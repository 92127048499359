import styled from 'styled-components'

export const MOBILE_TABLE_SCROLLBAR_WIDTH = '4px'

export const StyledMobileTable = styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
`
