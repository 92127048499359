import HomeTabs, {HOME_PATHS} from '@/features/home/components/home-tabs/HomeTabs'
import {StyledContent, StyledHeader, StyledMainContainer, StyledTabsWrapper, StyledTitle} from './style'
import {useParams} from 'react-router-dom'
import {useMemo} from 'react'
import HomeRestaurants from '@/features/home/components/home-restaurants/HomeRestaurants'
import HomeAccounts from '@/features/home/components/home-accounts/HomeAccounts'
import HomeMenus from '@/features/home/components/home-menus/HomeMenus'
import HomeCustomers from '@/features/home/components/home-customers/HomeCustomers'
import {useTranslation} from 'react-i18next'

export const Home = () => {
    const {tab: currentTab} = useParams()
    const {t} = useTranslation()

    const tabContent = useMemo(() => {
        switch (currentTab) {
            case HOME_PATHS.restaurants:
                return <HomeRestaurants />
            case HOME_PATHS.accounts:
                return <HomeAccounts />
            case HOME_PATHS.customers:
                return <HomeCustomers />
            case HOME_PATHS.menus:
                return <HomeMenus />

            default:
                return undefined
        }
    }, [currentTab])

    return (
        <StyledMainContainer>
            <StyledHeader>
                <StyledTitle>{t('home:dashboard')}</StyledTitle>
            </StyledHeader>
            <StyledTabsWrapper>
                <div>
                    <HomeTabs />
                </div>
            </StyledTabsWrapper>
            <StyledContent fullWidth>{tabContent}</StyledContent>
        </StyledMainContainer>
    )
}

Home.displayName = 'Home'
