import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {httpUpdateRestaurant} from '../restaurant.http'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons'
import {errorHandler} from '@/utilities/genericErrorHandler'

type UseUpdateProjectProps = {
    onSuccess?: () => void
    onError?: () => void
    restaurantId: string
}

export const useUpdateRestaurant = ({onSuccess, onError, restaurantId}: UseUpdateProjectProps) => {
    const client = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.UPDATE_RESTAURANT],
        mutationFn: httpUpdateRestaurant,
        onSuccess: () => {
            client.invalidateQueries({queryKey: [QUERY_KEYS.RESTAURANT_DETAILS, restaurantId]})
            client.invalidateQueries({queryKey: [QUERY_KEYS.RESTAURANTS]})
            onSuccess?.()
        },
        onError: (error: AxiosError<ErrorResponseData>) => {
            onError?.()
            errorHandler(error)
        }
    })
}
