import React from 'react'
import {useTranslation} from 'react-i18next'
import {Modal} from '@/components/ui/modal/Modal'
import {StyledModal, StyledTitle} from './style'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import MenuPageForm from './MenuPageForm'

interface MenuPageModalProps {
    onClose: () => void
    restaurantId: string
    menuId: string
    lastPagePosition: number
}

export const MenuPageModal: React.FC<MenuPageModalProps> = ({onClose, restaurantId, menuId, lastPagePosition}) => {
    const {t} = useTranslation()

    return (
        <Modal width={500} closeIconOffset="sm" onClose={onClose}>
            <StyledModal>
                <Flexbox align={'center'}>
                    <StyledTitle>{t('menu:addPageModal:title')}</StyledTitle>
                </Flexbox>
                <MenuPageForm
                    restaurantId={restaurantId}
                    menuId={menuId}
                    onCancel={onClose}
                    lastPagePosition={lastPagePosition}
                />
            </StyledModal>
        </Modal>
    )
}

export default MenuPageModal
