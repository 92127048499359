import {FC, ReactNode} from 'react'
import {StyledList} from './style'

interface ListProps {
    children: ReactNode
}
export const List: FC<ListProps> = ({children}) => {
    return <StyledList>{children}</StyledList>
}

List.displayName = 'List'
