import axios from '@/axiosInstance'
import {z} from 'zod'

/**
 * httpDeleteStaff
 * Here we will delete multiple staff members
 */

export const httpDeleteStaff = async (idRestaurant: string, idEateryAccount: string[]): Promise<void> => {
    await axios.delete(`/~/account/eatery/${idRestaurant}/crew/delete`, {params: {idEateryAccount}})
}

/**
 * httpCreateStaff
 * Here we will create a staff member instance
 */

// Response
export const HttpCreateStaffResponse = z.array(
    z.object({
        displayName: z.string().nullable()
    })
)
export type HttpCreateStaffResponse = z.infer<typeof HttpCreateStaffResponse>

export const HttpStaffBodyRequest = z.object({
    eatery: z.object({
        idEatery: z.string(),
        name: z.string(),
        crew: z.array(
            z.object({
                displayName: z.string()
            })
        )
    }),
    email: z.string(),
    permission: z.string()
})

export type HttpStaffBodyRequest = z.infer<typeof HttpStaffBodyRequest>

export const HttpCreateStaffParams = z.object({
    data: HttpStaffBodyRequest
})
export type HttpCreateStaffParams = z.infer<typeof HttpCreateStaffParams>

export const httpCreateStaff = async (params: HttpCreateStaffParams): Promise<HttpCreateStaffResponse> => {
    const response = await axios.post('/~/account/crew/invitation', params.data)
    return response.data
}
