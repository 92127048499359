import {LoadingWrapper, UploaderWrapper} from './style'
import {Accept, useDropzone} from 'react-dropzone'
import {useTranslation} from 'react-i18next'
import {FC} from 'react'
import {useTheme} from 'styled-components'
import {megabytesToBytes, truncateText} from '@utilities/helpers.ts'
import {Dropzone} from '@/components/ui/dropzone/Dropzone'
import {ImageIcon} from '@/components/ui/icon/Icon'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {Spinner} from '@/components/ui/spinner/Spinner'

type ImageUploaderProps = {
    maxSizeInMB: number
    accept?: Accept
    value?: File
    onChange?: (file: File) => void
    error?: string
    url?: string
    downloadCallback: (file: File | undefined) => void
    isLoading?: boolean
    image?: string
}

export const ImageUploader: FC<ImageUploaderProps> = ({
    maxSizeInMB,
    accept,
    onChange,
    value,
    error,
    url,
    downloadCallback,
    isLoading,
    image
}) => {
    const dropzoneState = useDropzone({
        onDrop: acceptedFiles => {
            const [file] = acceptedFiles
            if (file) {
                onChange?.(file)
            }
        },
        accept,
        maxSize: megabytesToBytes(maxSizeInMB),
        maxFiles: 1,
        noClick: true
    })

    const {t} = useTranslation()
    const {palette} = useTheme()

    if (isLoading) {
        return (
            <LoadingWrapper>
                <div className="icon-container">
                    <Spinner color={palette.neutral[500]} size={16} />
                </div>
                <div className="text-container">{t('fileUpload:loading')} </div>
            </LoadingWrapper>
        )
    }

    return (
        <>
            <Dropzone state={dropzoneState}>
                <UploaderWrapper
                    dragAccepted={dropzoneState.isDragAccept}
                    dragRejected={dropzoneState.isDragReject}
                    error={!!error}
                >
                    {image ? (
                        <>
                            <img className="image" src={image} onClick={dropzoneState.open} />
                        </>
                    ) : (
                        <div className="wrapper">
                            <div className="icon-container" onClick={() => downloadCallback(value)}>
                                <ImageIcon fill={palette.neutral['400']} size={20} viewBox="0 0 29 28" />
                            </div>
                            <div className="text-container">
                                {value && <p className="filename">{truncateText(value.name, 70)}</p>}
                                {url && !value && <p className="filename">{url}</p>}
                                <p>
                                    <span onClick={dropzoneState.open} className="choose-file">
                                        {t('fileUpload:chooseFile')}{' '}
                                    </span>
                                    {t('fileUpload:orDnd')}
                                </p>
                            </div>
                        </div>
                    )}
                </UploaderWrapper>
            </Dropzone>
            <InputHelpText error={error} />
        </>
    )
}
ImageUploader.displayName = 'ImageUploader'
