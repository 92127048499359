import {z} from 'zod'

export const LogListItem = z.object({
    email: z.string(),
    idLog: z.number(),
    method: z.string(),
    deleted: z.number(),
    resource: z.string(),
    givenName: z.string(),
    idAccount: z.string(),
    timestamp: z.string(),
    displayName: z.string().nullable()
})
export type LogListItem = z.infer<typeof LogListItem>

export const LogsSearchParams = z.object({
    orderBy: z.enum(['email', 'timestamp']).optional(),
    orderDirection: z.enum(['asc', 'desc']).optional(),
    search: z.string().optional()
})
export type LogsSearchParams = z.infer<typeof LogsSearchParams>
