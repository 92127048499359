import {MobileTable} from '@components/commons/table/mobile-table/MobileTable'
import {DesktopTable} from '@/components/commons/table/desktop-table/DesktopTable'
import {ReactElement, ReactNode} from 'react'
import {Percentage} from '@/types.ts'
import {EmptyPlaceholder} from '@components/commons/table/empty-placeholder/EmptyPlaceholder'

export interface TableColumn<TData extends unknown[], TSortName extends string | undefined> {
    alignment?: 'left' | 'center' | 'right'
    cellRender: (item: TData[number], itemIndex: number) => ReactNode
    label?: ReactNode
    sortName?: TSortName
    width: Percentage
}

export interface TableActiveSorter<TSortName extends string | undefined> {
    orderBy: TSortName | undefined
    orderDirection: 'asc' | 'desc' | undefined
}

export interface TableSorter<TSortName extends string | undefined> extends TableActiveSorter<TSortName> {
    onSort: (order: TableActiveSorter<TSortName>) => void
}

export interface TableExpandedRows<TData extends unknown[]> {
    indexes: number[]
    render: (item: TData[number], itemIndex: number) => ReactNode
}

export interface TableProps<TData extends unknown[], TSortName extends string | undefined> {
    columns: TableColumn<TData, TSortName>[]
    data: TData
    emptySearchStateComponent?: ReactElement
    emptyStateComponent?: ReactElement
    isError?: boolean
    expandedRows?: TableExpandedRows<TData>
    isChangingPage?: boolean
    isLoading: boolean
    onChangePage?: (lastItemIndex: number) => void
    searchValue?: string | boolean
    sorter?: TableSorter<TSortName>
    subRow?: (item: TData[number], itemIndex: number) => ReactNode
}

export const Table = <TData extends unknown[], TSortName extends string | undefined>({
    data,
    emptySearchStateComponent,
    emptyStateComponent,
    isError,
    isLoading,
    isMobile,
    searchValue,
    ...rest
}: TableProps<TData, TSortName> & {isMobile?: boolean}) => {
    const propsToPass = {
        data,
        emptySearchStateComponent,
        emptyStateComponent,
        isError,
        isLoading,
        isMobile,
        searchValue,
        ...rest
    }

    if (!data.length && !isError && searchValue && !isLoading) {
        return emptySearchStateComponent ?? <EmptyPlaceholder />
    }
    if (!data.length && !isError && !isLoading) {
        return emptyStateComponent ?? <EmptyPlaceholder />
    }

    return isMobile ? <MobileTable {...propsToPass} /> : <DesktopTable {...propsToPass} />
}

Table.displayName = 'Table'
