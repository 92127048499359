import {XCloseIcon} from '@/components/ui/icon/Icon'
import {ReactNode} from 'react'
import RHT, {Toast} from 'react-hot-toast'
import {StyledToastContainer} from './style'
import {Button} from '@/components/ui/button/Button'

const renderToast = (content: ReactNode, id: string, variant: 'ghost' | 'ghostDanger' | 'ghostSuccess' = 'ghost') => (
    <StyledToastContainer>
        <div>{content}</div>
        <Button variant={variant} onClick={() => RHT.dismiss(id)}>
            <XCloseIcon />
        </Button>
    </StyledToastContainer>
)

type Options = Partial<
    Pick<Toast, 'className' | 'id' | 'style' | 'icon' | 'duration' | 'ariaProps' | 'position' | 'iconTheme'>
>

export const toast = {
    success: (content: ReactNode, options?: Options) =>
        RHT.success(t => renderToast(content, t.id, 'ghostSuccess'), options),
    error: (content: ReactNode, options?: Options) =>
        RHT.error(t => renderToast(content, t.id, 'ghostDanger'), options),
    info: (content: ReactNode, options?: Options) => RHT(t => renderToast(content, t.id), options)
}
