import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
import {httpGetPlans} from '../plan.http'

export const usePlansQuery = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.PLANS],
        queryFn: httpGetPlans
    })
}
