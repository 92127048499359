export const breakpoints = {
    width: {
        xs: 365,
        s: 414,
        m: 768,
        l: 980,
        xl: 1200,
        '2xl': 1440,
        '3xl': 1920
    }
} as const

export const mediaQueries = {
    xs: `@media only screen and (max-width: ${breakpoints.width.xs}px)`,
    s: `@media only screen and (max-width: ${breakpoints.width.s}px)`,
    m: `@media only screen and (max-width: ${breakpoints.width.m}px)`,
    l: `@media only screen and (max-width: ${breakpoints.width.l}px)`,
    xl: `@media only screen and (max-width: ${breakpoints.width.xl}px)`,
    '2xl': `@media only screen and (max-width: ${breakpoints.width['2xl']}px)`,
    '3xl': `@media only screen and (max-width: ${breakpoints.width['3xl']}px)`
} as const satisfies Record<keyof (typeof breakpoints)[keyof typeof breakpoints], string>
