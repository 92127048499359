import styled, {css} from 'styled-components'
import {DefaultTheme} from 'styled-components/dist/types'

export const StyledMainWrapper = styled.div`
    ${() => css`
        width: 60%;
        margin: 0 auto;
    `}
`

export const StyledActionsWrapper = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 4}px;
        margin-bottom: ${spacing * 2}px;
        & > span:first-child {
            ${typography.textMd}
            flex-grow: 1;
            color: ${palette.neutral[700]};
            font-weight: 700;
        }
    `}
`

export const StyledStaffMainWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 1fr;
        gap: ${spacing * 2}px;
        width: 100%;
    `}
`

type StyledStaffInfoWrapperProps = {
    theme: DefaultTheme
    $disabled: boolean
}

export const StyledStaffInfoWrapper = styled.div<StyledStaffInfoWrapperProps>`
    ${({theme: {palette, typography, spacing}, $disabled}) => css`
        display: flex;
        flex-direction: column;
        & > .name {
            ${typography.textLg}
            color: ${$disabled ? palette.neutral[400] : palette.neutral[700]}
        }
        & > .email {
            ${typography.textMd}
            color: ${$disabled ? palette.neutral[400] : palette.neutral[700]}
        }
        & > .role {
            ${typography.textMd}
            color: ${palette.neutral[400]}
        }
        & > .status {
            margin-top: ${spacing * 2}px;
        }
    `}
`
