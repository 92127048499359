import {z} from 'zod'

export const MENU_FORM_MODEL = {
    name: {
        name: 'name',
        label: 'menu:addForm:fields:name'
    },
    pages: {
        name: {
            name: 'name',
            label: 'menu:addForm:fields:pages:name'
        },
        dishes: {
            dish: {
                dish: 'dish',
                label: 'menu:addForm:fields:pages:dishes:dish'
            },
            period: {
                name: 'period',
                label: 'menu:addForm:fields:pages:dishes:period'
            },
            price: {
                name: 'price',
                label: 'menu:addForm:fields:pages:dishes:price'
            }
        }
    }
} as const

export const PageDishSchema = z.object({
    dish: z.object({
        value: z.string().min(1, {message: 'errors:required'}),
        label: z.string()
    }),
    period: z.object({
        value: z.string().min(1, {message: 'errors:required'}),
        label: z.string()
    }),
    price: z.coerce.number()
})
export type PageDish = z.infer<typeof PageDishSchema>

export const MenuPageSchema = z.object({
    name: z.string().min(1, {message: 'errors:required'}),
    dishes: z.array(PageDishSchema)
})
export type MenuPage = z.infer<typeof MenuPageSchema>

export const MenuFormSchema = z.object({
    name: z.string().min(1, {message: 'errors:required'}),
    pages: z.array(MenuPageSchema)
})
export type MenuValidationSchema = z.infer<typeof MenuFormSchema>

export const MenuPageNameSchema = z.object({
    name: z.string().min(1, {message: 'errors:required'})
})
export type MenuPageNameValidationSchema = z.infer<typeof MenuPageNameSchema>

export const MenuPageItemSchema = z.object({
    dishes: z.array(PageDishSchema)
})
export type MenuPageItemValidationSchema = z.infer<typeof MenuPageItemSchema>
