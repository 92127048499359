import styled, {css} from 'styled-components'

export const StyledMainWrapper = styled.div`
    ${() => css`
        width: 60%;
        margin: 0 auto;
    `}
`

export const StyledActionsWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: ${spacing * 2}px;
        margin-bottom: ${spacing * 2}px;
    `}
`
