import {useTranslation} from 'react-i18next'
import {StyledTabItem, StyledTabs} from './style'
import {Link, generatePath, useNavigate, useParams} from 'react-router-dom'
import {routes} from '@/utilities/constants/routes'
import {useEffect} from 'react'

export const HOME_PATHS = {
    restaurants: 'home-restaurants',
    accounts: 'home-accounts',
    customers: 'home-customers',
    menus: 'home-menus'
}

export const TABS = [
    {
        label: 'home:tabs:restaurants',
        path: HOME_PATHS.restaurants
    },
    {
        label: 'home:tabs:accounts',
        path: HOME_PATHS.accounts
    },
    {
        label: 'home:tabs:customers',
        path: HOME_PATHS.customers
    },
    {
        label: 'home:tabs:menus',
        path: HOME_PATHS.menus
    }
]

export const HomeTabs = () => {
    const {t} = useTranslation()
    const {tab: currentTab} = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (!currentTab) navigate(generatePath(routes.HOME.path, {tab: HOME_PATHS.restaurants}))
    }, [currentTab])

    return (
        <StyledTabs gap={4} align="center">
            {TABS.map(tab => (
                <Link to={generatePath(routes.HOME.path, {tab: tab.path})} key={tab.path}>
                    <StyledTabItem $isActive={tab.path === currentTab}>
                        <span className="label">{t(tab.label)}</span>
                    </StyledTabItem>
                </Link>
            ))}
        </StyledTabs>
    )
}

export default HomeTabs
