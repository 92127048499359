import {Container} from '@components/ui/container/Container.tsx'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'
import {useTranslation} from 'react-i18next'
import {SearchLgIcon} from '@components/ui/icon/Icon.tsx'
import {toast} from '@/utilities/toast/toast'
import {useEffect, useRef, useState} from 'react'
import {EmptySearch} from '@/components/commons/empty-search/EmptySearch'
import {InputText} from '@/components/commons/input-text/InputText'
import {FiltersContainer} from '@/components/commons/filters-container/FiltersContainer'
import {ListPageLayout} from '@/layouts/list-page-layout/ListPageLayout'
import {MenuListItem} from '@/features/menu/types'
import {useMenusTable} from '@/features/menu/services/queries/useMenusTable'
import {MenusTable} from '@/features/menu/components/menus-table/MenusTable'
import {DeleteModal} from '@/components/commons/delete-modal/DeleteModal'
import {useDeleteMenu} from '@/features/menu/services/queries/useDeleteMenu'
import {useDuplicateMenu} from '@/features/menu/services/queries/useDuplicateMenu'
import {generatePath, useNavigate} from 'react-router-dom'
import {routes} from '@/utilities/constants/routes'

export const Menus = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const menusQuery = useMenusTable()
    const searchRef = useRef<HTMLInputElement | null>(null)
    const [menuToDelete, setMenuToDelete] = useState<MenuListItem | null>(null)
    const {
        mutate: deleteMutate,
        isPending: deletePending,
        isError: isErrorDelete,
        error: errorDelete
    } = useDeleteMenu({
        onSuccess: () => setMenuToDelete(null)
    })
    const {mutate: duplicateMenu} = useDuplicateMenu({
        onSuccess: data => {
            toast.success(t('menu:duplicatedSuccess'))
            navigate(
                `${generatePath(routes.RESTAURANT_DETAILS.path, {
                    id: data.idEatery,
                    tab: 'menus'
                })}?idMenu=${data.idMenu}`
            )
        }
    })

    useEffect(() => {
        if (menusQuery.isError || isErrorDelete) toast.error(menusQuery.error?.message || errorDelete?.message)
    }, [menusQuery.isError, isErrorDelete])

    const onSubmitMenuToDelete = () => {
        if (menuToDelete) {
            return deleteMutate(menuToDelete.idMenu)
        }
    }

    const onDuplicateMenu = (menuId: string) => {
        duplicateMenu({menuId})
    }

    return (
        <ListPageLayout>
            <Container fullWidth>
                <PageHero title={t('menu:title')} />

                <FiltersContainer>
                    <InputText
                        onChange={e => menusQuery.onSearch(e.currentTarget.value)}
                        defaultValue={menusQuery.searchValue}
                        type={'text'}
                        placeholder={t('commons:search_placeholder')}
                        typeIcon={<SearchLgIcon size={18} />}
                        ref={searchRef}
                        width={200}
                    />
                </FiltersContainer>

                <MenusTable
                    data={menusQuery.remappedData}
                    isChangingPage={menusQuery.isFetchingNextPage}
                    isError={menusQuery.isError}
                    isLoading={menusQuery.isLoading}
                    onChangePage={menusQuery.fetchNextPage}
                    searchValue={menusQuery.searchValue}
                    onResetSearchCb={() => menusQuery.onResetSearch(searchRef)}
                    emptySearchStateComponent={<EmptySearch title={t('commons:no_results')} icon={<SearchLgIcon />} />}
                    sorter={menusQuery.sorter}
                    setMenuToDelete={setMenuToDelete}
                    onDuplicateMenu={onDuplicateMenu}
                />
            </Container>
            {menuToDelete && (
                <DeleteModal
                    title={t('menu:delete:title')}
                    paragraph={t('menu:delete:description')}
                    isLoading={deletePending}
                    onClose={() => setMenuToDelete(null)}
                    onDelete={() => onSubmitMenuToDelete()}
                />
            )}
        </ListPageLayout>
    )
}

Menus.displayName = 'Menus'
