import {Box} from '@/components/commons/box/Box'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {
    StyledActionsWrapper,
    StyledBeverageImageWrapper,
    StyledBeverageInfoWrapper,
    StyledBeverageMainWrapper,
    StyledBeveragePriceWrapper,
    StyledMainWrapper,
    StyledTemplateLink,
    StyledUploadTitle
} from './style'
import {Button} from '@/components/ui/button/Button'
import {ImageIcon, Trash01Icon} from '@/components/ui/icon/Icon'
import {Checkbox} from '@/components/commons/checkbox/CheckBox'
import {List} from '@/components/commons/list/list/List'
import {ListItem} from '@/components/commons/list/list-item/ListItem'
import {downloadFile, formatCurrency} from '@/utilities/helpers'
import {useSelectableList} from '@/hooks/useSelectableList'
import {Uploader} from '@/components/commons/uploader/Uploader'
import {DeleteModal} from '@/components/commons/delete-modal/DeleteModal'
import {useUploadBeveragesFile} from '../../services/queries/useUploadBeveragesFile'
import {toast} from '@/utilities/toast/toast'
import {BeverageItem} from '@/features/restaurant/types'
import {EmptyList} from '@/components/commons/empty-list/EmptyList'
import {HttpUploadBeveragesFileResponse} from '../../services/restaurantBeverages.http'
import {useDeleteBeverages} from '../../services/queries/useDeleteDishes'
import {BeverageModal} from '../beverage-form/BeverageModal'
import {useTheme} from 'styled-components'

type BeveragesListProps = {
    beverages: BeverageItem[]
    onUploadBeverages: (beverages: HttpUploadBeveragesFileResponse) => void
    beveragePeriodId: string
}

export const BeveragesList: React.FC<BeveragesListProps> = ({beverages, onUploadBeverages, beveragePeriodId}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const {id: restaurantId} = useParams()
    const {items, setItems, onSelectItem, onSelectAll, isAllSelected, isNoneSelected} = useSelectableList()
    const [file, setFile] = useState<File | undefined>(undefined)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [isBeverageModalOpen, setIsBeverageModalOpen] = useState(false)
    const {mutate: deleteBeverages, isPending: isPendingDeleteBeverages} = useDeleteBeverages({
        idRestaurant: `${restaurantId}`
    })

    const {
        data: uploadedBeverages,
        mutateAsync: uploadBeveragesFile,
        isPending: isPendingUploadBeveragesFile
    } = useUploadBeveragesFile({
        restaurantId: restaurantId ?? '',
        onSuccess: () => toast.success(t('beverage:list:uploadSuccessful'))
    })

    useEffect(() => {
        if (uploadedBeverages?.length) {
            onUploadBeverages(uploadedBeverages)
        }
    }, [uploadedBeverages?.length])

    useEffect(() => {
        if (beverages && beverages?.length) {
            setItems(
                beverages?.map((_, index) => ({
                    index,
                    selected: false
                }))
            )
        }
    }, [beverages?.length])

    const onChangeFile = (uploadedFile: File) => {
        setFile(uploadedFile)
        uploadBeveragesFile(uploadedFile)
        setFile(undefined)
    }

    const onCloseDeleteModal = () => {
        setIsDeleteModalVisible(false)
    }

    const onSubmitDeleteModal = () => {
        setIsDeleteModalVisible(false)
        deleteBeverages(items.filter(item => item.selected).map(item => beverages[item.index].idDish))
    }

    return (
        <>
            <Box>
                <StyledMainWrapper>
                    <div>
                        <StyledUploadTitle>{t('commons:upload_file')}</StyledUploadTitle>
                        <Uploader
                            maxSizeInMB={10}
                            accept={{'text/csv': []}}
                            value={file}
                            onChange={onChangeFile}
                            downloadCallback={downloadFile}
                            isLoading={isPendingUploadBeveragesFile}
                        />
                        <StyledTemplateLink href="/template.csv" target="_blank">
                            {t('commons:download_template')}
                        </StyledTemplateLink>
                    </div>
                    {beverages.length ? (
                        <div>
                            <StyledActionsWrapper>
                                <span>{t('beverage:list:label')}</span>
                                <Button
                                    type="button"
                                    variant="transparentDanger"
                                    disabled={isNoneSelected}
                                    onClick={() => setIsDeleteModalVisible(true)}
                                >
                                    <Trash01Icon />
                                    {t('commons:delete')}
                                </Button>
                                <Button
                                    type="button"
                                    variant="secondary"
                                    disabled={isNoneSelected}
                                    onClick={() => setIsBeverageModalOpen(true)}
                                >
                                    {t('commons:update')}
                                </Button>
                                <Checkbox
                                    disabled={items.length === 0}
                                    checked={isAllSelected}
                                    onChange={onSelectAll}
                                    id="selectAll"
                                    label={t('commons:select_all')}
                                />
                            </StyledActionsWrapper>
                            <List>
                                {beverages?.map((beverage, beverageIndex) => {
                                    const checked =
                                        items.find((element, index) => index === beverageIndex && element.selected) !==
                                        undefined
                                    return (
                                        <ListItem key={beverage.idDish} isSelected={checked}>
                                            <Checkbox
                                                checked={checked}
                                                onChange={() => onSelectItem(beverageIndex)}
                                                id={`${beverage.idDish}`}
                                            />
                                            <StyledBeverageMainWrapper>
                                                {beverage.resource ? (
                                                    <StyledBeverageImageWrapper>
                                                        <img
                                                            className="image"
                                                            src={beverage.resource.URL}
                                                            alt="Beverage"
                                                        />
                                                    </StyledBeverageImageWrapper>
                                                ) : (
                                                    <StyledBeverageImageWrapper>
                                                        <ImageIcon
                                                            fill={theme.palette.neutral['400']}
                                                            size={50}
                                                            viewBox="0 0 29 28"
                                                        />
                                                    </StyledBeverageImageWrapper>
                                                )}
                                                <StyledBeverageInfoWrapper>
                                                    <span className="name">{beverage.name}</span>
                                                    <span className="description">{beverage.description}</span>
                                                </StyledBeverageInfoWrapper>
                                                <StyledBeveragePriceWrapper>
                                                    <span className="price">
                                                        {formatCurrency(beverage.price, 'EUR')}
                                                    </span>
                                                </StyledBeveragePriceWrapper>
                                            </StyledBeverageMainWrapper>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </div>
                    ) : (
                        <EmptyList />
                    )}
                </StyledMainWrapper>
            </Box>
            {isDeleteModalVisible && (
                <DeleteModal
                    title={t('commons:delete_items_title')}
                    paragraph={t('commons:delete_items_description')}
                    isLoading={isPendingDeleteBeverages}
                    onClose={onCloseDeleteModal}
                    onDelete={onSubmitDeleteModal}
                />
            )}
            {isBeverageModalOpen && (
                <BeverageModal
                    beveragePeriodId={beveragePeriodId}
                    onClose={() => {
                        setIsBeverageModalOpen(false)
                        setItems(
                            beverages?.map((_, index) => ({
                                index,
                                selected: false
                            }))
                        )
                    }}
                    restaurantId={restaurantId ?? ''}
                    defaultValues={{
                        beverages: beverages
                            .filter((_, index) => items.find(item => item.index === index && item.selected))
                            .map(dish => ({
                                idDish: dish.idDish,
                                name: dish.name,
                                description: dish.description ?? '',
                                price: dish.price
                            }))
                    }}
                    defaultImages={beverages
                        .filter((_, index) => items.find(item => item.index === index && item.selected))
                        .map((beverage, index) => ({
                            file: undefined,
                            image: beverage.resource ? beverage.resource.URL : undefined,
                            index
                        }))}
                />
            )}
        </>
    )
}

export default BeveragesList
