import styled, {css} from 'styled-components'

export const StyledMainWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 350px 1fr;
        gap: ${spacing * 8}px;
    `}
`

export const StyledActionsWrapper = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        display: flex;
        align-items: center;
        gap: ${spacing * 4}px;
        margin-bottom: ${spacing * 2}px;
        & > span:first-child {
            ${typography.textMd}
            flex-grow: 1;
            color: ${palette.neutral[700]};
            font-weight: 700;
        }
    `}
`

export const StyledBeverageMainWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 100px 1fr auto;
        gap: ${spacing * 4}px;
        width: 100%;
    `}
`

export const StyledBeverageImageWrapper = styled.div`
    ${({theme: {palette}}) => css`
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 1px ${palette.neutral[300]};
        border-radius: 8px;
        & > .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
        }
    `}
`

export const StyledBeverageInfoWrapper = styled.div`
    ${({theme: {palette, typography}}) => css`
        display: flex;
        flex-direction: column;
        & > .name {
            ${typography.textLg}
            color: ${palette.neutral[700]};
        }
        & > .description {
            ${typography.textMd}
            color: ${palette.neutral[400]};
        }
    `}
`

export const StyledBeveragePriceWrapper = styled.div`
    ${({theme: {palette, typography}}) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        & > .price {
            ${typography.textLg}
            color: ${palette.neutral[700]};
        }
    `}
`

export const StyledUploadTitle = styled.span`
    ${({theme: {palette, typography, spacing}}) => css`
        ${typography.textMd}
        flex-grow: 1;
        color: ${palette.neutral[700]};
        font-weight: 700;
        margin: ${spacing * 2}px 0 ${spacing * 4}px 0;
        display: block;
    `}
`

export const StyledTemplateLink = styled.a`
    ${({theme: {palette, spacing, typography}}) => css`
        ${typography.textSm}
        display: block;
        margin-top: ${spacing * 4}px;
        text-align: center;
        color: ${palette.primary[700]};
        cursor: pointer;
        font-weight: 600;
    `}
`
