import {
    MenuPageItemValidationSchema,
    MenuPageNameValidationSchema,
    MenuValidationSchema
} from './components/menu-form/MenuFormModel'
import {
    HttpCreateMenuPageItemParams,
    HttpCreateMenuPageParams,
    HttpCreateMenuParams
} from './services/restaurantMenus.http'

export const adaptMenuToCreate = (data: MenuValidationSchema, restaurantId: string): HttpCreateMenuParams => ({
    restaurantId,
    data: [
        {
            name: data.name,
            type: '1',
            menuPages: data.pages.map((page, pageIndex) => ({
                name: page.name,
                position: pageIndex + 1,
                menuPageItems: page.dishes.map((dish, dishIndex) => ({
                    idDish: dish.dish.value,
                    position: dishIndex + 1,
                    priceOverride: dish.price,
                    idPeriodOverride: dish.period.value
                }))
            }))
        }
    ]
})

export const adaptMenuPageToCreate = (
    data: MenuPageNameValidationSchema,
    restaurantId: string,
    menuId: string,
    lastPagePosition: number
): HttpCreateMenuPageParams => ({
    restaurantId,
    menuId,
    data: [
        {
            name: data.name,
            position: lastPagePosition + 1
        }
    ]
})

export const adaptMenuPageItemToCreate = (
    data: MenuPageItemValidationSchema,
    restaurantId: string,
    menuId: string,
    menuPageId: string,
    lastPagePosition: number
): HttpCreateMenuPageItemParams => ({
    restaurantId,
    menuId,
    menuPageId,
    data: data.dishes.map((item, index) => ({
        idDish: item.dish.value,
        idPeriodOverride: item.price.toString(),
        position: lastPagePosition + index + 1,
        priceOverride: item.price
    }))
})
