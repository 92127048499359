import {BeverageValidationSchema} from './components/beverage-form/BeverageFormModel'
import {HttpBeverageBodyRequestItem, HttpCreateBeverageParams} from './services/restaurantBeverages.http'

export const adaptBeveragesToCreate = (
    data: BeverageValidationSchema,
    idEatery: string,
    idPeriod: string
): HttpCreateBeverageParams => ({
    idEatery,
    data: data.beverages.map(beverage => {
        const toSend: HttpBeverageBodyRequestItem = {
            description: beverage.description,
            name: beverage.name,
            period: {
                idPeriod
            },
            price: beverage.price.toString(),
            status: 'PUBLISHED'
        }
        if (beverage.idDish) toSend.idDish = beverage.idDish
        return toSend
    })
})
