import {Toaster as RHTToaster} from 'react-hot-toast'
import {useTheme} from 'styled-components'

const Toaster = () => {
    const theme = useTheme()
    return (
        <RHTToaster
            toastOptions={{
                style: {
                    padding: `${theme.spacing * 4}px`,
                    boxShadow: theme.shadows.md,
                    backgroundColor: theme.palette.neutral.white
                },
                success: {
                    style: {
                        backgroundColor: theme.palette.success[100]
                    }
                },
                error: {
                    style: {
                        backgroundColor: theme.palette.danger[100]
                    }
                }
            }}
        />
    )
}

export default Toaster
