import styled, {css} from 'styled-components'

type StyledInputStatusIconProps = {
    $hasError?: boolean
}
export const InputStatusIconWrapper = styled.div<StyledInputStatusIconProps>`
    ${({$hasError, theme: {palette, spacing}}) => css`
        position: absolute;
        top: 50%;
        height: 16px;
        transform: translateY(-50%);
        right: ${spacing * 2}px;
        color: ${$hasError ? palette.danger['300'] : palette.success['300']};
    `}
`
