import {StyledContent, StyledBreadcrumbsWrapper, StyledMainContainer} from './styles'
import React, {ReactNode} from 'react'
import {BreadCrumbs} from '@components/commons/bread-crumbs/BreadCrumbs'

type ListPageLayoutProps = {
    children: ReactNode
}

export const ListPageLayout: React.FC<ListPageLayoutProps> = ({children}) => {
    return (
        <StyledMainContainer>
            <StyledBreadcrumbsWrapper>
                <BreadCrumbs />
            </StyledBreadcrumbsWrapper>
            <StyledContent>{children}</StyledContent>
        </StyledMainContainer>
    )
}
