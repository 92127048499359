import {Navigate, Outlet, generatePath} from 'react-router-dom'
import {routes} from '@/utilities/constants/routes.tsx'
import useAuthStore from '@/features/auth/store/store'
import {HOME_PATHS} from '@/features/home/components/home-tabs/HomeTabs'

export const PublicRoute = () => {
    const accessToken = useAuthStore(state => state.accessToken)

    return accessToken ? <Navigate to={generatePath(routes.HOME.path, {tab: HOME_PATHS.restaurants})} /> : <Outlet />
}
