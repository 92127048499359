import {z} from 'zod'

export const BEVERAGE_FORM_MODEL = {
    beverages: {
        idDish: {
            name: 'idDish',
            label: ''
        },
        name: {
            name: 'name',
            label: 'beverage:addForm:fields:name'
        },
        description: {
            name: 'description',
            label: 'beverage:addForm:fields:description'
        },
        price: {
            name: 'price',
            label: 'beverage:addForm:fields:price'
        },
        image: {
            name: 'image',
            label: ''
        }
    }
} as const

export const BeverageItemSchema = z.object({
    idDish: z.string().optional(),
    name: z.string().min(1, {message: 'errors:required'}),
    description: z.string().optional(),
    price: z.coerce.number(),
    image: z.instanceof(File).optional().nullable()
})
export type BeverageItem = z.infer<typeof BeverageItemSchema>

export const BeverageFormSchema = z.object({
    beverages: z.array(BeverageItemSchema)
})
export type BeverageValidationSchema = z.infer<typeof BeverageFormSchema>
