import {Container} from '@components/ui/container/Container.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'
import {useTranslation} from 'react-i18next'
import {PlusIcon, SearchLgIcon} from '@components/ui/icon/Icon.tsx'
import {toast} from '@/utilities/toast/toast'
import {useTheme} from 'styled-components'
import {useAccountsTable} from '@/features/account/services/queries/useAccountsTable'
import {useEffect, useRef, useState} from 'react'
import {AccountsTable} from '@/features/account/components/accounts-table/AccountsTable'
import {EmptySearch} from '@/components/commons/empty-search/EmptySearch'
import {AccountModal} from '@/features/account/components/account-form/AccountModal'
import {InputText} from '@/components/commons/input-text/InputText'
import {FiltersContainer} from '@/components/commons/filters-container/FiltersContainer'
import {ListPageLayout} from '@/layouts/list-page-layout/ListPageLayout'
import {AccountListItem} from '@/features/account/types'
import {DeleteModal} from '@/components/commons/delete-modal/DeleteModal'
import {useDeleteAccount} from '@/features/account/services/queries/useDeleteAccount'

export const Accounts = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const accountsQuery = useAccountsTable()
    const searchRef = useRef<HTMLInputElement | null>(null)
    const [isAddModalOpen, setIsAddModalOpen] = useState(false)
    const [accountToDelete, setAccountToDelete] = useState<AccountListItem | null>(null)
    const {
        mutate: deleteMutate,
        isPending: deletePending,
        isError: isErrorDelete,
        error: errorDelete
    } = useDeleteAccount({
        onSuccess: () => setAccountToDelete(null)
    })

    useEffect(() => {
        if (accountsQuery.isError || isErrorDelete) toast.error(accountsQuery.error?.message || errorDelete?.message)
    }, [accountsQuery.isError, isErrorDelete])

    const onClickAddAccount = () => setIsAddModalOpen(true)

    const onCancelAddAccount = () => setIsAddModalOpen(false)

    const onSubmitAccountToDelete = () => {
        if (accountToDelete) {
            return deleteMutate(accountToDelete.idAccount)
        }
    }

    return (
        <ListPageLayout>
            <Container fullWidth>
                <PageHero
                    title={t('account:title')}
                    ctaComponent={
                        <Button size={'sm'} variant={'primary'} onClick={onClickAddAccount}>
                            <PlusIcon stroke={theme.palette.neutral.white} />
                            {t('account:new_account')}
                        </Button>
                    }
                />

                <FiltersContainer>
                    <InputText
                        onChange={e => accountsQuery.onSearch(e.currentTarget.value)}
                        defaultValue={accountsQuery.searchValue}
                        type={'text'}
                        placeholder={t('commons:search_placeholder')}
                        typeIcon={<SearchLgIcon size={18} />}
                        ref={searchRef}
                        width={200}
                    />
                </FiltersContainer>

                <AccountsTable
                    data={accountsQuery.remappedData}
                    isChangingPage={accountsQuery.isFetchingNextPage}
                    isError={accountsQuery.isError}
                    isLoading={accountsQuery.isLoading}
                    onChangePage={accountsQuery.fetchNextPage}
                    searchValue={accountsQuery.searchValue}
                    onResetSearchCb={() => accountsQuery.onResetSearch(searchRef)}
                    emptySearchStateComponent={<EmptySearch title={t('commons:no_results')} icon={<SearchLgIcon />} />}
                    sorter={accountsQuery.sorter}
                    onClickAddAccount={onClickAddAccount}
                    setAccountToDelete={setAccountToDelete}
                />
            </Container>
            {isAddModalOpen && <AccountModal onClose={onCancelAddAccount} />}
            {accountToDelete && (
                <DeleteModal
                    title={t('account:delete:title')}
                    paragraph={t('account:delete:description')}
                    isLoading={deletePending}
                    onClose={() => setAccountToDelete(null)}
                    onDelete={() => onSubmitAccountToDelete()}
                />
            )}
        </ListPageLayout>
    )
}

Accounts.displayName = 'Accounts'
